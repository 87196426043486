import { IUserRealty } from '6-entities/user';
import { defaultValue } from '7-shared/utils';

export const getUserAddress = (data: IUserRealty) => {
  const settlement = defaultValue(data?.street?.settlement, {});
  const street = defaultValue(data?.street, {});
  const realty_id = defaultValue(`${data?.id}`, undefined);
  return {
    settlement: {
      id: settlement?.id && Number(settlement?.id),
      name: settlement?.name ?? '',
    },
    street: { id: street?.id && Number(street?.id), name: street?.name },
    realty: {
      id: realty_id && Number(realty_id),
      name: `${data?.number ? data?.number : ''} ${data?.corpus ? `к.${data?.corpus.toUpperCase()}` : ''}`,
    },
    address: {
      id: realty_id,
      name: `${street?.name ? street?.name : ' '} ${data?.number ? data?.number : ''} ${
        data?.corpus ? `к.${data?.corpus.toUpperCase()}` : ''
      }`,
    },
  };
};
