import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditBody,
  IPaginationResponse,
  IQueryParams,
  IServiceSupport,
  IServiceSupportInfoForm,
  IServiceSupportQueryParams,
  IServiceSupportReplace,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const serviceSupportApi = createApi({
  reducerPath: 'serviceSupportApi',
  tagTypes: ['serviceSupportList', 'serviceSupportListItem'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getServiceSupportItem: build.query<IServiceSupport, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/feedback-communal/${id}`,
      }),
      providesTags: ['serviceSupportListItem'],
    }),
    createServiceSupportComment: build.mutation<IServiceSupport, IEditBody<IServiceSupportInfoForm>>({
      query: (data) => ({
        url: `cms/feedback-communal/response/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['serviceSupportListItem', 'serviceSupportList'],
    }),
    replaceServiceSupportItem: build.mutation<IServiceSupport, IEditBody<IServiceSupportReplace>>({
      query: (data) => ({
        url: `cms/feedback-any/redirect/${data.id}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: ['serviceSupportList'],
    }),
  }),
});

export const {
  useGetServiceSupportItemQuery,
  useCreateServiceSupportCommentMutation,
  useReplaceServiceSupportItemMutation,
} = serviceSupportApi;
