import { useEffect, useState } from 'react';
import { omit } from '7-shared/lib/lodash';
import { useSetQueryParams } from './useSetQueryParams';

interface IPagination {
  page?: string | number;
}

interface IUseQueryParams<T> {
  onChange: (fieldName: keyof T | keyof IPagination, value: string) => void;
  onClear: (fieldName: keyof T | string[]) => void;
  params?: Record<keyof T, string>;
  pagination: IPagination;
}

export const useQueryParams = <T,>(): IUseQueryParams<T> => {
  const [pagination, setPagination] = useState<IPagination | undefined>(undefined);
  const [params, setParams] = useState<Record<keyof T, string>>();
  const [allParams, setAllParams] = useState<Record<string, string>>();

  const { onChangeQuery, deleteQueryFromUrl, getQueryFromUrl } = useSetQueryParams();

  const onChangeParams = (fieldName: keyof T | keyof IPagination, value: string) => {
    if (!value) {
      onClearParams(fieldName);
    } else {
      const newFilter = { ...allParams, [fieldName]: value, page: fieldName === 'page' ? value : '1' };
      const { page, page_size, ...other } = newFilter;
      setPagination({ page });
      setParams(other as any);
      setAllParams(newFilter);
      onChangeQuery(newFilter);
    }
  };

  const onClearParams = (fieldName: keyof T | keyof IPagination | string[]) => {
    if (Array.isArray(fieldName)) {
      onClearParamsArr(fieldName);
    } else {
      const newAllFilter = omit(params, fieldName);
      const newFilter = omit(params, ['page', fieldName]);
      setPagination({ page: '1' });
      setParams(newFilter as any);
      setAllParams(newAllFilter);
      deleteQueryFromUrl(fieldName as string);
    }
  };

  const onClearParamsArr = (fieldName: string[]) => {
    const newAllFilter = omit(params, fieldName);
    const newFilter = omit(params, ['page', ...fieldName]);
    setPagination({ page: '1' });
    setParams(newFilter as any);
    setAllParams(newAllFilter as Record<string, string>);
    fieldName.forEach((i) => deleteQueryFromUrl(i as string));
  };

  const setParamsInitialValues = () => {
    const paramsFromUrl = getQueryFromUrl();
    const { page, page_size, ...other } = paramsFromUrl;
    setPagination({ page: page ?? '1' });
    setAllParams(paramsFromUrl);
    setParams(other as any);
  };

  useEffect(() => {
    setParamsInitialValues();
  }, []);

  return {
    onChange: onChangeParams,
    onClear: onClearParams,
    params,
    pagination: {
      page: pagination?.page ? Number(pagination?.page) : undefined,
    },
  };
};
