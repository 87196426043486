import React, { useEffect } from 'react';
import { getTemplatesReportsThunk, reportsActions, selectReportsListPagination } from '6-entities/reports';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { Button, Flex } from '7-shared/ui';
import { IconPlus } from '7-shared/assets';
import { TemplateReportsForm, TemplatesPagination, TemplatesTable } from '5-features/reports';

export const ReportsTemplates = () => {
  const dispatch = useAppDispatch();
  const { pagination } = useQueryParams();
  const tablePagination = useAppSelector(selectReportsListPagination);
  const openModalTemplates = () => {
    dispatch(reportsActions.setReportsModalTemplatesState({ open: true }));
  };
  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getTemplatesReportsThunk());
    }
  }, [tablePagination?.page]);
  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(reportsActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  useEffect(() => {
    return () => {
      dispatch(reportsActions.setInitialReportsstate());
    };
  }, []);
  return (
    <Flex direction={'column'} className={'flex-col  h-full'} gap={10}>
      <Flex gap={'md'} justify={'flex-end'}>
        <Button rightIcon={<IconPlus size={20} />} variant="outline" onClick={openModalTemplates}>
          Добавить шаблон
        </Button>
      </Flex>
      <TemplatesTable />
      <TemplatesPagination />
      <TemplateReportsForm />
    </Flex>
  );
};
