import React from 'react';

import { useAppSelector, useQueryParams } from '7-shared/hooks';
import { Tooltip } from '7-shared/ui';
import { IReportsFilters, selectReportsListPagination } from '6-entities/reports';

interface IProps {
  value: any;
  index: number;
}

export const TemplatesNumberRow = ({ value, index }: IProps) => {
  const { pagination } = useQueryParams<IReportsFilters>();
  const tablePagination = useAppSelector(selectReportsListPagination);

  return (
    <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.id}`}>
      {pagination?.page === 1
        ? index + 1
        : Number(tablePagination?.page_size) * Number(pagination?.page) -
          Number(tablePagination?.page_size) +
          index +
          1}
      .
    </Tooltip>
  );
};
