import React from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import './assets/css/index.css';
import { bootstrap } from './bootstrap';
import { App } from '1-app';
import { Provider } from 'react-redux';
import store from '1-app/store/store';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import 'dayjs/locale/ru';
import { ru } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

const main = async () => {
  const ruTranslate = await import('./locales/ru.json');
  setDefaultOptions({ locale: ru });
  await i18next.use(initReactI18next).init({
    resources: {
      ru: {
        translation: ruTranslate.default,
      },
    },
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
  });

  bootstrap({
    rootElement: (
      <React.Suspense>
        <Provider store={store}>
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <Notifications position="top-right" />
            <ModalsProvider>
              <App />
            </ModalsProvider>
          </MantineProvider>
        </Provider>
      </React.Suspense>
    ),
    selector: '#root',
  });
};

void main();
