import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, IContact, IContactForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const contactsAPI = createApi({
  reducerPath: 'contactsAPI',
  tagTypes: ['contacts'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getContacts: build.query<IPaginationResponse<IContact[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/contacts/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['contacts'],
    }),
    createContacts: build.mutation<IContact, IContactForm>({
      query: (data) => ({
        url: `/cms/contacts/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['contacts'],
    }),
    editContacts: build.mutation<IContact, IEditBody<IContactForm>>({
      query: (data) => ({
        url: `/cms/contacts/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['contacts'],
    }),
    deleteContacts: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/contacts/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['contacts'],
    }),
  }),
});

export const { useCreateContactsMutation, useDeleteContactsMutation, useEditContactsMutation, useGetContactsQuery } =
  contactsAPI;
