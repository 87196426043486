export const validation = {
  email: 'Неверный формат email',
  min: 'Минимальная длина символов',
  max: 'Максимальная длина символов',
  required: 'Это поле обязательное',
  only_numbers: 'Разрешенные символы: цифры',
  phone: 'Требуемый формат: +77777777777',
  phone_invalid: 'Введите корректный номер телефона.',
  code: 'Разрешенные символы: цифры',
};

export const validate = {
  required: 'Это поле обязательное',
  email: 'Это не email',
  numbers: 'Должен содержать только цифры',
  min: 'Минимальная длина символов',
  max: 'Максимальная длина символов',
  min_count: 'Минимальное количество',
  required_min_count: 'Нужно выбрать как минимум',
  link: 'Это не ссылка',
  valid_link: 'Это не валидная ссылка',
  invalid_phone: 'Введен некорректный номер телефона',
};
export const regex = {
  is_link:
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
};
