import { createApi } from '@reduxjs/toolkit/query/react';
import { ILoginResponse, IRecoveryPassword, IResetPasswordForm, ISignInForm, IUserInformation } from 'interface';
import { customFetchBase } from '../fetchBase';

export const authAPI = createApi({
  reducerPath: `authAPI`,
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    login: build.mutation<ILoginResponse, ISignInForm>({
      query: (loginForm) => ({
        url: `/cms/login/`,
        method: 'POST',
        body: loginForm,
      }),
    }),
    forgotPassword: build.mutation<{}, IResetPasswordForm>({
      query: (resetPasswordForm) => ({
        url: `/cms/auth/password/reset/`,
        method: `POST`,
        body: resetPasswordForm,
      }),
    }),
    resetPassword: build.mutation<{}, IRecoveryPassword>({
      query: (form) => ({
        url: `/cms/auth/password/reset/complete/`,
        method: `POST`,
        body: form,
      }),
    }),
    checkPasswordToken: build.mutation<{}, { uid: string; token: string }>({
      query: ({ uid, token }) => ({
        url: `/cms/auth/password/reset/confirm/`,
        method: `POST`,
        body: { uid, token },
      }),
    }),
    logout: build.mutation<{}, void>({
      query: () => ({
        url: `logout/`,
        method: `POST`,
      }),
    }),
    authMe: build.mutation<{}, void>({
      query: () => ({
        url: `token/verify/`,
        method: `POST`,
      }),
    }),
    getUserInfo: build.query<IUserInformation, void>({
      query: () => ({
        url: `cms/users/me/`,
      }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useLogoutMutation,
  useAuthMeMutation,
  useCheckPasswordTokenMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
} = authAPI;
