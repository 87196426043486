import React, { useEffect } from 'react';
import { Button, Flex, Title } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { IconPlus } from '7-shared/assets';
import { getVideosListThunk, selectVideosListPagination, videosActions } from '6-entities/videos';
import { VideoForm, VideosPagination, VideosTable } from '5-features/videos';
import { getSettlementListThunk } from '7-shared/api';

export const VideosList = () => {
  const dispatch = useAppDispatch();

  const { pagination } = useQueryParams();

  const tablePagination = useAppSelector(selectVideosListPagination);

  useEffect(() => {
    dispatch(getVideosListThunk());
    dispatch(getSettlementListThunk());
  }, []);

  const onPressAddHandler = () => {
    dispatch(videosActions.setVideosModalState({ open: true }));
  };

  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getVideosListThunk());
    }
  }, [tablePagination?.page]);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(videosActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  useEffect(() => {
    return () => {
      dispatch(videosActions.setInitialVideosState());
    };
  }, []);

  return (
    <Flex direction={'column'} className={'flex-col  h-full'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
          Камеры
        </Title>
        <Button rightIcon={<IconPlus size={20} />} className={'w-max'} variant="outline" onClick={onPressAddHandler}>
          Добавить КП
        </Button>
      </Flex>
      <VideosTable />
      <VideosPagination />
      <VideoForm />
    </Flex>
  );
};
