import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IQueryParams } from 'interface';
import { ICamera, ICameraForm, IVideo, IVideoForm } from 'interface/videos';
import { customFetchBase } from '../fetchBase';

export const videosAPI = createApi({
  reducerPath: `videosApi`,
  tagTypes: ['videos', 'current_video', 'cameras'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getVideos: build.query<any, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/video/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['videos', 'cameras'],
    }),
    getCurrentVideo: build.query<any, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/video/${id}`,
      }),
      providesTags: ['current_video', 'cameras'],
    }),

    createVideo: build.mutation<IVideo, IVideoForm>({
      query: (data) => ({
        url: `/cms/video/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['videos'],
    }),
    editVideo: build.mutation<IVideo, IEditBody<IVideoForm>>({
      query: (data) => ({
        url: `/cms/video/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['videos', 'current_video'],
    }),
    deleteVideo: build.mutation<null, IQueryParams>({
      query: (data) => ({
        url: `cms/video/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['videos'],
    }),
    getCameras: build.query<any, IQueryParams>({
      query: ({ page, size, id: video_id }) => ({
        url: `/cms/camera-list/${video_id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['cameras'],
    }),
    createCamera: build.mutation<ICamera, ICameraForm>({
      query: (data) => ({
        url: `/cms/cameras/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['cameras'],
    }),
    editCamera: build.mutation<ICamera, IEditBody<ICameraForm>>({
      query: (data) => ({
        url: `/cms/cameras/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['cameras'],
    }),
    deleteCamera: build.mutation<null, IQueryParams>({
      query: (data) => ({
        url: `/cms/cameras/${data.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['cameras'],
    }),
    startStream: build.query<any, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/cameras/start-stream/${id}/`,
      }),
    }),
    stopStream: build.query<any, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/cameras/stop-stream/${id}/`,
      }),
    }),
    pauseStream: build.mutation<any, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/cameras/pausa/${id}/`,
        method: 'PUT',
        body: {},
      }),
    }),
  }),
});

export const {
  useCreateVideoMutation,
  useDeleteVideoMutation,
  useEditVideoMutation,
  useGetVideosQuery,
  useGetCurrentVideoQuery,
  useCreateCameraMutation,
  useDeleteCameraMutation,
  useEditCameraMutation,
  useGetCamerasQuery,
  useLazyStartStreamQuery,
  useLazyStopStreamQuery,
  usePauseStreamMutation,
} = videosAPI;
