import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppShell } from '@mantine/core';
import { Navbar } from './navBar';
import { getIsAuth } from 'store/app';
import { useLogoutMutation } from 'store/auth';
import { AUTH_TOKEN, ROUTE_PATH } from 'shared/constants';
import { localStorageUtils } from 'shared/utils';
import { useManagerPermissions } from './utils';

export const DashboardLayout = () => {
  const navigate = useNavigate();
  const isAuth = useSelector(getIsAuth);

  const [logOut] = useLogoutMutation();

  const { accessLinks, isLoading } = useManagerPermissions();

  useEffect(() => {
    if (!isAuth) {
      navigate(ROUTE_PATH.LOGIN);
    }
  }, [isAuth, navigate]);

  const logoutHandler = async () => {
    await logOut().unwrap();
    localStorageUtils.removeValue(AUTH_TOKEN.access);
    await window.location.reload();
    navigate(ROUTE_PATH.LOGIN);
  };

  return (
    <AppShell
      className={'overflow-hidden'}
      padding="md"
      navbar={<Navbar isLoading={isLoading} accessLinks={accessLinks} logoutHandler={logoutHandler} />}
    >
      <Outlet />
    </AppShell>
  );
};
