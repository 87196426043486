import { ITimeStamps } from './app.interface';

export interface IMaster extends ITimeStamps, IMasterForm {
  id: number;
  status: MASTERS_DISPLAY_STATUS;
  section_descriptions: IMasterSectionDescription[];
  subsection_descriptions: IMasterSubSectionDescription[];
  second_subsection_descriptions: IMasterSecondSubSectionDescription[];
}

export interface IMasterForm {
  services: IServiceMasterForm[] | string[];
  settlements: number[];
  show_all: boolean;
  avatar: string;
  surname: string;
  name: string;
  description: string;
  email: string;
  expire_date: string;
  phone: string;
  sections: number[];
  second_subsections: number[];
  subsections: number[];
  category: any[];
  detail?: string;
}

export interface IInviteMasterForm {
  services: IServiceMasterForm[] | string[];
  avatar: string;
  surname: string;
  name: string;
  description: string;
  email: string;
  phone: string;
  sections: number[];
  second_subsections: number[];
  subsections: number[];
  category: any[];
  detail?: string;
  company_id: number;
}

export enum MASTERS_DISPLAY_STATUS {
  ACCESS = 'access_display',
  PROLONG = 'prolong_display',
  NO = 'no_display',
}

export interface IMastersTreeOptions {
  disabled: boolean;
  id: number;
  value: string;
  checked: boolean;
  level: number;
  options: IMastersTreeOptions[];
  description?: string;
}

export interface IMasterSectionDescription {
  section_id: number;
  description: string;
}
export interface IMasterSubSectionDescription {
  subsection_id: number;
  description: string;
}
export interface IMasterSecondSubSectionDescription {
  second_subsection_id: number;
  description: string;
}

export interface IServiceMasterForm {
  id?: number;
  name: string;
}

export type IMastersQueryParams = Readonly<
  'search' | 'status' | 'page' | 'size' | 'time_created__lte' | 'time_created__gte'
>;
