import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapReportsList } from '../lib/mapReportsList';
import { reportsApi } from '../api/reports.api';
import { reportsActions } from '../model/reports.slice';
import {
  ICommonErrorRejectReports,
  IImportReportForm,
  IReportForm,
  IReportViaTemplateForm,
} from '6-entities/reports/model/types';
import { ICommonErrorReject } from '7-shared/api/types';

export const getReportsListThunk = createAsyncThunk<void, void, { state: RootState }>(
  'reports/getReportsList',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setListTableData({ isLoading: true }));
      const filters = getState().reports.filters;
      const pagination = getState().reports.pagination;
      const result = await reportsApi.getReportsList(pagination.page, pagination.page_size, filters);
      const list = result.results.map((i) => mapReportsList(i));
      dispatch(reportsActions.setListTableData({ data: list }));
      dispatch(reportsActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(reportsActions.setListTableData({ isLoading: false }));
    }
  }
);
export const editReportThunk = createAsyncThunk<void, { id: string; data: IReportForm }, { state: RootState }>(
  'reports/editReport',
  async ({ id, data }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setReportsModalState({ isLoading: true }));
      await reportsApi.editReport(id, data);
      dispatch(getReportsListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(reportsActions.setReportsModalState({ isLoading: false }));
    }
  }
);
export const createReportThunk = createAsyncThunk<void, IReportForm, { state: RootState }>(
  'reports/createReport',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setReportsModalState({ isLoading: true }));
      await reportsApi.createReport(data);
      dispatch(getReportsListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(reportsActions.setReportsModalState({ isLoading: false }));
    }
  }
);
export const deleteReportThunk = createAsyncThunk<void, string, { state: RootState }>(
  'reports/deleteReport',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setListTableData({ isLoading: true }));
      await reportsApi.deleteReport(id);
      dispatch(getReportsListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(reportsActions.setListTableData({ isLoading: false }));
    }
  }
);

export const importFileReportsThunk = createAsyncThunk<void, IImportReportForm, { state: RootState }>(
  'reports/importFileReports',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setListTableData({ isLoading: true }));
      await reportsApi.importFileReports(data);
      dispatch(getReportsListThunk());
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(reportsActions.setListTableData({ isLoading: false }));
    }
  }
);

export const createReportViaTemplateThunk = createAsyncThunk<void, IReportViaTemplateForm, { state: RootState }>(
  'reports/createReportViaTemplate',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setListTableData({ isLoading: true }));
      await reportsApi.createReportViaTemplate(data);
      dispatch(getReportsListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorRejectReports)?.response?.data?.non_field_errors);
    } finally {
      dispatch(reportsActions.setListTableData({ isLoading: false }));
    }
  }
);
