import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { mapKeys } from '7-shared/lib/lodash';

export const useSetQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onChangeQuery = (data: Record<string, string>) => {
    mapKeys(data, (value, key) => setQueryValue(key, value));
    setSearchParams(searchParams, { replace: true });
  };
  const setQueryValue = (key: string, value: string) => {
    searchParams.has(key) ? searchParams.set(key, value) : searchParams.append(key, value);
  };

  const deleteQueryFromUrl = (fieldName: string) => {
    searchParams.delete(fieldName);
    setSearchParams(searchParams, { replace: true });
  };

  const getQueryFromUrl = useCallback(() => {
    const queryParams: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      queryParams[key as string] = value;
    });
    return queryParams;
  }, [searchParams]);

  return { onChangeQuery, deleteQueryFromUrl, getQueryFromUrl };
};
