import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IPartnerForm, IQueryParams, IService, IServiceForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const servicesAPI = createApi({
  reducerPath: 'servicesAPI',
  tagTypes: ['services', 'pre-services'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getServices: build.query<IPaginationResponse<IService[]>, IQueryParams>({
      query: ({ page, size, search, status }) => ({
        url: `/cms/services/?page=${page}&page_size=${size}&search=${search ?? ''}&status=${status ?? ''}`,
      }),
      providesTags: ['services'],
    }),
    createService: build.mutation<IService, IServiceForm>({
      query: (data) => ({
        url: `cms/services/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['services'],
    }),
    editService: build.mutation<IService, IEditBody<IServiceForm>>({
      query: (data) => ({
        url: `cms/services/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['services'],
    }),
    deleteService: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/services/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['services'],
    }),
    getPreServices: build.query<IPaginationResponse<IService[]>, IQueryParams>({
      query: ({ page, size, search, status, time_created__gte, time_created__lte }) => ({
        url: `/cms/pre-services/`,
        params: { page, page_size: size, search, status__in: status, time_created__gte, time_created__lte },
      }),
      providesTags: ['pre-services'],
    }),
    editPreService: build.mutation<IService, IEditBody<IPartnerForm>>({
      query: (data) => ({
        url: `cms/pre-services/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['pre-services', 'services'],
    }),
    deletePreService: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/pre-services/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['pre-services'],
    }),
  }),
});

export const {
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useEditServiceMutation,
  useGetServicesQuery,
  useDeletePreServiceMutation,
  useEditPreServiceMutation,
  useGetPreServicesQuery,
} = servicesAPI;
