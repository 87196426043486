import { IBuilderSupportListFilters } from '../model/types';
import { IFilters } from '../api/types';
import { addDays, format } from 'date-fns';

export function mapFiltersBuilderSupport(filters: IBuilderSupportListFilters): IFilters {
  return {
    status: filters.status,
    user_role: filters.role,
    score: filters.score,
    realty_id: filters.address_realty,
    street_id: filters.address_street,
    settlement_id: filters.settlement,
    time_created_min: filters.dateOfCreation_start,
    time_created_max: filters.dateOfCreation_end
      ? format(addDays(new Date(filters.dateOfCreation_end), 1), 'yyyy-MM-dd')
      : undefined,
    time_closed_min: filters.dateOfClosed_start,
    time_closed_max: filters.dateOfClosed_end
      ? format(addDays(new Date(filters.dateOfClosed_end), 1), 'yyyy-MM-dd')
      : undefined,
    main_time_max: filters.main_time_max
      ? format(addDays(new Date(filters.main_time_max), 1), 'yyyy-MM-dd')
      : undefined,
    main_time_min: filters.main_time_min,
    search_id: filters.id,
    search_account: filters.account_id,
    search_user_names: filters.name,
    search_user_phone: filters.phone,
    search_email: filters.email,
    search_land_plot: filters.plot,
    search_message: filters.comment,
    search_manager: filters.manager,
  };
}
