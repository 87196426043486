import React from 'react';
import { Tooltip } from '7-shared/ui';
import { ICamera } from '6-entities/videos';
import { CamerasTableActions } from '../ui/cameras/camerasTableActions/camerasTableActions';

export interface ICamerasColumnTable {
  accessor: keyof ICamera;
  render?: (value: ICamera, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: ICamerasColumnTable[] = [
  {
    accessor: 'name',
    render: (value, index) => (
      <Tooltip className={'max-w-[900px]'} index={index} tooltip={`${value?.id}`}>
        {value.name}
      </Tooltip>
    ),
  },
  {
    accessor: 'actions',
    render: (value) => <CamerasTableActions record={value} />,
    width: 100,
  },
];
