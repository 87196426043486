import React from 'react';
import { selectPlotsListTableData } from '6-entities/plots';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';

import { columns } from '../../model/plotsListColumns';

export const PlotsTable = () => {
  const tableData = useAppSelector(selectPlotsListTableData);

  return <Table columns={columns} rows={tableData.data} tableLoading={tableData.isLoading} />;
};
