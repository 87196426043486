import React from 'react';
import {
  selectTechnicalSupportListFilters,
  selectTechnicalSupportListPagination,
  technicalSupportActions,
} from '6-entities/technicalSupport';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { checkAnyValue } from '7-shared/utils';
import { Flex } from '7-shared/ui/mantine';
import { Appeals, Pagination } from '7-shared/ui';

interface IProps {
  onChange: (fieldName: 'page', value: string) => void;
}

export const TechnicalSupporPagination = ({ onChange }: IProps) => {
  const dispatch = useAppDispatch();

  const tablePagination = useAppSelector(selectTechnicalSupportListPagination);
  const filters = useAppSelector(selectTechnicalSupportListFilters);

  const onChangePagination = (newPage: number) => {
    dispatch(technicalSupportActions.setListPagination({ page: newPage }));
    onChange('page', `${newPage}`);
  };

  const textAppeals = checkAnyValue(filters)
    ? `Общее количество обращений за выбранный период:  ${tablePagination.total_count}`
    : `Общее количество обращений за весь период:  ${tablePagination.total_count}`;

  return (
    <Flex className=" w-[60vw]  whitespace-nowrap justify-between">
      <Appeals text={textAppeals} />
      <Pagination
        setPage={onChangePagination}
        activePage={tablePagination.page}
        total={Math.ceil(tablePagination.total_count / tablePagination.page_size) ?? 1}
      />
    </Flex>
  );
};
