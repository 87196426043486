import React, { SetStateAction, useState } from 'react';
import { ReactComponent as Icon } from '../../../assets/icons/documentIcon.svg';
import { IconPlus } from '../../../assets';
import { Box, Dropzone, FileWithPath, FileRejection, Button } from '../../../ui';

interface IProps {
  aspect?: number;
  defaultUrl?: any;
  setImage: React.Dispatch<SetStateAction<any>>;
  type: 'image' | 'file';
  label_button?: string;
  title?: string;
  accept?: string[] | undefined;
}

export const AddPhoto = ({
  title = '',
  type,
  label_button = '',
  defaultUrl,
  setImage,
  aspect = 1,
  accept = undefined,
}: IProps) => {
  const [error, setError] = useState<string>('');

  const onChangeHandler = async (data: FileWithPath[]) => {
    data.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result;
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  };
  const onChangeFile = async (data: File[]) => {
    data.map((i) => setImage(i));
  };
  const checkTypeFile = (file: FileRejection[]) => {
    if (!accept) setError('');
    file.forEach((i) => (i.errors ? setError(`Разрешенные форматы:${accept}`) : setError('')));
  };
  if (type === 'image')
    return (
      <Box className={'w-full'}>
        <Dropzone sx={{ border: 0 }} onDrop={onChangeHandler} onReject={checkTypeFile} accept={accept}>
          {defaultUrl ? (
            <div className={'relative max-w-[90px] h-[50px] rounded-[10px]'}>
              <img alt={''} src={defaultUrl} className={'w-full h-full rounded-[10px]'} />
              <Box
                className={
                  'fill-white top-0 left-0 w-full h-full flex justify-center items-center absolute z-20 bg-black_text_opacity_40 invisible hover:visible rounded-[10px]'
                }
              ></Box>
            </div>
          ) : (
            <Button className={'w-full'} rightIcon={<IconPlus size={20} />} variant="outline">
              {label_button}
            </Button>
          )}
        </Dropzone>
        <span className={'block h-[20px] text-center text-red-500'}>{error}</span>
      </Box>
    );
  return (
    <Box className={'w-full'}>
      <Dropzone onDrop={onChangeFile} onReject={checkTypeFile} accept={accept} sx={{ border: 0 }}>
        {defaultUrl ? (
          <div className={'flex flex-row gap-3 hover:underline'}>
            <Icon fill={'black'} />
            <span>Изменить документ</span>
          </div>
        ) : (
          <Button
            className={'w-full'}
            rightIcon={<IconPlus size={20} />}
            variant="outline"
            onClick={(event) => event.preventDefault()}
          >
            Загрузить файл
          </Button>
        )}
      </Dropzone>
    </Box>
  );
};
