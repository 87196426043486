import { ISettlement } from './settlements.interface';
import { IPaginationQuery, ITimeStamps } from './app.interface';
import { IUser } from './users.interface';

export interface IPartnerForm {
  name: string;
  phone: string;
  email: string;
  cover: string;
  preview: string;
  short_description: string;
  full_description: string;
  expire_date: string;
  sections: number[];
  subsections: number[];
  category: any[];
  second_subsections: number[];
  show_all: boolean;
  settlements: ISettlement[];
  staff: IStaffPartnerForm[];
  detail?: string;
  rating?: number;
}
export interface IPartner extends ITimeStamps, IPartnerForm {
  id: number;
  staff: IStaffPartner[];
  status: PARTNERS_DISPLAY_STATUS;
  section_descriptions: IPartnerSectionDescription[];
  subsection_descriptions: IPartnerSubSectionDescription[];
  second_subsection_descriptions: IPartnerSecondSubSectionDescription[];
}

export interface IPartnerStatistic {
  id: number;
  calls_amount: number;
  name: string;
  status: PARTNERS_DISPLAY_STATUS;
}

export interface IPartnerScore {
  id: number;
  partner_id: number;
  partner_name: string;
  refusal_text: string;
  score: number;
  status: string;
  name: string;
  comment: string;
  user: IUser;
}

export enum PARTNERS_DISPLAY_STATUS {
  ACCESS = 'access_display',
  PROLONG = 'prolong_display',
  NO = 'no_display',
}

export interface IPartnerSectionDescription {
  section_id: number;
  short_description: string;
  full_description: string;
}
export interface IPartnerSubSectionDescription {
  subsection_id: number;
  short_description: string;
  full_description: string;
}
export interface IPartnerSecondSubSectionDescription {
  second_subsection_id: number;
  short_description: string;
  full_description: string;
}

export interface IStaffPartnerForm {
  full_name: string;
  short_description: string;
  avatar: string;
  id?: number;
}

export interface IPartnersTreeOptions {
  disabled: boolean;
  id: number;
  value: string;
  checked: boolean;
  level: number;
  options: IPartnersTreeOptions[];
  full_description?: string;
  short_description?: string;
}

export interface IStaffPartner extends IStaffPartnerForm, ITimeStamps {
  id: number;
}

export interface IInvitePartnerForm {
  company_id: number;
  name: string;
  phone: string;
  email: string;
  cover: string;
  preview: string;
  short_description: string;
  full_description: string;
  sections: number[];
  subsections: number[];
  category: any[];
  second_subsections: number[];
  staff: IStaffPartnerForm[];
  detail?: string;
}

export type IPartnersQueryParams = Readonly<
  'search' | 'status' | 'page' | 'size' | 'time_created__gte' | 'time_created__lte'
>;
export type IPartnersStatisticParams = Readonly<'time_lte' | 'time_gte' | 'page' | 'size'>;
export type IPartnersStatisticQueryParams = IPartnersStatisticParams | IPaginationQuery;
