import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import _ from 'lodash';
import defaultTo from 'lodash/defaultTo';
import { ILikeInfo, Ilikes, IUserAddresses, IUserRealty, ROLES } from 'interface';

interface IFormError {
  isError: boolean;
  errors: SerializedError | FetchBaseQueryError | undefined;
}

export const isEmpty = (data: any): boolean => {
  return _.isEmpty(data);
};

export const checkFormError = (errors: IFormError[]) => {
  const error = errors.find((i) => i.isError);
  return defaultValue((error?.errors as any)?.data, []);
};

export const defaultValue = (value?: any, defaultValue?: any) => {
  return defaultTo(value, defaultValue);
};
export const mapLikes = (item: ILikeInfo): Ilikes => {
  return {
    date_joined: item.user.date_joined,
    email: item.user.email,
    first_name: item.user.first_name,
    get_notifications: item.user.get_notifications,
    id: item.user.id,
    is_active: item.user.is_active,
    last_name: item.user.last_name,
    middle_name: item.user.middle_name,
    phone: item.user.phone,
    role: item.user.role,
    name_settlement: item.settlement.name,
  };
};

/**
 * @description Получаем цвет для <Badge /> согласно роли.
 * @param {ROLES} role роль пользователя.
 * @return {string} Возвращает название цвета.
 */
export const getUserRoleColorHandler = (role: ROLES | undefined) => {
  switch (role) {
    case ROLES.CLIENT:
      return 'blue';
    case ROLES.OWNER_AND_CLIENT:
      return 'blue';
    case ROLES.GUEST:
      return 'orange';
    case ROLES.OWNER:
      return 'green';
  }
};
/**
 * @description Получаем имя для <Badge /> согласно роли.
 * @param {ROLES} role роль пользователя.
 * @return {string} Возвращает название роли.
 */
export const getUserRoleNameHandler = (role: ROLES | undefined) => {
  switch (role) {
    case ROLES.CLIENT:
      return 'клиент';
    case ROLES.OWNER_AND_CLIENT:
      return 'житель/клиент';
    case ROLES.GUEST:
      return 'гость';
    case ROLES.OWNER:
      return 'житель';
  }
};

export const imgToBase64 = function (imageURL: any) {
  return fetch(imageURL)
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();

        reader.onloadend = function () {
          return resolve({ file: reader.result, name: imageURL });
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};
export const checkError = (errorObj: any) => {
  if (errorObj) {
    if ('status' in errorObj) {
      return errorObj.data.detail;
    }
  }
  return undefined;
};
export const checkErrorStatus = (errorObj: any) => {
  if (errorObj) {
    if ('status' in errorObj) {
      return errorObj.status;
    }
  }
  return undefined;
};

export const datePars = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const months = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноб', 'дек'];
  return day + ' ' + months[Number(month) - 1] + ' ' + year;
};

export const getSettlementName = (data: Ilikes) => {
  return defaultValue(data?.name_settlement, '');
};

export const getPlotAddress = (data: IUserAddresses) => {
  if (!data?.address?.street__name) {
    return 'н/д';
  }
  const street = data?.address?.street__name;
  const realty = `${data?.address?.number} ${data?.address?.corpus ? `к.${data?.address?.corpus.toUpperCase()}` : ''}`;
  return `${street} ${realty}`;
};

export const getUserAddress = (data: IUserRealty) => {
  const settlement = defaultValue(data?.street?.settlement, {});
  const street = defaultValue(data?.street, {});
  const realty_id = defaultValue(`${data?.id}`, undefined);
  return {
    settlement: {
      id: settlement?.id && Number(settlement?.id),
      name: settlement?.name ?? '',
    },
    street: { id: street?.id && Number(street?.id), name: street?.name },
    realty: {
      id: realty_id && Number(realty_id),
      name: `${data?.number ? data?.number : ''} ${data?.corpus ? `к.${data?.corpus.toUpperCase()}` : ''}`,
    },
    address: {
      id: realty_id,
      name: `${street?.name ? street?.name : ' '} ${data?.number ? data?.number : ''} ${
        data?.corpus ? `к.${data?.corpus.toUpperCase()}` : ''
      }`,
    },
  };
};
export const urlToObject = async (path: string) => {
  const response = await fetch(path);
  const blob = await response.blob();
  const type = path.slice(path.lastIndexOf('.') + 1);
  const file = new File([blob], `file.${type}`, { type: blob.type });
  return file;
};

//TODO rewrite this function
export function base64ToFile(base64: string, filename: string) {
  const arr = base64.split(',');
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const format = mime.split('/');
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], `${filename}.${format[1]}`, { type: mime });
}

export const isNeedComma = (needComma: boolean) => (needComma ? ',' : '');

export const declination = (count: number, words: string[]) => {
  return `${count} ${
    words[count % 100 > 4 && count % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][count % 10 < 5 ? Math.abs(count) % 10 : 5]]
  }`;
};

/**
 * @description Преобразует строку формата base64 в формат svg файла.
 * @example "data:image/svg+xml;base64,PH..." преобразуется в "data:image/svg;base64,PH...".
 * @param {string} data строка формата base64.
 * @return {string} Возвращает строку с расширением data:image/svg.
 */
export const transformSvgVariantiesToSvg = (data: string) => {
  if (!data) {
    return undefined;
  }
  const firstPartIndex = data?.indexOf('/') + 1;
  const lastPartIndex = data?.indexOf(';');
  if (data.slice(firstPartIndex, lastPartIndex).includes('svg')) {
    return `${data?.slice(0, data?.indexOf('/') + 1)}svg${data?.slice(data?.indexOf(';'))}`;
  }
  return undefined;
};

/**
 * @description Меняет индексы элементов массива.
 * @example arr:[1,2,3], from: 1, to: 0 -> [2,1,3]
 * @param {Array} arr исходный массив.
 * @param {number} from старый индекс в массиве.
 * @param {number} to новый индекс в массиве.
 * @return {Array} Возвращает новый массив, с измененными индексами
 */
export const reorder = <T>({ arr, from, to }: { arr: T[]; from: number; to: number }): T[] => {
  const cloned = [...arr];
  const item = arr[from];

  cloned.splice(from, 1);
  cloned.splice(to, 0, item);

  return cloned;
};
