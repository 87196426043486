import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITablePagination, ITableState } from '7-shared/types';

import { IServicelSupport, IServiceSupportListFilters } from './types';

interface IInitialState {
  table: ITableState<IServicelSupport>;
  pagination: ITablePagination;
  filters: IServiceSupportListFilters;
}

const initialState: IInitialState = {
  table: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 0,
    total_count: 0,
    page_size: 10,
  },
  filters: {
    settlement: undefined,
    id: undefined,
    account_id: undefined,
    name: undefined,
    role: undefined,
    phone: undefined,
    email: undefined,
    address_street: undefined,
    address_realty: undefined,
    plot: undefined,
    comment: undefined,
    dateOfCreation_start: undefined,
    dateOfCreation_end: undefined,
    dateOfClosed_start: undefined,
    dateOfClosed_end: undefined,
    manager: undefined,
    score: undefined,
    status: undefined,
  },
};

export const serviceSupportSlice = createSlice({
  name: 'serviceSupport',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<IServicelSupport>>>) => {
      state.table = { ...state.table, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setListFilters: (state, action: PayloadAction<Partial<IServiceSupportListFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setInitialServiceSupportstate: () => initialState,
  },
});

export const selectServiceSupportListTableData = (state: RootState) => state.serviceSupport.table;
export const selectServiceSupportListPagination = (state: RootState) => state.serviceSupport.pagination;
export const selectServiceSupportListFilters = (state: RootState) => state.serviceSupport.filters;

export const serviceSupportActions = serviceSupportSlice.actions;
