import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ICategory,
  ICategoryForm,
  IEditBody,
  IPaginationResponse,
  IQueryParams,
  ISecondSubCategory,
  ISecondSubCategoryForm,
  ISubCategory,
  ISubCategoryForm,
  IUpdateCategoryIndex,
  IUpdateUsersIndex,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const mastersSectionsAPI = createApi({
  reducerPath: 'mastersSectionsAPI',
  tagTypes: ['mastersSections', 'mastersSubSections', 'mastersSecondSubSections'],
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getMastersSections: build.query<IPaginationResponse<ICategory[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `cms/master-sections/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['mastersSections'],
    }),
    getMastersSectionsInfo: build.query<ICategory, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/master-sections/${id}/`,
      }),
      providesTags: ['mastersSections'],
    }),
    createMasterSection: build.mutation<ICategory, ICategoryForm>({
      query: (data) => ({
        url: `cms/master-sections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mastersSections'],
    }),
    editMasterSection: build.mutation<ICategory, IEditBody<ICategoryForm>>({
      query: (data) => ({
        url: `cms/master-sections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['mastersSections'],
    }),
    deleteMasterSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/master-sections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['mastersSections'],
    }),
    updateMastersSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/master-sections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['mastersSections'],
    }),
    updateMastersUserSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/masters/update/sections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['mastersSections'],
    }),

    getMastersSubSections: build.query<IPaginationResponse<ISubCategory[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `cms/master-subsection-list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['mastersSubSections'],
    }),
    getMastersSubSectionsInfo: build.query<ISubCategory, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/master-subsections/${id}/`,
      }),
      providesTags: ['mastersSubSections'],
    }),
    createMasterSubSection: build.mutation<ISubCategory, ISubCategoryForm>({
      query: (data) => ({
        url: `cms/master-subsections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mastersSubSections'],
    }),
    editMasterSubSection: build.mutation<ISubCategory, IEditBody<ISubCategoryForm>>({
      query: (data) => ({
        url: `cms/master-subsections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['mastersSubSections'],
    }),
    deleteMasterSubSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/master-subsections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['mastersSubSections'],
    }),
    updateMastersSubSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/master-subsections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['mastersSubSections'],
    }),
    updateMastersUserSubSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/masters/update/subsections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['mastersSubSections'],
    }),

    getMastersSecondSubSections: build.query<IPaginationResponse<ISecondSubCategory[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `cms/master-second-subsection-list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['mastersSecondSubSections'],
    }),
    getMastersSecondSubSectionsInfo: build.query<IPaginationResponse<ISecondSubCategory[]>, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/master-second-subsections/${id}/`,
      }),
      providesTags: ['mastersSecondSubSections'],
    }),
    createMasterSecondSubSection: build.mutation<ISecondSubCategory, ISecondSubCategoryForm>({
      query: (data) => ({
        url: `cms/master-second-subsections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mastersSecondSubSections'],
    }),
    editMasterSecondSubSection: build.mutation<ISecondSubCategory, IEditBody<ISecondSubCategoryForm>>({
      query: (data) => ({
        url: `cms/master-second-subsections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['mastersSecondSubSections'],
    }),
    deleteMasterSecondSubSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/master-second-subsections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['mastersSecondSubSections'],
    }),
    updateMastersSecondSubSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/master-second-subsections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['mastersSecondSubSections'],
    }),
    updateMastersUserSecondSubSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/masters/update/second-subsections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['mastersSecondSubSections'],
    }),
  }),
});

export const {
  useUpdateMastersSubSectionIndexMutation,
  useUpdateMastersUserSubSectionIndexMutation,
  useUpdateMastersSecondSubSectionIndexMutation,
  useUpdateMastersUserSecondSubSectionIndexMutation,
  useUpdateMastersUserSectionIndexMutation,
  useUpdateMastersSectionIndexMutation,
  useGetMastersSubSectionsInfoQuery,
  useGetMastersSectionsInfoQuery,
  useCreateMasterSecondSubSectionMutation,
  useCreateMasterSubSectionMutation,
  useDeleteMasterSecondSubSectionMutation,
  useDeleteMasterSubSectionMutation,
  useEditMasterSecondSubSectionMutation,
  useEditMasterSubSectionMutation,
  useGetMastersSecondSubSectionsQuery,
  useGetMastersSubSectionsQuery,
  useCreateMasterSectionMutation,
  useGetMastersSectionsQuery,
  useEditMasterSectionMutation,
  useDeleteMasterSectionMutation,
} = mastersSectionsAPI;
