import React from 'react';
import { Pagination } from '7-shared/ui';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { newsActions, selectNewsListPagination } from '6-entities/news';

interface IProps {
  onChange: (fieldName: 'page', value: string) => void;
}

export const LikesPagination = ({ onChange }: IProps) => {
  const dispatch = useAppDispatch();

  const tablePagination = useAppSelector(selectNewsListPagination);

  const onChangePagination = (newPage: number) => {
    dispatch(newsActions.setListPagination({ page: newPage }));
    onChange('page', `${newPage}`);
  };

  return (
    <Pagination
      setPage={onChangePagination}
      activePage={tablePagination.page}
      total={Math.ceil(tablePagination.total_count / tablePagination.page_size) ?? 1}
    />
  );
};
