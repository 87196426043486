import { useGetUserInfoQuery } from 'store/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { STAFF_ROLES } from 'interface';
import { INavbarLinks, navBarLinks } from 'shared/constants';
import { showNotification } from '@mantine/notifications';
import { useLocales } from 'shared/hooks';
import { useAppSelector } from '7-shared/hooks';
import {
  selectIsExistsNewFeedbackBuilder,
  selectIsExistsNewFeedbackCommunal,
  selectIsExistsNewFeedbackSupport,
} from '6-entities/app';

export const useManagerPermissions = () => {
  const { t } = useLocales();
  const location = useLocation();
  const navigate = useNavigate();

  const [accessLinks, setAccessLinks] = useState<INavbarLinks[]>([]);
  const isExistsNewFeedbackSupport = useAppSelector(selectIsExistsNewFeedbackSupport);
  const isExistsNewFeedbackCommunal = useAppSelector(selectIsExistsNewFeedbackCommunal);
  const isExistsNewFeedbackBuilder = useAppSelector(selectIsExistsNewFeedbackBuilder);

  const { data, isLoading } = useGetUserInfoQuery();

  useEffect(() => {
    if (!isLoading) {
      if (data?.role === STAFF_ROLES.DIRECTOR || !data?.role) {
        accessDirectorHandler();
      }
      if (data?.role === STAFF_ROLES.MANAGER) {
        accessManagerHandler();
      }
      if (data?.role === STAFF_ROLES.GUARD) {
        accessGuardHandler();
      }
    }
  }, [isExistsNewFeedbackSupport, isExistsNewFeedbackCommunal, isExistsNewFeedbackBuilder, data, isLoading]);

  const accessGuardHandler = async () => {
    setAccessLinks(
      navBarLinks(t, isExistsNewFeedbackSupport, isExistsNewFeedbackCommunal, isExistsNewFeedbackBuilder).filter(
        (item) => data?.permissions.map((el) => el?.codename).includes(item.permissions)
      )
    );
  };

  const accessManagerHandler = async () => {
    setAccessLinks(
      navBarLinks(t, isExistsNewFeedbackSupport, isExistsNewFeedbackCommunal, isExistsNewFeedbackBuilder).filter(
        (item) => data?.permissions.map((el) => el?.codename).includes(item.permissions)
      )
    );
  };

  const accessDirectorHandler = async () => {
    setAccessLinks(navBarLinks(t, isExistsNewFeedbackSupport, isExistsNewFeedbackCommunal, isExistsNewFeedbackBuilder));
  };
  useEffect(() => {
    if (
      data?.role === STAFF_ROLES.MANAGER &&
      accessLinks.length > 0 &&
      navBarLinks(t, isExistsNewFeedbackSupport, isExistsNewFeedbackCommunal, isExistsNewFeedbackBuilder)
        .map((el) => el.path)
        .includes(location.pathname) &&
      !accessLinks.map((el) => el.path).includes(location.pathname)
    ) {
      showNotification({
        title: `${t('app.errors.permissions.title')}`,
        message: `${t('app.errors.permissions.description')}`,
        color: 'red',
      });
      navigate(accessLinks[0].path);
    }
  }, [accessLinks, location, accessDirectorHandler, accessManagerHandler]);

  return { accessLinks, isLoading };
};
