import { showNotification } from '@mantine/notifications';

export const useNotice = () => {
  const showNotice = (type: 'error' | 'success', title: string, message?: string) => {
    showNotification({
      color: type === 'error' ? 'red' : 'blue',
      title: title,
      message: message,
    });
  };

  return { showNotice };
};
