import React from 'react';
import { useParams } from 'react-router-dom';

import { IconChevronDown, IconChevronUp, IconMapPin } from '7-shared/assets';
import { BackArrow, Collapse, Flex, Group, Text, Tooltip } from '7-shared/ui';
import { useDisclosure } from '7-shared/hooks';

import { useGetSettlementQuery } from 'store/settlements';

export const SettlementInfo = () => {
  const { id } = useParams();
  const [opened, { toggle }] = useDisclosure(false);
  const { data: settlement } = useGetSettlementQuery({ id });
  return (
    <Flex direction={'column'} gap={10}>
      <Flex direction={'column'} align={'flex-start'} gap={10}>
        <Flex gap={10} align={'center'}>
          <BackArrow />
          <Text size={24} fw={600}>
            {settlement?.name}
          </Text>
        </Flex>
        <Flex gap={10}>
          <IconMapPin stroke={'white'} />
          <Tooltip className={'max-w-[600px]'} index={0} tooltip={`${settlement?.address}`}>
            {settlement?.address}
          </Tooltip>
        </Flex>
      </Flex>
      <Flex direction={'column'} gap={10}>
        <Group className={'cursor-pointer'} onClick={toggle} position="left" mb={5}>
          <Text fw={500}>Описание</Text>
          {opened ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
        </Group>

        <Collapse in={opened}>
          <Text>{settlement?.description}</Text>
        </Collapse>
      </Flex>
    </Flex>
  );
};
