import React from 'react';
import { Modal } from '7-shared/ui';

interface IProps {
  visible: boolean;
}

export const ConnectCameraModal = ({ visible }: IProps) => {
  return (
    <Modal title={'Подключаемся к камере...'} onClose={() => {}} loading={visible} opened={visible} size={'auto'}>
      <div className={'w-[300px] h-[300px] flex flex-col justify-center items-center'}></div>
    </Modal>
  );
};
