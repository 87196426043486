import React, { useEffect, useState } from 'react';
import { Box, Dropzone, FileWithPath, FileRejection, ImgCrop } from '../../../ui';
import { useController } from 'react-hook-form';
import emptyAvatar from '../../../assets/icons/empty_avatar.png';
import avatarImg from '../../../assets/icons/avatar.png';
import { imgToBase64 } from '../../../utils';

interface IProps {
  control: any;
  name: string;
  placeholder?: string;
  isSmall?: boolean;
  defaultValue: string;
  shape?: 'round' | 'rect';
  aspect?: number;
  classNameImg?: string;
  accept?: string[];
  disabled?: boolean;
}

export const AvatarUpload = ({
  defaultValue,
  control,
  name,
  placeholder = 'Загрузить фото',
  isSmall = false,
  shape = 'round',
  aspect = 1 / 1,
  classNameImg = '',
  accept = undefined,
  disabled = false,
}: IProps) => {
  const [image, setImage] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>('');

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  const onChangeHandler = async (data: FileWithPath[]) => {
    setOpen(true);
    data.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result;
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  };
  const onCloseCropModal = () => {
    setOpen(false);
    setImage(undefined);
  };
  useEffect(() => {
    if (value && value === defaultValue) {
      setInitialValue();
    }
  }, [value, defaultValue]);
  const setInitialValue = async () => {
    const res: any = await Promise.all([imgToBase64(value)]);
    onChange(res?.[0]?.file);
  };
  const checkTypeFile = (file: FileRejection[]) => {
    if (!accept) setError('');
    file.forEach((i) => (i.errors ? setError(`Разрешенные форматы:${accept}`) : setError('')));
  };

  const onSaveImage = (file: string) => {
    onCloseCropModal();
    onChange(file);
  };

  return (
    <Box
      className={
        isSmall
          ? 'relative flex flex-col gap-[10px] items-start w-max'
          : 'relative flex flex-col gap-[10px]  items-center w-full'
      }
    >
      <ImgCrop
        shape={shape}
        aspect={aspect}
        image={image}
        onOk={onSaveImage}
        onCancel={onCloseCropModal}
        visible={open}
      />
      <Dropzone onDrop={onChangeHandler} onReject={checkTypeFile} accept={accept}>
        <Box className={'flex flex-col items-center'}>
          <img
            alt={''}
            src={value ? value : isSmall ? emptyAvatar : avatarImg}
            className={
              !value && !defaultValue
                ? 'rounded-full max-w-[80px] max-h-[80px]'
                : isSmall
                ? 'rounded-full max-w-[54px] max-h-[54px]'
                : !!classNameImg
                ? `${classNameImg}`
                : 'rounded-full '
            }
          />
          {!isSmall && (
            <Box
              className={
                disabled
                  ? 'text-blue font-500 text-center text-black_text_opacity_20'
                  : 'text-blue font-500 text-center'
              }
            >
              {placeholder}
            </Box>
          )}
        </Box>
      </Dropzone>
      <span className={'text-center text-red-500'}>{error}</span>
    </Box>
  );
};
