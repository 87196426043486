import React from 'react';
import { NavLink } from 'react-router-dom';
import { Center, Container, Loader, Navbar as MantineNavbar, Text } from '@mantine/core';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { IconLogout } from '@tabler/icons';
import { INavbarLinks } from 'shared/constants';
import { useLocales } from 'shared/hooks';

interface IProps {
  logoutHandler: () => void;
  accessLinks: INavbarLinks[];
  isLoading: boolean;
}

export const Navbar = ({ logoutHandler, accessLinks, isLoading }: IProps) => {
  const { t } = useLocales();
  return (
    <MantineNavbar className={'bg-main_blue_gb h-full overflow-auto'} width={{ base: 270 }} pt={10}>
      <MantineNavbar.Section className={'flex justify-center'}>
        <Logo />
      </MantineNavbar.Section>
      <MantineNavbar.Section grow mt="md">
        {isLoading ? (
          <Center className={'h-full w-full'}>
            <Loader size="sm" />
          </Center>
        ) : (
          <>
            {accessLinks?.map((i) => (
              <NavLink
                data-cy={`navbar_link_${i.permissions}`}
                key={i.path}
                replace
                className={({ isActive }) =>
                  `flex gap-4 px-4 py-2 items-center font-400 text-1.6  hover:bg-white_opacity_7 hover:!text-white hover:!fill-white ${
                    isActive
                      ? 'bg-white_opacity_7 fill-white text-white'
                      : 'opacity-80 fill-white_opacity_75 text-white_opacity_75'
                  }`
                }
                to={i.path}
              >
                <div className={'h-[20px] w-[20px]'}>{i.icon}</div>
                <span>{i.title}</span>
              </NavLink>
            ))}
          </>
        )}
      </MantineNavbar.Section>
      <MantineNavbar.Section>
        <Container
          data-cy={'navbar_logout'}
          onClick={logoutHandler}
          className={
            'px-4 py-2 cursor-pointer flex gap-4 items-center text-white opacity-80 text-1.6 hover:bg-white_opacity_7 hover:fill-white hover:text-white'
          }
        >
          <IconLogout size={22} />
          <Text> {t('app.navbar.logout')}</Text>
        </Container>
      </MantineNavbar.Section>
    </MantineNavbar>
  );
};
