import { createAsyncThunk } from '@reduxjs/toolkit';
import { templatesApi } from '../api/templates.api';
import { templatesActions } from './templatesSlice';
import { mapTemplatesList } from '../lib/mapTemplatesList';
import { ICommonErrorReject } from '7-shared/api/types';
import { ITemplatesForm, ITemplatesType } from '../api/types';

export const getTemplatesListThunk = createAsyncThunk<void, ITemplatesType, { state: RootState }>(
  'templates/getTemplatesList',
  async (type, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(templatesActions.setListTableData({ isLoading: true }));

      const pagination = getState().template.pagination;
      const result = await templatesApi.getTechnicalTemplatesList(pagination.page, pagination.page_size, type);
      const list = result.results.map((i) => mapTemplatesList(i));
      dispatch(templatesActions.setListTableData({ data: list }));
      dispatch(templatesActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(templatesActions.setListTableData({ isLoading: false }));
    }
  }
);
export const editTemplateCommentThunk = createAsyncThunk<
  void,
  { id: number; data: ITemplatesForm },
  { state: RootState }
>('templates/editTemplateComment', async ({ id, data }, { dispatch, getState, rejectWithValue }) => {
  try {
    const type = getState().template.table.data[0].type;
    dispatch(templatesActions.setTemplateModalState({ isLoading: true }));
    await templatesApi.editTechnicalTemplateComment(id, data);
    dispatch(getTemplatesListThunk(type));
  } catch (error) {
    return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
  } finally {
    dispatch(templatesActions.setTemplateModalState({ isLoading: false }));
  }
});
export const createTemplateCommentThunk = createAsyncThunk<void, ITemplatesForm, { state: RootState }>(
  'templates/createTemplateComment',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(templatesActions.setTemplateModalState({ isLoading: true }));
      await templatesApi.createTechnicalTemplateComment(data);
      dispatch(getTemplatesListThunk(data.type));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(templatesActions.setTemplateModalState({ isLoading: false }));
    }
  }
);
export const deleteTemplateCommentThunk = createAsyncThunk<void, number, { state: RootState }>(
  'templates/deleteTemplateComment',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const type = getState().template.table.data[0].type;
      dispatch(templatesActions.setListTableData({ isLoading: true }));
      await templatesApi.deleteTechnicalTemplateComment(id);
      dispatch(getTemplatesListThunk(type));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(templatesActions.setListTableData({ isLoading: false }));
    }
  }
);
