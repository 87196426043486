import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditBody,
  IPaginationResponse,
  IQueryParams,
  ITechnicalTemplate,
  ITechnicalTemplateForm,
  ITechnicalTemplateQueryParams,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const technicalSupportTemplatesApi = createApi({
  reducerPath: 'technicalSupportTemplatesApi',
  tagTypes: ['technicalSupportTemplatesList'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getTechnicalTemplatesList: build.query<
      IPaginationResponse<ITechnicalTemplate[]>,
      Record<ITechnicalTemplateQueryParams, string | number | undefined>
    >({
      query: (params) => ({
        url: `cms/feedback-templates/`,
        params: { ...params, page_size: params.size },
      }),
      providesTags: ['technicalSupportTemplatesList'],
    }),
    createTechnicalTemplateComment: build.mutation<ITechnicalTemplate, ITechnicalTemplateForm>({
      query: (data) => ({
        url: `cms/feedback-templates/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['technicalSupportTemplatesList'],
    }),
    updateTechnicalTemplateComment: build.mutation<ITechnicalTemplate, IEditBody<ITechnicalTemplateForm>>({
      query: (data) => ({
        url: `cms/feedback-templates/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['technicalSupportTemplatesList'],
    }),
    deleteTechnicalTemplateComment: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/feedback-templates/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['technicalSupportTemplatesList'],
    }),
  }),
});

export const {
  useGetTechnicalTemplatesListQuery,
  useCreateTechnicalTemplateCommentMutation,
  useUpdateTechnicalTemplateCommentMutation,
  useDeleteTechnicalTemplateCommentMutation,
} = technicalSupportTemplatesApi;
