import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalState, ITablePagination, ITableState } from '7-shared/types';

import { IReports, IReportsFilters } from './types';
import { ITemplateReports } from '../api/types';

interface IInitialState {
  table: ITableState<IReports>;
  tableTemplates: ITableState<ITemplateReports>;
  filters: IReportsFilters;
  pagination: ITablePagination;
  modal: IModalState<IReports>;
  modalTemplates: IModalState<ITemplateReports>;
}

const initialState: IInitialState = {
  table: {
    data: [],
    isLoading: false,
  },
  tableTemplates: {
    data: [],
    isLoading: false,
  },
  filters: {
    type: undefined,
    settlement: undefined,
    search: undefined,
  },
  pagination: {
    page: 1,
    total_count: 0,
    page_size: 10,
  },
  modal: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
  modalTemplates: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<IReports>>>) => {
      state.table = { ...state.table, ...action.payload };
    },
    setListTableTemplatesData: (state, action: PayloadAction<Partial<ITableState<ITemplateReports>>>) => {
      state.tableTemplates = { ...state.tableTemplates, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setReportsModalState: (state, action: PayloadAction<Partial<IModalState<IReports>>>) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setReportsModalTemplatesState: (state, action: PayloadAction<Partial<IModalState<ITemplateReports>>>) => {
      state.modalTemplates = { ...state.modalTemplates, ...action.payload };
    },
    setListFilters: (state, action: PayloadAction<Partial<IReportsFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setInitialReportsstate: () => initialState,
  },
});

export const selectReportsListTableData = (state: RootState) => state.reports.table;
export const selectReportsTemplatesListTableData = (state: RootState) => state.reports.tableTemplates;
export const selectReportsListPagination = (state: RootState) => state.reports.pagination;
export const selectIsLoading = (state: RootState) => state.reports.table.isLoading;
export const selectReportsTemplatesModalState = (state: RootState) => state.reports.modalTemplates;
export const selectReportsModalState = (state: RootState) => state.reports.modal;
export const selectReportsListFilters = (state: RootState) => state.reports.filters;

export const reportsActions = reportsSlice.actions;
