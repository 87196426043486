import type { FieldErrors, Path, UseFormSetError, ErrorOption, FieldValues } from 'react-hook-form';

type ErrorSummaryProps<T extends FieldValues = FieldValues> = {
  errors: FieldErrors<T>;
  setError: UseFormSetError<T>;
};

export const formErrorHandler = <T extends FieldValues>({ errors, setError }: ErrorSummaryProps<T>) => {
  const detectMessage = (value: any): string => {
    if (Array.isArray(value)) {
      return value.join(' ');
    }
    if (typeof value === 'object') {
      return value.message as string;
    }

    return value;
  };
  for (const [key, value] of Object.entries(errors)) {
    setError(key as Path<T>, { message: detectMessage(value) } as ErrorOption);
  }
};
