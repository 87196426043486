import { createAsyncThunk } from '@reduxjs/toolkit';
import { serviceSupportActions } from './serviceSupport.slice';
import { serviceSupportApi } from '../api/serviceSupport.api';
import { mapServiceSupportList } from '../lib/mapServicesSupportList';
import { mapFiltersServiceSupport } from '../lib/mapFiltersServiceSuppor';

export const getServiceSupportListThunk = createAsyncThunk<void, void, { state: RootState }>(
  'serviceSupport/getServiceSupportList',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(serviceSupportActions.setListTableData({ isLoading: true }));

      const filters = mapFiltersServiceSupport(getState().serviceSupport.filters);
      const pagination = getState().serviceSupport.pagination;

      const result = await serviceSupportApi.getServiceSupportList(pagination.page, pagination.page_size, filters);
      const list = result.results.map((i) => mapServiceSupportList(i));
      dispatch(serviceSupportActions.setListTableData({ data: list }));
      dispatch(serviceSupportActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(serviceSupportActions.setListTableData({ isLoading: false }));
    }
  }
);
export const getServiceSupportReportThunk = createAsyncThunk<any, void, { state: RootState }>(
  'serviceSupport/getServiceSupportReport',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(serviceSupportActions.setListTableData({ isLoading: true }));
      const filters = mapFiltersServiceSupport(getState().serviceSupport.filters);
      serviceSupportApi.getServiceSupportReport(filters);
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(serviceSupportActions.setListTableData({ isLoading: false }));
    }
  }
);
