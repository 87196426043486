import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppDispatch, useFormAddress } from '7-shared/hooks';
import { AvatarUpload, Button, Input, Select, Textarea } from '7-shared/ui';
import {
  createReportThunk,
  editReportThunk,
  IReportForm,
  IReports,
  REPORT_TYPE,
  reportsActions,
} from '6-entities/reports';
import { reportType } from '../../../model/reportType';
import { reportSchema } from '../../../model/report.schema';
import { notification } from '7-shared/lib/notification';
import { isEmpty } from '7-shared/lib/lodash';

interface IProps {
  currentItem: IReports;
}

export const ReportForm = ({ currentItem }: IProps) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<IReportForm>({
    mode: 'onChange',
    resolver: yupResolver(reportSchema),
  });

  const { settlements, realties, streets } = useFormAddress(
    setValue,
    watch('settlement_id'),
    watch('street_id'),
    watch('realty_id')
  );
  //Заполнение значениеями по умолчанию, при создании отчета
  useEffect(() => {
    reset({
      ...currentItem,
      type: REPORT_TYPE.personal,
    });
  }, [currentItem]);
  //В зависимости от типо отчета, сетаем значения в форму
  useEffect(() => {
    if (currentItem?.type === REPORT_TYPE.personal) {
      reset({
        preview: currentItem?.preview,
        type: REPORT_TYPE.personal,
        settlement_id: `${currentItem?.settlement.id}`,
        street_id: `${currentItem?.realty.street.id}`,
        realty_id: `${currentItem?.realty.id}`,
        title: currentItem?.title,
        description: currentItem?.description,
        link: currentItem?.link,
      });
    }
    if (currentItem?.type === REPORT_TYPE.common) {
      reset({
        preview: currentItem?.preview,
        type: REPORT_TYPE.common,
        settlement_id: `${currentItem?.settlement.id}`,
        title: currentItem?.title,
        description: currentItem?.description,
        link: currentItem?.link,
      });
    }
  }, [currentItem]);

  const onCloseModalHandler = () => {
    dispatch(reportsActions.setReportsModalState({ open: false, currentItem: undefined }));
    reset();
  };

  const onSubmit = (data: IReportForm) => {
    //В зависимоти от типа отчета, в запросе отправляем либо "realty_id", либо "settlement_id"
    const { realty_id, street_id, settlement_id, ...rest } = data;
    const newDataCommon: IReportForm = {
      title: data.title,
      description: data.description,
      preview: data.preview,
      link: data.link,
      settlement_id: data.settlement_id,
    };
    const newData: IReportForm = {
      title: data.title,
      description: data.description,
      preview: data.preview,
      link: data.link,
      realty_id: data.realty_id,
    };
    if (isEmpty(currentItem)) {
      rest.type === REPORT_TYPE.common ? createReportHandler(newDataCommon) : createReportHandler(newData);
    } else {
      rest.type === REPORT_TYPE.common ? editReportHandler(newDataCommon) : editReportHandler(newData);
    }
    reset();
  };

  const createReportHandler = (data: IReportForm) => {
    dispatch(createReportThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Создание запрещено',
        });
      });
  };

  const editReportHandler = (data: IReportForm) => {
    dispatch(editReportThunk({ id: `${currentItem.id}`, data }))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error.message ?? 'Редактирование запрещено',
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-2 w-full items-center justify-center'}>
      <AvatarUpload
        defaultValue={isEmpty(currentItem) ? '' : (currentItem?.preview as string)}
        control={control}
        name={'preview'}
        placeholder={!!watch('preview') ? 'Изменить картинку-превью' : 'Загрузить картинку-превью'}
        shape={'rect'}
        aspect={4 / 3}
        classNameImg={'!rounded-[5px] w-[240px] h-max'}
        accept={['.jpg', '.jpeg', '.png']}
      />
      <Select className={'w-full'} name={'type'} placeholder={'Тип отчета'} control={control} data={reportType} />
      <Select className={'w-full'} name={'settlement_id'} placeholder={'КП'} control={control} data={settlements} />
      {watch('type') === REPORT_TYPE.personal && (
        <>
          <Select
            className={'w-full'}
            disabled={!watch('settlement_id')}
            name={'street_id'}
            placeholder={'Улица'}
            control={control}
            data={streets}
          />
          <Select
            className={'w-full'}
            disabled={!watch('street_id')}
            name={'realty_id'}
            placeholder={'№ дома'}
            control={control}
            data={realties}
          />
        </>
      )}
      <Input className={'w-full'} name={'title'} control={control} placeholder={'Название'} />
      <Textarea className={'w-full'} name={'description'} control={control} placeholder={'Описание'} />
      <Input className={'w-full'} name={'link'} control={control} placeholder={'Ссылка перехода'} />
      <Button disabled={!isValid} mt={30} size={'md'} type={'submit'} fullWidth className={'mt-[15px] w-full'}>
        Сохранить отчет
      </Button>
    </form>
  );
};
