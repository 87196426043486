import { ITemplateReports } from '../api/types';

export function mapTempleatessList(dto: ITemplateReports): ITemplateReports {
  return {
    id: dto.id,
    time_created: dto.time_created,
    time_updated: dto.time_updated,
    title: dto.title,
    description: dto.description,
    preview: dto.preview,
  };
}
