import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Input, Select, Textarea, AvatarUpload } from '7-shared/ui';
import {
  createReportViaTemplateThunk,
  IReportForm,
  IReports,
  ITemplateReports,
  reportsActions,
} from '6-entities/reports';
import { getRealtyThunk, getStreetsListThunk, selectRealty, selectSettlements, selectStreets } from '7-shared/api';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { collectObjectSelect } from '../../../model/utils/collectObjectSelect.utils';
import { templatesSchema } from '../../../model/templates.schema';
import { notification } from '7-shared/lib/notification';
import { isEmpty } from '7-shared/lib/lodash';
import { getUserAddress } from '../../../model/utils/getUserAddress.utils';

interface IProps {
  templates: ITemplateReports[];
  currentItem: IReports;
}

export const ReportTemplateForm = ({ currentItem, templates }: IProps) => {
  const dispatch = useAppDispatch();
  const settlements = collectObjectSelect(useAppSelector(selectSettlements));
  const streets = useAppSelector(selectStreets);
  const realty = useAppSelector(selectRealty);

  const streetsSelect = collectObjectSelect(streets);
  const realtysSelect = collectObjectSelect(realty);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<IReportForm>({
    mode: 'onChange',
    resolver: yupResolver(templatesSchema),
  });
  //Заполнение значениеями по умолчанию, при создании отчета
  useEffect(() => {
    reset({
      ...currentItem,
      settlement_id: !isEmpty(currentItem) ? getUserAddress(currentItem?.realty)?.settlement?.id : undefined,
      street_id: !isEmpty(currentItem) ? getUserAddress(currentItem?.realty)?.street?.id : undefined,
      realty_id: !isEmpty(currentItem) ? getUserAddress(currentItem?.realty)?.realty?.id : undefined,
      report_template_id: `${currentItem?.template}` || undefined,
    });
  }, [currentItem]);
  useEffect(() => {
    if (watch('report_template_id')) {
      const currentTemplate = templates.find((el) => String(el.id) === watch('report_template_id'));
      setValue('title', currentTemplate?.title || '');
      setValue('preview', currentTemplate?.preview || '');
      setValue('description', currentTemplate?.description || '');
    }
  }, [watch('report_template_id')]);
  useEffect(() => {
    if (watch('settlement_id')) {
      dispatch(getStreetsListThunk(watch('settlement_id') as string));
      setValue('street_id', null as any);
      setValue('realty_id', null as any);
    }
  }, [watch('settlement_id')]);

  useEffect(() => {
    if (watch('street_id')) {
      dispatch(getRealtyThunk(watch('street_id') as string));
      setValue('realty_id', null as any);
    }
  }, [watch('street_id')]);

  const onSubmit = (data: IReportForm) => {
    createReportViaTemplateHandler(data);
  };
  const onCloseModalHandler = () => {
    dispatch(reportsActions.setReportsModalState({ open: false, currentItem: undefined }));
    reset();
  };
  const createReportViaTemplateHandler = (data: IReportForm) => {
    const newData = {
      link: data.link,
      report_template_id: Number(data?.report_template_id),
      realty_id: Number(data.realty_id),
    };
    dispatch(createReportViaTemplateThunk(newData))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Создание запрещено',
        });
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-[8px] w-full items-center justify-center'}>
      <AvatarUpload
        disabled={true}
        defaultValue={isEmpty(currentItem) ? '' : currentItem?.preview}
        control={control}
        name={'preview'}
        placeholder={!!watch('preview') ? 'Изменить картинку-превью' : 'Загрузить картинку-превью'}
        shape={'rect'}
        aspect={4 / 3}
        classNameImg={'!rounded-[5px] w-[240px] h-max'}
        accept={['.jpg', '.jpeg', '.png']}
      />
      <Select
        className={'w-full'}
        name={'report_template_id'}
        placeholder={'Шаблон'}
        control={control}
        data={templates.map((el) => ({
          value: `${el.id}`,
          label: el.title,
        }))}
      />
      <Select className={'w-full'} name={'settlement_id'} placeholder={'КП'} control={control} data={settlements} />
      <Select
        className={'w-full'}
        disabled={!watch('settlement_id')}
        name={'street_id'}
        placeholder={'Улица'}
        control={control}
        data={streetsSelect}
      />
      <Select
        className={'w-full'}
        disabled={!watch('street_id')}
        name={'realty_id'}
        placeholder={'№ дома'}
        control={control}
        data={realtysSelect}
      />
      <Input className={'w-full'} disabled={true} name={'title'} control={control} placeholder={'Название'} />
      <Textarea className={'w-full'} disabled={true} name={'description'} control={control} placeholder={'Описание'} />
      <Input className={'w-full'} name={'link'} control={control} placeholder={'Ссылка перехода'} />
      <Button disabled={!isValid} mt={30} size={'md'} type={'submit'} fullWidth className={'mt-[15px] w-full'}>
        Сохранить отчет
      </Button>
    </form>
  );
};
