import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ICategory,
  ICategoryForm,
  IEditBody,
  IPaginationResponse,
  IQueryParams,
  ISecondSubCategory,
  ISecondSubCategoryForm,
  ISubCategory,
  ISubCategoryForm,
  IUpdateCategoryIndex,
  IUpdateUsersIndex,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const servicesSectionsAPI = createApi({
  reducerPath: 'servicesSectionsAPI',
  tagTypes: ['servicesSections', 'servicesSubSections', 'servicesSecondSubSections'],
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getServiceSections: build.query<IPaginationResponse<ICategory[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `cms/service-sections/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['servicesSections'],
    }),
    getServiceSectionsInfo: build.query<ICategory, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/service-sections/${id}/`,
      }),
      providesTags: ['servicesSections'],
    }),
    createServiceSection: build.mutation<ICategory, ICategoryForm>({
      query: (data) => ({
        url: `cms/service-sections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['servicesSections'],
    }),
    editServiceSection: build.mutation<ICategory, IEditBody<ICategoryForm>>({
      query: (data) => ({
        url: `cms/service-sections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['servicesSections'],
    }),
    deleteServiceSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/service-sections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['servicesSections'],
    }),
    updateServicesSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/service-sections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['servicesSections'],
    }),
    updateServicesUserSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/services/update/sections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['servicesSections'],
    }),

    getServiceSubSections: build.query<IPaginationResponse<ISubCategory[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `cms/service-subsection-list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['servicesSubSections'],
    }),
    getServiceSubSectionsInfo: build.query<ISubCategory, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/service-subsections/${id}/`,
      }),
      providesTags: ['servicesSubSections'],
    }),
    createServiceSubSection: build.mutation<ISubCategory, ISubCategoryForm>({
      query: (data) => ({
        url: `cms/service-subsections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['servicesSubSections'],
    }),
    editServiceSubSection: build.mutation<ISubCategory, IEditBody<ISubCategoryForm>>({
      query: (data) => ({
        url: `cms/service-subsections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['servicesSubSections'],
    }),
    deleteServiceSubSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/service-subsections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['servicesSubSections'],
    }),
    updateServicesSubSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/service-subsections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['servicesSubSections'],
    }),
    updateServicesUserSubSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/services/update/subsections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['servicesSubSections'],
    }),

    getServiceSecondSubSections: build.query<IPaginationResponse<ISecondSubCategory[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `cms/service-second-subsection-list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['servicesSecondSubSections'],
    }),
    getServiceSecondSubSectionsInfo: build.query<IPaginationResponse<ISecondSubCategory[]>, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/service-second-subsections/${id}/`,
      }),
      providesTags: ['servicesSecondSubSections'],
    }),
    createServiceSecondSubSection: build.mutation<ISecondSubCategory, ISecondSubCategoryForm>({
      query: (data) => ({
        url: `cms/service-second-subsections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['servicesSecondSubSections'],
    }),
    editServiceSecondSubSection: build.mutation<ISecondSubCategory, IEditBody<ISecondSubCategoryForm>>({
      query: (data) => ({
        url: `cms/service-second-subsections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['servicesSecondSubSections'],
    }),
    deleteServiceSecondSubSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/service-second-subsections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['servicesSecondSubSections'],
    }),
    updateServicesSecondSubSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/service-second-subsections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['servicesSecondSubSections'],
    }),
    updateServicesUserSecondSubSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/services/update/second-subsections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['servicesSecondSubSections'],
    }),
  }),
});

export const {
  useCreateServiceSecondSubSectionMutation,
  useUpdateServicesUserSubSectionIndexMutation,
  useUpdateServicesSecondSubSectionIndexMutation,
  useUpdateServicesSectionIndexMutation,
  useUpdateServicesSubSectionIndexMutation,
  useUpdateServicesUserSecondSubSectionIndexMutation,
  useUpdateServicesUserSectionIndexMutation,
  useCreateServiceSectionMutation,
  useCreateServiceSubSectionMutation,
  useGetServiceSecondSubSectionsQuery,
  useGetServiceSectionsQuery,
  useGetServiceSubSectionsQuery,
  useDeleteServiceSecondSubSectionMutation,
  useDeleteServiceSubSectionMutation,
  useDeleteServiceSectionMutation,
  useGetServiceSectionsInfoQuery,
  useGetServiceSubSectionsInfoQuery,
  useEditServiceSecondSubSectionMutation,
  useEditServiceSectionMutation,
  useEditServiceSubSectionMutation,
} = servicesSectionsAPI;
