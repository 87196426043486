import React from 'react';
import { Tooltip } from '7-shared/ui';
import { ITemplateReports } from '6-entities/reports';
import { TemplatesTableActions } from '../ui/templatesTableActions/templatesTableActions';
import { TemplatesNumberRow } from '../ui/templatesTable/tempatesNumberRow/tempatesNumberRow';

export interface TemplatesColumnTable {
  accessor: keyof ITemplateReports;
  render?: (value: ITemplateReports, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: TemplatesColumnTable[] = [
  {
    accessor: 'id',
    render: (value, index) => <TemplatesNumberRow value={value} index={index} />,
    width: 100,
    ellipsis: true,
  },
  {
    accessor: 'title',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.title}`}>
        {value.title}
      </Tooltip>
    ),
    width: 500,
    ellipsis: true,
  },
  {
    accessor: 'description',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.description}`}>
        {value.description}
      </Tooltip>
    ),
    width: 500,
    ellipsis: true,
  },
  {
    accessor: 'actions',
    render: (value) => <TemplatesTableActions record={value} />,
    width: 100,
  },
];
