import { defaultTo } from '7-shared/lib/lodash';
import { IUser, ROLES, STAFF_ROLES } from 'interface';

export const getFullName = (data: IUser, isGuest = false) => {
  return isGuest ? 'Гость' : `${defaultTo(data?.first_name, '')} ${defaultTo(data?.last_name, '')}`;
};

export const getStaffRoleNameHandler = (role: STAFF_ROLES) => {
  switch (role) {
    case STAFF_ROLES.MANAGER:
      return 'Менеджер';
    case STAFF_ROLES.DIRECTOR:
      return 'Руководитель';
    case STAFF_ROLES.GUARD:
      return 'Охранник';
  }
};

export const getUserRoleNameHandler = (role: ROLES) => {
  switch (role) {
    case ROLES.CLIENT:
      return 'клиент';
    case ROLES.OWNER_AND_CLIENT:
      return 'житель/клиент';
    case ROLES.GUEST:
      return 'гость';
    case ROLES.OWNER:
      return 'житель';
  }
};

export const getUserRoleColorHandler = (role: ROLES) => {
  switch (role) {
    case ROLES.CLIENT:
      return 'blue';
    case ROLES.OWNER_AND_CLIENT:
      return 'blue';
    case ROLES.GUEST:
      return 'orange';
    case ROLES.OWNER:
      return 'green';
  }
};
