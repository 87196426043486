import React from 'react';
import loadable from '@loadable/component';
import { createBrowserRouter } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { AuthLayout, DashboardLayout } from 'layouts';
import { ROUTE_PATH } from './shared/constants';

import { TechnicalSupportLayout, TechnicalSupportList, TechnicalSupportTemplates } from '3-pages/technicalSupport';
import { ServiceSupportLayout, ServiceSupportList, ServiceSupportTemplates } from '3-pages/serviceSupport';

import { BuilderSupportLayout, BuilderSupportList, BuilderSupportTemplates } from '3-pages/builderSupport';
import { PlotsLayout, PlotsList } from '3-pages/plots';
import { ReportsList, ReportsLayout, ReportsTemplates } from '3-pages/reports';
import { SettlementsList, CreateSettlementPage } from '3-pages/settlements';
import { VideosList, CamerasList } from '3-pages/videos';
import { LikesList } from '3-pages/likes';

import { routes } from '7-shared/lib/routes';

const routesHandler = {
  // @ts-ignore
  get(target, property) {
    const value = target[property];

    if (typeof value === 'object' && value !== null) {
      return new Proxy(value, routesHandler);
    }

    if (property === 'link') {
      // @ts-ignore
      return function (args) {
        let path = target.path || '';

        if (args) {
          for (const [key, value] of Object.entries(args)) {
            path = path.replaceAll(`:${key}`, value);
          }
        }

        return path;
      };
    }

    return value instanceof Function ? value.bind(target) : value;
  },
};

export const ROUTES = new Proxy(
  {
    LOGIN: {
      path: ROUTE_PATH.LOGIN,
    },
    FORGOT_PASSWORD: {
      path: ROUTE_PATH.FORGOT_PASSWORD,
    },
    RESET_PASSWORD: {
      path: ROUTE_PATH.RESET_PASSWORD,
    },
    DASHBOARD: {
      path: ROUTE_PATH.MAIN,
    },
    USERS: {
      path: ROUTE_PATH.USERS,
    },
    SETTLEMENTS: {
      path: routes.settlements.root,
    },
    SETTLEMENT: {
      path: `${routes.settlenent.root}/:id`,
    },
    CREATE_SETTLEMENTS: {
      path: routes.settlements.createSettlement,
    },
    STREETS: {
      path: `${ROUTE_PATH.SETTLEMENT}/:id${ROUTE_PATH.STREETS}`,
    },
    PLOTS: {
      path: `${ROUTE_PATH.SETTLEMENT}/:id${ROUTE_PATH.PLOTS}`,
    },
    REALTY: {
      path: `${ROUTE_PATH.STREETS}/:id`,
    },
    SURVEYS: {
      path: ROUTE_PATH.SURVEYS,
    },
    SURVEYS_RESULT: {
      path: `${ROUTE_PATH.SURVEYS}/:id`,
    },
    NEWS: {
      path: ROUTE_PATH.NEWS,
    },
    LIKES: {
      path: `${routes.likes.root}/:id`,
    },
    NEWS_STATUSES: {
      path: ROUTE_PATH.NEWS_STATUSES,
    },
    CREATE_NEWS: {
      path: ROUTE_PATH.CREATE_NEWS,
    },
    EDIT_NEWS: {
      path: `${ROUTE_PATH.EDIT_NEWS}/:id`,
    },
    NEWS_EVENTS: {
      path: ROUTE_PATH.NEWS_EVENTS,
    },
    CREATE_EVENT: {
      path: ROUTE_PATH.CREATE_EVENT,
    },
    EDIT_EVENT: {
      path: `${ROUTE_PATH.EDIT_EVENT}/:id`,
    },
    NOTIFICATIONS: {
      path: ROUTE_PATH.NOTIFICATIONS,
    },
    CREATE_NOTIFICATIONS: {
      path: ROUTE_PATH.CREATE_NOTIFICATIONS,
    },
    EDIT_NOTIFICATIONS: {
      path: `${ROUTE_PATH.EDIT_NOTIFICATIONS}/:id`,
    },
    NEWS_LIKES: {
      path: `${ROUTE_PATH.NEWS_LIKES}/:id`,
    },
    DOCUMENTS: {
      path: ROUTE_PATH.DOCUMENTS,
    },
    FAQ: {
      path: ROUTE_PATH.FAQ,
    },
    VIDEOS: {
      path: routes.videos.root,
    },
    CAMERAS: {
      path: `${routes.videos.cameras}/:id`,
    },
    REPORTS: {
      path: ROUTE_PATH.REPORTS,
    },
    TEMPLATE_REPORTS: {
      path: ROUTE_PATH.TEMPLATE_REPORTS,
    },
    TECHNICAL_SUPPORT: {
      path: routes.technical_support.root,
    },
    TECHNICAL_SUPPORT_TEMPLATES: {
      path: routes.technical_support.template,
    },
    TECHNICAL_SUPPORT_INFO: {
      path: `${ROUTE_PATH.TECHNICAL_SUPPORT}/:id`,
    },
    SERVICE_SUPPORT: {
      path: ROUTE_PATH.SERVICE_SUPPORT,
    },
    SERVICE_SUPPORT_INFO: {
      path: `${ROUTE_PATH.SERVICE_SUPPORT}/:id`,
    },
    SERVICE_SUPPORT_TEMPLATES: {
      path: ROUTE_PATH.SERVICE_SUPPORT_TEMPLATES,
    },
    BUILDER_SUPPORT: {
      path: routes.builder_support.root,
    },
    BUILDER_SUPPORT_INFO: {
      path: `${ROUTE_PATH.BUILDER_SUPPORT}/:id`,
    },
    BUILDER_SUPPORT_TEMPLATES: {
      path: routes.builder_support.template,
    },
    COMPANIES: {
      path: ROUTE_PATH.COMPANIES,
    },
    STAFF: {
      path: `${ROUTE_PATH.COMPANIES}/:id`,
    },
    ACCESS_SYSTEM_SETTLEMENTS: {
      path: ROUTE_PATH.ACCESS_SYSTEM,
    },
    ACCESS_SYSTEM: {
      path: `${ROUTE_PATH.ACCESS_SYSTEM}/:id`,
    },
    MASTERS_LAYOUT: {
      path: ROUTE_PATH.MASTERS,
    },
    MASTERS_CATEGORIES: {
      path: ROUTE_PATH.MASTERS_CATEGORIES,
    },
    MASTERS_SUB_CATEGORIES: {
      path: ROUTE_PATH.MASTERS_SUB_CATEGORIES,
    },
    MASTERS_SECOND_SUB_CATEGORIES: {
      path: ROUTE_PATH.MASTERS_SECOND_SUB_CATEGORIES,
    },
    PARTNERS_LAYOUT: {
      path: ROUTE_PATH.PARTNERS,
    },
    PARTNERS_CATEGORIES: {
      path: ROUTE_PATH.PARTNERS_CATEGORIES,
    },
    PARTNERS_SUB_CATEGORIES: {
      path: ROUTE_PATH.PARTNERS_SUB_CATEGORIES,
    },
    PARTNERS_SECOND_SUB_CATEGORIES: {
      path: ROUTE_PATH.PARTNERS_SECOND_SUB_CATEGORIES,
    },
    PARTNERS_STATISTICS: {
      path: ROUTE_PATH.PARTNERS_STATISTICS,
    },
    PARTNERS_SCORE: {
      path: ROUTE_PATH.PARTNERS_SCORE,
    },
    SERVICES_LAYOUT: {
      path: ROUTE_PATH.SERVICES,
    },
    SERVICES_CATEGORIES: {
      path: ROUTE_PATH.SERVICES_CATEGORIES,
    },
    SERVICES_SUB_CATEGORIES: {
      path: ROUTE_PATH.SERVICES_SUB_CATEGORIES,
    },
    SERVICES_SECOND_SUB_CATEGORIES: {
      path: ROUTE_PATH.SERVICES_SECOND_SUB_CATEGORIES,
    },
    CONTACTS: {
      path: ROUTE_PATH.CONTACTS,
    },
    SPECIAL_CONTACTS: {
      path: ROUTE_PATH.SPECIAL_CONTACTS,
    },
    CARD_CONTACTS: {
      path: `${ROUTE_PATH.CONTACTS}/:id`,
    },
    ADVERTISING: {
      path: ROUTE_PATH.ADVERTISING,
    },
    ADVERTISING_PERSONAL: {
      path: ROUTE_PATH.ADVERTISING_PERSONAL,
    },
    VIDEO_CONTENT: {
      path: ROUTE_PATH.VIDEO_CONTENT,
    },
    INVITE_PARTNER: {
      path: ROUTE_PATH.INVITE_PARTNER,
    },
    INVITED_PARTNER_LIST: {
      path: ROUTE_PATH.INVITED_PARTNER_LIST,
    },
    INVITE_MASTERS: {
      path: ROUTE_PATH.INVITE_MASTER,
    },
    INVITED_MASTERS_LIST: {
      path: ROUTE_PATH.INVITED_MASTER_LIST,
    },
    INVITE_SERVICES: {
      path: ROUTE_PATH.INVITE_SERVICE,
    },
    INVITED_SERVICES_LIST: {
      path: ROUTE_PATH.INVITED_SERVICE_LIST,
    },
  },
  routesHandler
);

const Login = loadable(() => import('auth/login'));
const ForgotPassword = loadable(() => import('auth/forgotPassword'));
const ResetPassword = loadable(() => import('auth/resetPassword'));

const Main = loadable(() => import('pages/main'));
const Users = loadable(() => import('pages/users'));

const Surveys = loadable(() => import('pages/surveys/surveys'));
const SurveysResult = loadable(() => import('pages/surveys/result'));

const News = loadable(() => import('pages/news/news'));
const NewsStatuses = loadable(() => import('pages/news/statuses'));
const CreateNews = loadable(() => import('pages/news/news/createNews'));
const EventsPage = loadable(() => import('pages/news/events/index'));
const NewsLayout = loadable(() => import('pages/news/newsLayout'));
const CreateEvent = loadable(() => import('pages/news/events/createEvent/index'));
const Notifications = loadable(() => import('pages/news/notifications/index'));
const CreateNotification = loadable(() => import('pages/news/notifications/createNotification/index'));

const InformationLayout = loadable(() => import('pages/information/informationLayout'));
const Documents = loadable(() => import('pages/information/documents'));
const Faq = loadable(() => import('pages/information/faq'));

const TechnicalSupportInfo = loadable(() => import('pages/technicalSupport/list/technicalSupportInfo'));

const ServiceSupportInfo = loadable(() => import('pages/serviceSupport/list/serviceSupportInfo'));
const ServiceInvite = loadable(() => import('pages/services/servicesInvite'));
const InvitedServicesList = loadable(() => import('pages/services/invitedServicesList'));

const BuilderSupportInfo = loadable(() => import('pages/builderSupport/list/builderSupportInfo'));

const AccessSystemSettlements = loadable(() => import('pages/accessSystem/settlements'));
const AccessSystem = loadable(() => import('pages/accessSystem/accessSystem'));

const Companies = loadable(() => import('pages/companies/companies'));
const Staff = loadable(() => import('pages/companies/staff'));

const MastersLayout = loadable(() => import('pages/masters/mastersLayout'));
const Masters = loadable(() => import('pages/masters/masters'));
const MastersCategories = loadable(() => import('pages/masters/categories'));
const MastersSubCategories = loadable(() => import('pages/masters/subCategory'));
const MastersSecondSubCategories = loadable(() => import('pages/masters/secondSubCategory'));
const MasterInvite = loadable(() => import('pages/masters/mastersInvite'));
const InvitedMastersList = loadable(() => import('pages/masters/invitedMastersList'));

const PartnersLayout = loadable(() => import('pages/partners/partnersLayout'));
const Partners = loadable(() => import('pages/partners/partners'));
const PartnersCategories = loadable(() => import('pages/partners/categories'));
const PartnersSubCategories = loadable(() => import('pages/partners/subCategory'));
const PartnersSecondSubCategories = loadable(() => import('pages/partners/secondSubCategory'));
const PartnerStatistics = loadable(() => import('pages/partners/statistics'));
const PartnerScore = loadable(() => import('pages/partners/score'));
const PartnerInvite = loadable(() => import('pages/partners/partnersInvite'));
const InvitedPartnersList = loadable(() => import('pages/partners/invitedPartnersList'));

const ServicesLayout = loadable(() => import('pages/services/servicesLayout'));
const Services = loadable(() => import('pages/services/services'));
const ServicesCategories = loadable(() => import('pages/services/categories'));
const ServicesSubCategories = loadable(() => import('pages/services/subCategory'));
const ServicesSecondSubCategories = loadable(() => import('pages/services/secondSubCategory'));

const ContactsLayout = loadable(() => import('pages/contacts/contactsLayout'));
const Contacts = loadable(() => import('pages/contacts/contacts'));
const SpecialContacts = loadable(() => import('pages/contacts/specialContacts'));
const ContactsCard = loadable(() => import('pages/contacts/card_contacts'));

const AdvertisingLayout = loadable(() => import('pages/advertising/advertisingLayout'));
const CommonAdvertising = loadable(() => import('pages/advertising/common'));
const PersonalAdvertising = loadable(() => import('pages/advertising/personal'));
const VideoContent = loadable(() => import('pages/videoContent'));

export const router = createBrowserRouter([
  { element: <PartnerInvite />, path: ROUTES.INVITE_PARTNER.path },
  { element: <MasterInvite />, path: ROUTES.INVITE_MASTERS.path },
  { element: <ServiceInvite />, path: ROUTES.INVITE_SERVICES.path },
  {
    element: <AuthLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      { children: [{ element: <Login />, path: ROUTES.LOGIN.path }] },
      { children: [{ element: <ForgotPassword />, path: ROUTES.FORGOT_PASSWORD.path }] },
      { children: [{ element: <ResetPassword />, path: ROUTES.RESET_PASSWORD.path }] },
    ],
  },
  {
    element: <DashboardLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        children: [
          {
            element: <Main />,
            path: ROUTES.DASHBOARD.path,
          },
        ],
      },
      {
        children: [
          {
            element: <Users />,
            path: ROUTES.USERS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <SettlementsList />,
            path: ROUTES.SETTLEMENTS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateSettlementPage />,
            path: ROUTES.CREATE_SETTLEMENTS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <PlotsLayout />,
            path: ROUTES.SETTLEMENT.path,
            children: [
              {
                element: <PlotsList />,
                path: ROUTES.PLOTS.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <Surveys />,
            path: ROUTES.SURVEYS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <SurveysResult />,
            path: `${ROUTES.SURVEYS.path}/:id`,
          },
        ],
      },
      {
        children: [
          {
            element: <NewsLayout />,
            path: ROUTES.NEWS.path,
            children: [
              {
                element: <News />,
                path: ROUTES.NEWS.path,
              },
              {
                element: <NewsStatuses />,
                path: ROUTES.NEWS_STATUSES.path,
              },
              {
                element: <EventsPage />,
                path: ROUTES.NEWS_EVENTS.path,
              },
              {
                element: <Notifications />,
                path: ROUTES.NOTIFICATIONS.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <LikesList />,
            path: ROUTES.NEWS_LIKES.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateNews />,
            path: ROUTES.CREATE_NEWS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateNews />,
            path: ROUTES.EDIT_NEWS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateEvent />,
            path: ROUTES.CREATE_EVENT.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateEvent />,
            path: ROUTES.EDIT_EVENT.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateNews />,
            path: ROUTES.EDIT_EVENT.path,
          },
        ],
      },
      {
        children: [
          {
            element: <Users />,
            path: ROUTES.USERS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateNotification />,
            path: ROUTES.CREATE_NOTIFICATIONS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CreateNotification />,
            path: ROUTES.EDIT_NOTIFICATIONS.path,
          },
        ],
      },

      {
        children: [
          {
            element: <InformationLayout />,
            path: ROUTES.DOCUMENTS.path,
            children: [
              {
                element: <Documents />,
                path: ROUTES.DOCUMENTS.path,
              },
              {
                element: <Faq />,
                path: ROUTES.FAQ.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <VideosList />,
            path: ROUTES.VIDEOS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <CamerasList />,
            path: ROUTES.CAMERAS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <TechnicalSupportLayout />,
            path: routes.technical_support.root,
            children: [
              {
                element: <TechnicalSupportList />,
                path: routes.technical_support.root,
              },
              {
                element: <TechnicalSupportTemplates />,
                path: routes.technical_support.template,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <ServiceSupportLayout />,
            path: routes.service_support.root,
            children: [
              {
                element: <ServiceSupportList />,
                path: routes.service_support.root,
              },
              {
                element: <ServiceSupportTemplates />,
                path: routes.service_support.template,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <BuilderSupportLayout />,
            path: ROUTES.BUILDER_SUPPORT.path,
            children: [
              {
                element: <BuilderSupportList />,
                path: ROUTES.BUILDER_SUPPORT.path,
              },
              {
                element: <BuilderSupportTemplates />,
                path: ROUTES.BUILDER_SUPPORT_TEMPLATES.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <TechnicalSupportInfo />,
            path: ROUTES.TECHNICAL_SUPPORT_INFO.path,
          },
        ],
      },
      {
        children: [
          {
            element: <ServiceSupportInfo />,
            path: ROUTES.SERVICE_SUPPORT_INFO.path,
          },
        ],
      },
      {
        children: [
          {
            element: <BuilderSupportInfo />,
            path: ROUTES.BUILDER_SUPPORT_INFO.path,
          },
        ],
      },
      {
        children: [
          {
            element: <AccessSystemSettlements />,
            path: ROUTES.ACCESS_SYSTEM_SETTLEMENTS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <AccessSystem />,
            path: ROUTES.ACCESS_SYSTEM.path,
          },
        ],
      },
      {
        children: [
          {
            element: <ReportsLayout />,
            path: routes.reports.root,
            children: [
              {
                element: <ReportsList />,
                path: routes.reports.root,
              },
              {
                element: <ReportsTemplates />,
                path: routes.reports.template,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <Companies />,
            path: ROUTES.COMPANIES.path,
          },
        ],
      },
      {
        children: [
          {
            element: <Staff />,
            path: ROUTES.STAFF.path,
          },
        ],
      },
      {
        children: [
          {
            element: <MastersLayout />,
            path: ROUTES.MASTERS_LAYOUT.path,
            children: [
              {
                element: <Masters />,
                path: ROUTES.MASTERS_LAYOUT.path,
              },
              {
                element: <MastersCategories />,
                path: ROUTES.MASTERS_CATEGORIES.path,
              },
              {
                element: <MastersSubCategories />,
                path: ROUTES.MASTERS_SUB_CATEGORIES.path,
              },
              {
                element: <MastersSecondSubCategories />,
                path: ROUTES.MASTERS_SECOND_SUB_CATEGORIES.path,
              },
              {
                element: <InvitedMastersList />,
                path: ROUTES.INVITED_MASTERS_LIST.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <PartnersLayout />,
            path: ROUTES.PARTNERS_LAYOUT.path,
            children: [
              {
                element: <Partners />,
                path: ROUTES.PARTNERS_LAYOUT.path,
              },
              {
                element: <PartnersCategories />,
                path: ROUTES.PARTNERS_CATEGORIES.path,
              },
              {
                element: <PartnersSubCategories />,
                path: ROUTES.PARTNERS_SUB_CATEGORIES.path,
              },
              {
                element: <PartnersSecondSubCategories />,
                path: ROUTES.PARTNERS_SECOND_SUB_CATEGORIES.path,
              },
              {
                element: <PartnerStatistics />,
                path: ROUTES.PARTNERS_STATISTICS.path,
              },
              {
                element: <PartnerScore />,
                path: ROUTES.PARTNERS_SCORE.path,
              },
              {
                element: <InvitedPartnersList />,
                path: ROUTES.INVITED_PARTNER_LIST.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <ServicesLayout />,
            path: ROUTES.SERVICES_LAYOUT.path,
            children: [
              {
                element: <Services />,
                path: ROUTES.SERVICES_LAYOUT.path,
              },
              {
                element: <ServicesCategories />,
                path: ROUTES.SERVICES_CATEGORIES.path,
              },
              {
                element: <ServicesSubCategories />,
                path: ROUTES.SERVICES_SUB_CATEGORIES.path,
              },
              {
                element: <ServicesSecondSubCategories />,
                path: ROUTES.SERVICES_SECOND_SUB_CATEGORIES.path,
              },
              {
                element: <InvitedServicesList />,
                path: ROUTES.INVITED_SERVICES_LIST.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <ContactsLayout />,
            path: ROUTES.CONTACTS.path,
            children: [
              {
                element: <Contacts />,
                path: ROUTES.CONTACTS.path,
              },
              {
                element: <SpecialContacts />,
                path: ROUTES.SPECIAL_CONTACTS.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <ContactsCard />,
            path: ROUTES.CARD_CONTACTS.path,
          },
        ],
      },
      {
        children: [
          {
            element: <AdvertisingLayout />,
            path: ROUTES.ADVERTISING.path,
            children: [
              {
                element: <CommonAdvertising />,
                path: ROUTES.ADVERTISING.path,
              },
              {
                element: <PersonalAdvertising />,
                path: ROUTES.ADVERTISING_PERSONAL.path,
              },
            ],
          },
        ],
      },
      {
        children: [
          {
            element: <VideoContent />,
            path: ROUTES.VIDEO_CONTENT.path,
          },
        ],
      },
    ],
  },
  // {
  //     path: '*',
  //     element: <Navigate to={ROUTES.DASHBOARD.path}/>,
  // },
]);
