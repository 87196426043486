import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AvatarUpload, Button, Input, Modal, Textarea } from '7-shared/ui';
import {
  createTemplateReportsThunk,
  editTemplateReportsThunk,
  ITemplateReportsForm,
  reportsActions,
  selectReportsTemplatesModalState,
} from '6-entities/reports';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { notification } from '7-shared/lib/notification';
import { isEmpty } from '7-shared/lib/lodash';
import * as yup from 'yup';
import { validate } from '7-shared/constants';

export const TemplateReportsForm = () => {
  const dispatch = useAppDispatch();
  const { open, currentItem, isLoading } = useAppSelector(selectReportsTemplatesModalState);
  const schema = yup.object().shape({
    title: yup.string().required(validate.required).max(300, `${validate.max} 300`),
    description: yup
      .string()
      .required(validate.required)
      .required(validate.required)
      .max(10000, `${validate.max} 10 0000 `),
    preview: yup.string().required(validate.required),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<ITemplateReportsForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  //Заполнение значениеями по умолчанию, при создании отчета
  useEffect(() => {
    if (currentItem) {
      reset({
        title: currentItem.title,
        description: currentItem.description,
        preview: currentItem.preview,
      });
    }
  }, [currentItem]);

  const onSubmit = (data: ITemplateReportsForm) => {
    const newData = {
      title: data.title,
      description: data.description,
      preview: data.preview,
    };
    isEmpty(currentItem) ? createTemplateHandler(newData) : editTemplateHandler(newData);
    reset();
  };
  const onCloseModalHandler = () => {
    dispatch(reportsActions.setReportsModalTemplatesState({ open: false, currentItem: undefined }));
    reset();
  };
  const createTemplateHandler = (data: ITemplateReportsForm) => {
    dispatch(createTemplateReportsThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Создание запрещено',
        });
      });
  };
  const editTemplateHandler = (data: ITemplateReportsForm) => {
    dispatch(editTemplateReportsThunk({ id: `${currentItem?.id}`, data }))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Редактирование запрещено',
        });
      });
  };
  return (
    <Modal
      onClose={onCloseModalHandler}
      loading={isLoading}
      opened={open}
      size={'auto'}
      title={isEmpty(currentItem) ? 'Добавление шаблона' : 'Редактирование шаблона'}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-[8px] w-full items-center justify-center'}>
        <AvatarUpload
          disabled={true}
          defaultValue={isEmpty(currentItem) ? '' : currentItem?.preview}
          control={control}
          name={'preview'}
          placeholder={'Загрузить картинку-превью'}
          shape={'rect'}
          aspect={4 / 3}
          classNameImg={'!rounded-[5px] w-[240px] h-max'}
          accept={['.jpg', '.jpeg', '.png']}
        />
        <Input className={'w-full'} name={'title'} control={control} placeholder={'Название'} />
        <Textarea className={'w-full'} name={'description'} control={control} placeholder={'Описание'} />
        <Button disabled={!isValid} mt={30} size={'md'} type={'submit'} fullWidth className={'mt-[15px] w-full'}>
          Сохранить шаблон
        </Button>
      </form>
    </Modal>
  );
};
