import { ACCESS_SYSTEM_ITEM_CATEGORY, ISettlement } from 'interface';

/**
 * @description Получить названия КП.
 * @param {boolean} isAll Показано ли для всех КП.
 * @param {Array} settlements Массив с разрешенными КП.
 * @return {string} Возвращает массив, с именами КП
 */
export const getSettlementsNames = (isAll: boolean, settlements: ISettlement[]) => {
  return [isAll ? 'Все кп' : undefined, ...settlements?.map((i) => i.name)].filter((i) => i).join();
};

export const getAccessSystemItemCategoryName = (data: ACCESS_SYSTEM_ITEM_CATEGORY) => {
  switch (data) {
    case ACCESS_SYSTEM_ITEM_CATEGORY.DELIVERY:
      return 'Доставка';
    case ACCESS_SYSTEM_ITEM_CATEGORY.COURIER:
      return 'Курьер';
    case ACCESS_SYSTEM_ITEM_CATEGORY.GUEST:
      return 'Гость';
    case ACCESS_SYSTEM_ITEM_CATEGORY.MASTER:
      return 'Мастер';
    case ACCESS_SYSTEM_ITEM_CATEGORY.TAXI:
      return 'Такси';
    default:
      return '';
  }
};
