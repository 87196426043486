import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalState, ITablePagination, ITableState } from '7-shared/types';

import { IPlots, IPlotsListFilters } from './types';
import { IRealty, ISettlement, IStreets } from '../api/types';

interface IInitialState {
  settlement: ISettlement;
  table: ITableState<IPlots>;
  pagination: ITablePagination;
  streets: IStreets[];
  realty: IRealty[];
  filters: IPlotsListFilters;
  modal: IModalState<IPlots>;
}

const initialState: IInitialState = {
  settlement: {} as ISettlement,
  table: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 0,
    total_count: 0,
    page_size: 10,
  },
  streets: [],
  realty: [],
  filters: {
    search: undefined,
    street_id: undefined,
    realty_id: undefined,
    ordering: undefined,
  },
  modal: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
};

export const plotsSlice = createSlice({
  name: 'plots',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<IPlots>>>) => {
      state.table = { ...state.table, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setListFilters: (state, action: PayloadAction<Partial<IPlotsListFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setStreets: (state, action: PayloadAction<IStreets[]>) => {
      state.streets = action.payload;
    },
    setRealty: (state, action: PayloadAction<IRealty[]>) => {
      state.realty = action.payload;
    },
    setSettlement: (state, action: PayloadAction<ISettlement>) => {
      state.settlement = action.payload;
    },
    setPlotsModalState: (state, action: PayloadAction<Partial<IModalState<IPlots>>>) => {
      state.modal = { ...state.modal, ...action.payload };
    },
  },
});

export const selectPlotsListTableData = (state: RootState) => state.plots.table;
export const selectPlotsListPagination = (state: RootState) => state.plots.pagination;
export const selectPlotsListFilters = (state: RootState) => state.plots.filters;
export const selectStreets = (state: RootState) => state.plots.streets;
export const selectRealty = (state: RootState) => state.plots.realty;
export const selectSettlement = (state: RootState) => state.plots.settlement;
export const selectIsLoading = (state: RootState) => state.plots.table.isLoading;
export const selectPlotsModalState = (state: RootState) => state.plots.modal;

export const plotsActions = plotsSlice.actions;
