import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Input, Modal, Textarea } from '7-shared/ui';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { notification } from '7-shared/lib/notification';
import { isEmpty } from '7-shared/lib/lodash';
import * as yup from 'yup';
import {
  createTemplateCommentThunk,
  editTemplateCommentThunk,
  selectTemplateModalState,
  templatesActions,
  ITemplatesForm,
} from '6-entities/templates';

export const BuilderTemplateForm = () => {
  const dispatch = useAppDispatch();
  const { open, currentItem, isLoading } = useAppSelector(selectTemplateModalState);
  const schema = yup.object().shape({
    title: yup.string().required('Это поле обязательное').max(256, 'Максимальная длина символов 256'),
    text: yup.string().required('Это поле обязательное'),
  });
  const { control, handleSubmit, setError, reset } = useForm<ITemplatesForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  //Заполнение значениеями по умолчанию, при редактировании шаблона
  useEffect(() => {
    if (currentItem) {
      reset({
        title: currentItem.title,
        text: currentItem.text,
      });
    } else {
      reset({});
    }
  }, [currentItem, open]);

  const onSubmit = (data: ITemplatesForm) => {
    const newData: ITemplatesForm = {
      title: data.title,
      text: data.text,
      type: 'builder',
    };
    isEmpty(currentItem) ? createTemplateHandler(newData) : editTemplateHandler(newData);
  };
  const onCloseModalHandler = () => {
    dispatch(templatesActions.setTemplateModalState({ open: false, currentItem: undefined }));
    reset();
  };
  const createTemplateHandler = (data: ITemplatesForm) => {
    dispatch(createTemplateCommentThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Добавление запрещено',
        });
      });
  };
  const editTemplateHandler = (data: ITemplatesForm) => {
    dispatch(editTemplateCommentThunk({ id: currentItem?.id as number, data }))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Редактирование запрещено',
        });
      });
  };
  return (
    <Modal
      onClose={onCloseModalHandler}
      loading={isLoading}
      opened={open}
      size={'auto'}
      title={isEmpty(currentItem) ? 'Добавление шаблона' : 'Редактирование шаблона'}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'flex flex-col gap-[8px] w-[400px] items-center justify-center'}
      >
        <Input className={'w-full'} name={'title'} control={control} placeholder={'Заголовок'} />
        <Textarea
          minRows={8}
          maxRows={8}
          withAsterisk
          className={'w-full'}
          name={'text'}
          control={control}
          placeholder={'Сообщение'}
        />
        <Button mt={30} size={'md'} type={'submit'} fullWidth className={'mt-[15px] w-full'}>
          Сохранить
        </Button>
      </form>
    </Modal>
  );
};
