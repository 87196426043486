import React, { useEffect, useState } from 'react';

import { UseFormSetValue } from 'react-hook-form';
import { defaultValue } from '../../utils';
import { useAppSelector } from '../useAppSelector/useAppSelector';
import { getRealtyThunk, getStreetsListThunk, selectRealty, selectSettlements, selectStreets } from '../../api';
import { useAppDispatch } from '../useAppDispatch/useAppDispatch';

export interface IOption {
  value: string;
  label: string;
}

export const useFormAddress = (
  setValue: UseFormSetValue<any>,
  settlement_id: string | null | undefined,
  street_id: string | null | undefined,
  realty_id: string | null | undefined
) => {
  const dispatch = useAppDispatch();
  const [settlementId, setSettlementId] = useState<string | null>(settlement_id ?? null);
  const [streetId, setStreetId] = useState<string | null>(street_id ?? null);
  const [realtyId, setRealtyId] = useState<string | null>(realty_id ?? null);

  const [settlementsResult, setSettlementsResult] = useState<IOption[]>([]);
  const [streetsResult, setStreetsResult] = useState<IOption[]>([]);
  const [realtiesResult, setRealtiesResult] = useState<IOption[]>([]);

  const settlements = useAppSelector(selectSettlements);
  const streets = useAppSelector(selectStreets);
  const realty = useAppSelector(selectRealty);

  useEffect(() => {
    if (settlementId) {
      dispatch(getStreetsListThunk(settlementId));
    }
  }, [settlementId]);

  useEffect(() => {
    if (streetId) {
      dispatch(getRealtyThunk(streetId));
    }
  }, [streetId]);
  useEffect(() => {
    setSettlementsResult(
      defaultValue(
        settlements
          .map((settlement) => ({
            value: String(settlement.id),
            label: settlement.name,
          }))
          .sort((a, b) => a?.value.localeCompare(b?.value, undefined, { numeric: true })),
        []
      )
    );
  }, [settlements]);
  useEffect(() => {
    setStreetsResult(
      defaultValue(
        streets
          .map((street) => ({
            value: String(street.id),
            label: street.name,
          }))
          .sort((a, b) => a?.value.localeCompare(b?.value, undefined, { numeric: true })),
        []
      )
    );
  }, [streets]);
  useEffect(() => {
    setRealtiesResult(
      defaultValue(
        realty
          .map((realty) => ({
            value: String(realty.id),
            label: realty.name,
          }))
          .sort((a, b) => a?.value.localeCompare(b?.value, undefined, { numeric: true })),
        []
      )
    );
  }, [realty]);
  useEffect(() => {
    if (settlement_id && settlement_id !== settlementId) {
      setSettlementId(settlement_id);
      if (streetId) {
        setStreetId(null);
        setRealtyId(null);
        setValue('street_id', null);
        setValue('realty_id', null);
      }
    }
  }, [settlement_id]);
  useEffect(() => {
    if (street_id && street_id !== streetId) {
      setStreetId(street_id);
      if (realtyId) {
        setRealtyId(null);
        setValue('realty_id', null);
      }
    }
  }, [street_id]);
  useEffect(() => {
    if (realty_id && realty_id !== realtyId) {
      setRealtyId(realty_id);
    }
  }, [realty_id]);

  const onClear = () => {
    setSettlementId(null);
    setStreetId(null);
    setRealtyId(null);
    setStreetsResult([]);
    setRealtiesResult([]);
  };

  return {
    settlements: settlementsResult,
    streets: streetsResult,
    realties: realtiesResult,
    onClear,
  };
};
