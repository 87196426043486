import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITablePagination, ITableState } from '7-shared/types';

import { ITechnicalSupport, ITechnicalSupportListFilters } from './types';

interface IInitialState {
  table: ITableState<ITechnicalSupport>;
  pagination: ITablePagination;
  filters: ITechnicalSupportListFilters;
}

const initialState: IInitialState = {
  table: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 0,
    total_count: 0,
    page_size: 10,
  },
  filters: {
    settlement: undefined,
    id: undefined,
    account_id: undefined,
    name: undefined,
    role: undefined,
    phone: undefined,
    email: undefined,
    address_street: undefined,
    address_realty: undefined,
    plot: undefined,
    comment: undefined,
    dateOfCreation_start: undefined,
    dateOfCreation_end: undefined,
    dateOfClosed_start: undefined,
    dateOfClosed_end: undefined,
    main_time_max: undefined,
    main_time_min: undefined,
    manager: undefined,
    score: undefined,
    status: undefined,
  },
};

export const technicalSupportSlice = createSlice({
  name: 'technicalSupport',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<ITechnicalSupport>>>) => {
      state.table = { ...state.table, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setListFilters: (state, action: PayloadAction<ITechnicalSupportListFilters>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setInitialTechnicalSupportstate: () => initialState,
  },
});

export const selectTechnicalSupportListTableData = (state: RootState) => state.technicalSupport.table;
export const selectTechnicalSupportListPagination = (state: RootState) => state.technicalSupport.pagination;
export const selectTechnicalSupportListFilters = (state: RootState) => state.technicalSupport.filters;

export const technicalSupportActions = technicalSupportSlice.actions;
