import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAnswer,
  IEditBody,
  IPaginationResponse,
  IQueryParams,
  ISurvey,
  ISurveyForm,
  ISurveysVoicesResults,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const surveyAPI = createApi({
  reducerPath: 'surveyAPI',
  tagTypes: ['surveys'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getSurveys: build.query<IPaginationResponse<ISurvey[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/surveys/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['surveys'],
    }),
    getSurveyResult: build.query<ISurvey, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/surveys/${id}/`,
      }),
    }),
    getSurveysVoicesResults: build.query<IPaginationResponse<ISurveysVoicesResults[]>, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/surveys/responses/${id}/?page=1&page_size=1000`,
      }),
    }),
    createSurvey: build.mutation<ISurvey, ISurveyForm>({
      query: (data) => ({
        url: `cms/surveys/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['surveys'],
    }),
    editSurvey: build.mutation<ISurvey, IEditBody<ISurveyForm>>({
      query: (data) => ({
        url: `cms/surveys/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['surveys'],
    }),
    editSurveyAnswers: build.mutation<ISurvey, IEditBody<IAnswer>>({
      query: (data) => ({
        url: `cms/answers/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['surveys'],
    }),
    deleteSurvey: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/surveys/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['surveys'],
    }),
    completeSurvey: build.mutation<null, IEditBody<ISurveyForm>>({
      query: (data) => ({
        url: `cms/surveys/complete/${data.id}/`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: ['surveys'],
    }),
  }),
});

export const {
  useGetSurveysQuery,
  useGetSurveyResultQuery,
  useEditSurveyAnswersMutation,
  useCreateSurveyMutation,
  useEditSurveyMutation,
  useDeleteSurveyMutation,
  useCompleteSurveyMutation,
  useGetSurveysVoicesResultsQuery,
} = surveyAPI;
