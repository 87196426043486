import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, ITemplateReports, ITemplateReportsForm } from 'interface';
import { customFetchBase } from '../../fetchBase';

export const templatesReportsAPI = createApi({
  reducerPath: 'templatesReportsAPI',
  tagTypes: ['templates'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getTemplatesReports: build.query<IPaginationResponse<ITemplateReports[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/report-templates/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['templates'],
    }),
    createTemplateReports: build.mutation<ITemplateReports, ITemplateReportsForm>({
      query: (data) => ({
        url: `cms/report-templates/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['templates'],
    }),
    editTemplateReports: build.mutation<ITemplateReports, IEditBody<ITemplateReportsForm>>({
      query: (data) => ({
        url: `cms/report-templates/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['templates'],
    }),
    deleteTemplateReports: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/report-templates/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['templates'],
    }),
  }),
});

export const {
  useCreateTemplateReportsMutation,
  useDeleteTemplateReportsMutation,
  useGetTemplatesReportsQuery,
  useEditTemplateReportsMutation,
} = templatesReportsAPI;
