import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalState, ITablePagination, ITableState } from '7-shared/types';
import { ISettlement } from '../api/types';

interface IInitialState {
  table: ITableState<ISettlement>;
  pagination: ITablePagination;
  modal: IModalState<ISettlement>;
}

const initialState: IInitialState = {
  table: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 0,
    total_count: 0,
    page_size: 10,
  },
  modal: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
};

export const settlementsSlice = createSlice({
  name: 'settlements',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<ISettlement>>>) => {
      state.table = { ...state.table, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setSettlementsModalState: (state, action: PayloadAction<Partial<IModalState<ISettlement>>>) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setInitialSettlementsState: () => initialState,
  },
});

export const selectSettlementsListTableData = (state: RootState) => state.settlements.table;
export const selectSettlementsListPagination = (state: RootState) => state.settlements.pagination;
export const selectIsLoading = (state: RootState) => state.settlements.table.isLoading;
export const selectSettlementsModalState = (state: RootState) => state.settlements.modal;

export const settlementsActions = settlementsSlice.actions;
