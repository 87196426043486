import React, { useEffect } from 'react';
import { Button, Flex, Title } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { IconPlus } from '7-shared/assets';
import { getSettlementListThunk, selectSettlementsListPagination, settlementsActions } from '6-entities/settlement';
import { SettlementsPagination, SettlementsTable } from '5-features/settlements';
import { useNavigate } from 'react-router-dom';
import { routes } from '7-shared/lib/routes';

export const SettlementsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pagination } = useQueryParams();
  const tablePagination = useAppSelector(selectSettlementsListPagination);

  const onPressAddHandler = () => {
    navigate(routes.createSettlement.root);
  };

  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getSettlementListThunk());
    }
  }, [tablePagination?.page]);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(settlementsActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  useEffect(() => {
    return () => {
      if (routes.createSettlement.root) {
        return;
      }
      dispatch(settlementsActions.setInitialSettlementsState());
    };
  }, []);

  return (
    <Flex direction={'column'} className={'w-full h-full'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
          Коттеджные поселки
        </Title>
        <Button ml={'auto'} variant={'outline'} onClick={onPressAddHandler} rightIcon={<IconPlus size={14} />}>
          Добавить поселок
        </Button>
      </Flex>
      <SettlementsTable />
      <SettlementsPagination />
    </Flex>
  );
};
