import { createAsyncThunk } from '@reduxjs/toolkit';
import { plotsApi } from '../api/plots.api';
import { plotsActions } from './plots.slice';
import { mapPlotsList } from '../lib/mapPlotsList';
import { IStreetForm } from '../api/types';
import { ICommonErrorReject, IRejectRequest } from '7-shared/api/types';
import { IPlotForm } from './types';
import { mapStreetsList } from '../lib/mapStreetsList';
import { mapRealtyList } from '../lib/mapRealtyList';

export const getPlotsListThunk = createAsyncThunk<void, string, { state: RootState }>(
  'plots/getPlotsList',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(plotsActions.setListTableData({ isLoading: true }));
      const filters = getState().plots.filters;
      const pagination = getState().plots.pagination;
      const result = await plotsApi.getPlotsList(id, pagination.page, pagination.page_size, filters);
      const list = result.results.map((i) => mapPlotsList(i));
      dispatch(getSettlementByIdThunk(id));
      dispatch(plotsActions.setListTableData({ data: list }));
      dispatch(plotsActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(plotsActions.setListTableData({ isLoading: false }));
    }
  }
);
export const getRealtyThunk = createAsyncThunk<void, string, { state: RootState }>(
  'plots/getRealty',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(plotsActions.setListTableData({ isLoading: true }));
      const result = await plotsApi.getRealtyList(id);

      const list = result.results.map((i) => mapRealtyList(i));

      dispatch(plotsActions.setRealty(list));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(plotsActions.setListTableData({ isLoading: false }));
    }
  }
);
export const editPlotThunk = createAsyncThunk<void, { id: number; data: IPlotForm }, { state: RootState }>(
  'plots/editPlot',
  async ({ id, data }, { dispatch, getState, rejectWithValue }) => {
    const settlementID = getState().plots.settlement.id;
    try {
      dispatch(plotsActions.setPlotsModalState({ isLoading: true }));
      await plotsApi.editPlot(id, data);
      dispatch(plotsActions.setPlotsModalState({ open: false }));
      dispatch(getPlotsListThunk(`${settlementID}`));
      dispatch(getStreetsListThunk(`${settlementID}`));
    } catch (error) {
      return rejectWithValue((error as IRejectRequest<IPlotForm>)?.response?.data);
    } finally {
      dispatch(plotsActions.setPlotsModalState({ isLoading: false }));
    }
  }
);
export const deletePlotThunk = createAsyncThunk<void, string, { state: RootState }>(
  'plots/deletePlot',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const settlementID = getState().plots.settlement.id;
    try {
      dispatch(plotsActions.setPlotsModalState({ isLoading: true }));
      await plotsApi.deletePlot(id);
      dispatch(getPlotsListThunk(`${settlementID}`));
      dispatch(getStreetsListThunk(`${settlementID}`));
      dispatch(plotsActions.setPlotsModalState({ open: false }));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(plotsActions.setPlotsModalState({ isLoading: false }));
    }
  }
);
export const createPlotThunk = createAsyncThunk<void, IPlotForm, { state: RootState }>(
  'plots/createPlot',
  async (data, { dispatch, getState, rejectWithValue }) => {
    const settlementID = getState().plots.settlement.id;
    try {
      dispatch(plotsActions.setPlotsModalState({ isLoading: true }));
      await plotsApi.createPlot(data);
      dispatch(plotsActions.setPlotsModalState({ open: false }));
      dispatch(getPlotsListThunk(`${settlementID}`));
      dispatch(getStreetsListThunk(`${settlementID}`));
    } catch (error) {
      return rejectWithValue((error as IRejectRequest<IPlotForm>)?.response?.data);
    } finally {
      dispatch(plotsActions.setPlotsModalState({ isLoading: false }));
    }
  }
);
export const getStreetsListThunk = createAsyncThunk<void, string, { state: RootState }>(
  'plots/getStreetsList',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(plotsActions.setListTableData({ isLoading: true }));
      const resultStreet = await plotsApi.getStreetList(id);
      const listStreet = resultStreet.results.map((i) => mapStreetsList(i));
      dispatch(plotsActions.setStreets(listStreet));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(plotsActions.setListTableData({ isLoading: false }));
      dispatch(plotsActions.setPlotsModalState({ isLoading: false }));
    }
  }
);
export const createStreetThunk = createAsyncThunk<void, IStreetForm, { state: RootState }>(
  'plots/createStreet',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(plotsActions.setPlotsModalState({ isLoading: true }));
      await plotsApi.createStreet(data);
      dispatch(getStreetsListThunk(`${data.settlement_id}`));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data);
    } finally {
      dispatch(plotsActions.setPlotsModalState({ isLoading: false }));
    }
  }
);
export const editStreetThunk = createAsyncThunk<void, { street_id: string; data: IStreetForm }, { state: RootState }>(
  'plots/editStreet',
  async ({ street_id, data }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(plotsActions.setPlotsModalState({ isLoading: true }));
      await plotsApi.editStreet(street_id, data);
      dispatch(getStreetsListThunk(`${data.settlement_id}`));
    } catch (error) {
      return rejectWithValue((error as IRejectRequest<IPlotForm>)?.response?.data);
    } finally {
      dispatch(plotsActions.setPlotsModalState({ isLoading: false }));
    }
  }
);
export const deleteStreetThunk = createAsyncThunk<void, string, { state: RootState }>(
  'plots/editStreet',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const settlementID = getState().plots.settlement.id;
    try {
      dispatch(plotsActions.setPlotsModalState({ isLoading: true }));
      await plotsApi.deleteStreet(id);
      dispatch(getStreetsListThunk(`${settlementID}`));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(plotsActions.setPlotsModalState({ isLoading: false }));
    }
  }
);
export const getSettlementByIdThunk = createAsyncThunk<void, string, { state: RootState }>(
  'plots/getSettlementById',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const result = await plotsApi.getSettlementById(id);
      dispatch(plotsActions.setSettlement(result));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    }
  }
);
