import * as yup from 'yup';
import { regex, validate } from '7-shared/constants';
import { REPORT_TYPE } from '6-entities/reports';

export const reportSchema = yup.object().shape({
  title: yup.string().required(validate.required).max(300, `${validate.max} 300`),
  description: yup.string().required(validate.required).required(validate.required).max(10000, `${validate.max} 10000`),
  preview: yup.string().required(validate.required),
  link: yup.string().required(validate.required).matches(regex.is_link, validate.link).max(200, `${validate.max} 200`),
  settlement_id: yup.string().required(validate.required),
  street_id: yup.string().when('type', {
    is: (type: string) => type === REPORT_TYPE.personal,
    then: yup.string().required(validate.required),
    otherwise: yup.string().nullable(),
  }),
  realty_id: yup.string().when('type', {
    is: (type: string) => type === REPORT_TYPE.personal,
    then: yup.string().required(validate.required),
    otherwise: yup.string().nullable(),
  }),
});
