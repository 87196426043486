import * as yup from 'yup';
import { regex, validate } from 'shared/constants';

export const templatesSchema = yup.object().shape({
  link: yup.string().required(validate.required).matches(regex.is_link, validate.link).max(200, `${validate.max} 200`),
  settlement_id: yup.string().required(validate.required),
  street_id: yup.string().required(validate.required),
  realty_id: yup.string().required(validate.required),
  report_template_id: yup.string().required(validate.required),
});
