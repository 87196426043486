import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';
import { useAuthMeMutation } from 'store/auth';
import { getIsAuth, getIsInit, setIsInit } from 'store/app';
import { router } from '../router';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { getConfigThunk } from '6-entities/app';

export const App = () => {
  const dispatch = useAppDispatch();
  const isInit = useAppSelector(getIsInit);
  const isAuth = useAppSelector(getIsAuth);
  const [authMe, { isLoading: authLoading }] = useAuthMeMutation();

  useEffect(() => {
    initializeApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }
    dispatch(getConfigThunk());
  }, [isAuth]);

  const initializeApp = async () => {
    await authMe();
    dispatch(setIsInit(true));
  };

  if (!isInit || authLoading) {
    return (
      <Center w={'100%'} h={'100vh'}>
        <Loader size={50} />
      </Center>
    );
  }
  return <RouterProvider router={router} />;
};
