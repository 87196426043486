import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { format, parse } from 'date-fns';
import { IconCalendar, IconX } from '../../../assets';
import { DatePickerInput, MantineSelect, MantineTextInput, SelectItem } from '../../mantine';

export interface IFilterSchema<T> {
  name: keyof T;
  type: 'input' | 'select' | 'date';
  options?: (string | SelectItem)[];
  placeholder: string;
  className?: string;
  value?: string;
  onChange: (fieldName: keyof T, value: string) => void;
  onClear: (fieldName: keyof T) => void;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  itemComponent?: ForwardRefExoticComponent<any & RefAttributes<HTMLDivElement>>;
}

interface IProps<T extends string> {
  //TODO разобраться с типом
  schema: any[];
}

export const TableFilters = <T extends string>({ schema }: IProps<T>) => {
  const renderFilterByType = (i: IFilterSchema<T>) => {
    switch (i.type) {
      case 'input':
        return (
          <MantineTextInput
            value={i.value ?? ''}
            onChange={(e) => i.onChange(i.name, e.target.value)}
            className={'w-full'}
            size={'md'}
            rightSection={
              i.value ? (
                <IconX size={14} cursor={'pointer'} className={'cursor-pointer'} onClick={() => i.onClear(i.name)} />
              ) : null
            }
            placeholder={i.placeholder}
          />
        );
      case 'select':
        return (
          <MantineSelect
            value={i.value ?? ''}
            onChange={(value) => i.onChange(i.name, value ?? '')}
            size={'md'}
            rightSection={i.value ? <IconX cursor={'pointer'} size="1rem" onClick={() => i.onClear(i.name)} /> : null}
            data={i?.options ?? []}
            clearable
            disabled={i.disabled}
            searchable
            nothingFound="Ничего не найдено"
            placeholder={i.placeholder}
            icon={i.icon}
            itemComponent={i.itemComponent}
          />
        );
      case 'date':
        return (
          <DatePickerInput
            type="default"
            locale="ru"
            onChange={(date) => (date ? i.onChange(i.name, format(date, 'yyyy-MM-dd')) : i.onClear(i.name))}
            // @ts-ignore
            placeholder={i.placeholder}
            valueFormat={'DD-MM-YYYY'}
            value={i?.value ? parse(i.value, 'yyyy-MM-dd', new Date()) : null}
            icon={<IconCalendar size={16} />}
            rightSection={i.value && <IconX cursor={'pointer'} size={16} onClick={() => i.onClear(i.name)} />}
            minDate={i.minDate ? parse(i.minDate, 'yyyy-MM-dd', new Date()) : undefined}
            maxDate={i.maxDate ? parse(i.maxDate, 'yyyy-MM-dd', new Date()) : undefined}
            firstDayOfWeek={1}
            size={'md'}
          />
        );
    }
  };
  return (
    <div className={'w-full flex gap-2 items-center'}>
      {schema.map((i, index) => (
        <div key={index} className={`${i?.className}`}>
          {renderFilterByType(i)}
        </div>
      ))}
    </div>
  );
};
