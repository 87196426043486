export const validate = {
  required: 'Это поле обязательное',
  email: 'Это не email',
  numbers: 'Должен содержать только цифры',
  min: 'Минимальная длина символов',
  max: 'Максимальная длина символов',
  min_count: 'Минимальное количество',
  required_min_count: 'Нужно выбрать как минимум',
  link: 'Это не ссылка',
  valid_link: 'Это не валидная ссылка',
  invalid_phone: 'Введен некорректный номер телефона',
};

export const errors = {
  error: 'Ошибка',
  access: 'У вас нет доступа к этой странице',
};

export const form_fields = {
  accept: { name: '', title: 'Доступно для:' },
  city: { name: 'city', title: 'Город' },
  address: { name: 'address', title: 'Адрес' },
  email: { name: 'email', title: 'Email' },
  email_auth: { name: 'email', title: 'Ваш Email' },
  phone: { name: 'phone', title: 'Номер телефона' },
  name: { name: 'name', title: 'Наименование' },
  title: { name: 'name', title: 'Название' },
  title_as_title: { name: 'title', title: 'Название' },
  first_name: { name: 'first_name', title: 'Имя' },
  last_name: { name: 'last_name', title: 'Фамилия' },
  middle_name: { name: 'middle_name', title: 'Отчество' },
  role: { name: 'role', title: 'Должность' },
  permissions: { name: 'permissions', title: 'Предоставить доступ' },
  save_button: { name: 'save', title: 'Сохранить' },
  delete_button: { name: 'delete', title: 'Удалить' },
  cancel_button: { name: 'cancel', title: 'Отмена' },
  upload_button: { name: 'upload', title: 'Загрузить' },
  detail: { name: 'detail', title: '' },
  comment: { name: 'comment', title: 'Комментарий' },
  settlement_id: { name: 'settlement_id', title: 'КП' },
  settlement: { name: 'settlement', title: 'КП' },
  street_id: { name: 'street_id', title: 'Улица' },
  realty_id: { name: 'realty_id', title: '№ дома' },
  is_test: { name: 'is_test', title: 'Тестовый пользователь' },
  number: { name: 'number', title: 'Номер дома' },
  numberPlot: { name: 'number', title: 'Номер земельного участка' },
  account: { name: 'account', title: 'Лицевой счёт' },
  corpus: { name: 'corpus', title: 'Корпус' },
  status: { name: 'status', title: 'Статус' },
  title_as_header: { name: 'title', title: 'Заголовок' },
  text: { name: 'text', title: 'Основной текст' },
  settlements: { name: 'settlements', title: '' },
  show_all: { name: 'show_all', title: 'Все КП' },
  code: { name: 'code', title: 'Задайте пароль' },
  description: { name: 'description', title: 'Описание' },
  short_description: { name: 'short_description', title: 'Краткое описание' },
  full_description: { name: 'full_description', title: 'Полное описание' },
  user_id: { name: 'user_id', title: 'Клиент' },
  images: { name: 'images', title: '' },
  file: { name: 'file', title: 'Загрузить файл' },
  answers: { name: 'answers', title: 'Вариант выбора №' },
  expire_date: { name: 'expire_date', title: 'Дата окончания' },
  start_date: { name: 'start_date', title: 'Дата начала' },
  login: { name: 'login', title: 'Логин' },
  password: { name: 'password', title: 'Пароль' },
  ip: { name: 'ip', title: 'Ip' },
  endpoint: { name: 'endpoint', title: 'Эндпоинт' },
  surname: { name: 'surname', title: 'Фамилия' },
  avatar: { name: 'avatar', title: 'Загрузить фото' },
  password_auth: { name: 'password', title: 'Введите пароль' },
  new_password: { name: 'new_password', title: 'Введите новый пароль' },
  cover: { name: 'cover', title: 'Загрузить фото' },
  play: { name: 'play', title: 'Старт' },
  pause: { name: 'pause', title: 'Пауза' },
  full_name: { name: 'full_name', title: 'ФИО' },
  position: { name: 'position', title: 'Должность' },
  image: { name: 'image', title: 'Загрузить фото профиля' },
  reclames_image: { name: 'image', title: 'Загрузить обложку для рекламы' },
  name_org: { name: 'name', title: 'Наименование организации' },
  link: { name: 'link', title: 'Ссылка перехода' },
  edit_image: { name: 'edit_image', title: 'Редактировать изображение' },
  no_value: { name: '', title: 'Нет значений' },
  change_icon: { name: '', title: 'Изменить иконку' },
  add_icon: { name: '', title: 'Добавить иконку' },
  color: { name: 'color', title: 'Выбор цвета' },
  reports_preview: { name: 'preview', title: 'Загрузить картинку-превью' },
  partners_preview: { name: 'preview', title: 'Загрузить превью партнера' },
  report_type: { name: 'type', title: 'Тип отчета' },
  template: { name: 'report_template_id', title: 'Шаблон' },
  id: { name: 'id', title: 'ID' },
  account_id: { name: 'account_id', title: 'ID' },
};
export const components = {
  pagination: {
    back: 'Назад',
    forward: 'Вперед',
  },
};

export const pages = {
  users: {
    title: 'Все пользователи',
    add_button: 'Добавить пользователя',
    import_button: 'Импортировать пользователей',
    delete_text: 'Вы уверены, что хотите \n' + 'удалить пользователя?',
    modal: {
      create_title: 'Регистрация нового пользователя',
      edit_title: 'Редактирование пользователя',
      role: 'выберите статус:',
      create_button: 'Зарегистрировать',
      edit_button: 'Сохранить изменения',
    },
    success_message: 'Пользователь успешно зарегистрирован',
    modal_import: {
      title: 'Ошибки загрузки даннных',
      upload_table: ' Выгрузить таблицу с ошибками в Exсel',
      ok: 'OK',
    },
  },
  settlements: {
    title: 'Коттеджные поселки',
    add_button: 'Добавить поселок',
    delete_text: 'Вы уверены, что хотите \n' + ' удалить поселок?',
    create_age: {
      create_title: 'Создание коттеджного поселка',
      edit_title: 'Редактирование поселка',
      create_button: 'Создать поселок',
      edit_button: 'Сохранить изменения',
    },
  },
  streets: {
    title: 'Улицы',
    add_button: 'Добавить улицу',
    delete_text: 'Вы уверены, что хотите \n' + ' удалить улицу?',
    modal: {
      create_title: 'Добавление улицы',
      edit_title: 'Редактирование улицы',
      create_button: 'Создать улицу',
      edit_button: 'Сохранить изменения',
    },
  },
  plot: {
    title: 'ЗУ',
    add_button: 'Добавить ЗУ',
    delete_text: 'Вы уверены, что хотите \n' + ' земельный участок?',
    modal: {
      create_title: 'Добавление ЗУ',
      edit_title: 'Редактирование ЗУ',
      create_button: 'Создать',
      edit_button: 'Сохранить',
    },
  },
  realty: {
    title: 'Дома',
    add_button: 'Добавить дом',
    delete_text: 'Вы уверены, что хотите \n' + ' удалить дом?',
    modal: {
      create_title: 'Добавление дома',
      edit_title: 'Редактирование дома',
      create_button: 'Создать',
    },
  },
  reports: {
    reports: {
      title: 'Отчеты',
      add_button: 'Добавить отчет',
      delete_text: 'Вы уверены, что хотите \n' + ' удалить отчет?',
      modal: {
        create_title: 'Добавление отчета',
        edit_title: 'Редактирование отчета',
        create_button: 'Сохранить отчет',
      },
    },
    templates: {
      title: 'Шаблоны',
      add_button: 'Добавить шаблон',
      delete_text: 'Вы уверены, что хотите \n' + ' удалить шаблон?',
      modal: {
        create_title: 'Добавление шаблона',
        edit_title: 'Редактирование шаблона',
        create_button: 'Сохранить шаблон',
      },
    },
    modal_import: {
      title: 'Автоматическое добавление отчетов',
      save_button: 'Сохранить',
    },
  },
  sub_reports: {
    add_button: 'Создать отчет',
    delete_text: 'Вы уверены, что хотите \n' + ' удалить отчет?',
    modal: {
      create_title: 'Создание отчета',
      edit_title: 'Редактирование отчета',
      add_photo: 'Загрузить фото',
      create_button: 'Отправить клиенту',
      edit_button: 'Сохранить изменения',
    },
    send_confirm_modal: {
      title: 'Вы уверены, что хотите \n' + 'отправить отчет?',
      send: 'Отправить',
      cancel: 'Отмена',
    },
  },
  videos: {
    title: 'Камеры',
    add_button: 'Добавить кп ',
    delete_text: 'Вы уверены, что хотите \n' + 'удалить кп?',
    modal: {
      create_title: 'Добавление кп',
      edit_title: 'Редактирование кп',
      create_button: 'Сохранить кп',
      edit_button: 'Сохранить изменения',
    },
  },
  cameras: {
    add_button: 'Добавить камеру ',
    delete_text: 'Вы уверены, что хотите \n' + 'удалить камеру?',
    count_cameras: (count: number) => {
      if (count == 1) return `${count} камера`;
      else if (count > 1 && count < 5) return `${count} камеры`;
      return `${count} камер`;
    },
    modal: {
      create_title: 'Добавление камеры ',
      create_button: 'Сохранить камеру',
      edit_button: 'Сохранить изменения',
    },
  },
};
export const notification_text = {
  title_error: 'Ошибка',
  title_success: 'Успешно',
  import_users: {
    text_error: 'Что-то пошло не так',
    text_success: 'Пользователи успешно импортированы',
  },
  import_reports: {
    text_error: 'Что-то пошло не так',
    text_success: 'Отчеты успешно импортированы',
  },
  import_invite: {
    text_error: 'Пользователь не имеет электронной почты',
    text_success: 'Приглашение было отправлено по электронной почте',
  },
};
export const news_statuses_colors = [
  { id: 1, background: 'rgba(211, 100, 20, 0.07)', textColor: 'rgba(224,108,25,1)', title: 'Оранжевый' },
  { id: 2, background: 'rgba(1, 145, 84, 0.07)', textColor: 'rgba(1,145,84,1)', title: 'Зеленый' },
  { id: 5, background: 'rgba(1,145,84,1)', textColor: 'rgba(255,255,255,1)', title: 'Темно-зеленый' },
  { id: 3, background: 'rgba(226, 63, 82, 0.07)', textColor: 'rgba(226,63,82,1)', title: 'Красный' },
  { id: 4, background: 'rgba(38, 88, 185, 0.07)', textColor: 'rgba(38,88,185,1)', title: 'Синий' },
  { id: 6, background: 'rgba(212, 166, 0, 0.07)', textColor: 'rgba(187,146,0,1)', title: 'Желтый' },
  { id: 7, background: 'rgba(158, 105, 75, 0.07)', textColor: 'rgba(144,90,60,1)', title: 'Коричневый' },
  { id: 8, background: 'rgba(104, 20, 211, 0.07)', textColor: 'rgba(104,20,211,1)', title: 'Фиолетовый' },
  { id: 9, background: 'rgba(192, 33, 206, 0.07)', textColor: 'rgba(192,33,206,1)', title: 'Лиловый' },
  { id: 10, background: 'rgba(20, 211, 154, 0.07)', textColor: 'rgba(0,183,128,1)', title: 'Мятный' },
];
export const regex = {
  is_link:
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
};

export enum AUTH_TOKEN {
  access = 'jwt-auth',
  refresh = 'jwt-refresh',
}
