import React from 'react';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';
import { columns } from '../../model/likesListColumns';
import { selectNewsListTableData } from '6-entities/news';

export const LikesTable = () => {
  const tableData = useAppSelector(selectNewsListTableData);

  return (
    <Table
      noHeader={true}
      columns={columns}
      rows={tableData.data}
      tableLoading={tableData.isLoading}
      wholeScreen={true}
    />
  );
};
