import { IconCategory, IconUsers } from '7-shared/assets';
import { routes } from '7-shared/lib/routes';

export const tabs = {
  reports: {
    value: 'reports',
    label: 'Ход строительства',
    link: routes.reports.root,
    icon: <IconUsers size={18} />,
  },
  templates: {
    value: 'templates',
    label: 'Шаблоны',
    link: routes.reports.template,
    icon: <IconCategory size={18} />,
  },
};
