import React from 'react';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';
import { selectConfigBuilderSupport, selectConfigIsLoading } from '6-entities/app';
import { filterColumnsByAccessor } from '7-shared/utils';
import { columns } from '../../model/builderSupportListColumns';
import { selectIsLoader } from '7-shared/api';
import { selectBuilderSupportListTableData } from '6-entities/builderSupport';

export const BuilderSupportTable = () => {
  const tableData = useAppSelector(selectBuilderSupportListTableData);
  const tableSettings = useAppSelector(selectConfigBuilderSupport);
  const configIsLoading = useAppSelector(selectConfigIsLoading);
  const adresesIsLoading = useAppSelector(selectIsLoader);

  return (
    <Table
      columns={filterColumnsByAccessor(tableSettings, columns)}
      rows={tableData.data}
      tableLoading={tableData.isLoading || configIsLoading || adresesIsLoading}
      wholeScreen={false}
    />
  );
};
