import { forwardRef } from 'react';
import { Rating } from '@mantine/core';

interface ISelectUserItem {
  value: string;
  label: string;
  onChange: (value: string, label: string) => void;
  onPressDelete?: (value: string) => void;
}

export const SelectRatingItem = forwardRef<HTMLDivElement, any>(
  ({ value, label, onChange, onPressDelete, ...others }: ISelectUserItem, ref) => {
    return (
      <div ref={ref} {...others}>
        <Rating value={+label} readOnly />
      </div>
    );
  }
);
