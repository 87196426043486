import { IVideo } from '../api/types';

export function mapVideosList(dto: IVideo): IVideo {
  return {
    id: dto.id,
    settlement: dto.settlement,
    cameras: dto.cameras,
    cameras_number: dto.cameras_number,
    time_created: dto.time_created,
    time_updated: dto.time_updated,
  };
}
