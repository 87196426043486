import { combineReducers } from '@reduxjs/toolkit';

import { technicalSupportSlice } from '6-entities/technicalSupport';
import { builderSupportSlice } from '6-entities/builderSupport';
import { appNewSlice } from '6-entities/app';
import { serviceSupportSlice } from '6-entities/serviceSupport/';
import { usersSlice } from '6-entities/user/';
import { adresesSlice } from '7-shared/api/adreses/';
import { plotsSlice } from '6-entities/plots';
import { reportsSlice } from '6-entities/reports';
import { videosSlice } from '6-entities/videos';
import { newsSlice } from '6-entities/news';
import { templatesSlice } from '6-entities/templates';
import { settlementsSlice } from '6-entities/settlement';

import { authAPI } from '../../store/auth';
import { usersAPI } from '../../store/users';
import { companyAPI, staffAPI } from '../../store/company';
import { realtyAPI, settlementsAPI, streetsAPI } from '../../store/settlements';
import { plotsAPI } from '../../store/settlements/plots.api';
import { technicalSupportApi, technicalSupportTemplatesApi } from '../../store/technicalSupport';
import { documentsAPI, faqAPI } from '../../store/information';
import { eventAPI, newsAPI, notificationsAPI } from '../../store/news';
import { reportsAPI, templatesReportsAPI } from '../../store/reports';
import { surveyAPI } from '../../store/survey';
import { mastersAPI, mastersSectionsAPI, publicMastersApi } from '../../store/masters';
import { videosAPI } from '../../store/videos';
import { partnersAPI, partnerSectionsAPI, publicPartnersAPI } from '../../store/partners';
import { cardContactAPI, contactsAPI, specialContactsAPI } from '../../store/contacts';
import { advertisingAPI } from '../../store/advertising';
import { statusesAPI } from '../../store/news/statuses';
import { videoContentApi } from '../../store/videoContent';
import { publicServicesAPI, servicesAPI, servicesSectionsAPI } from '../../store/services';
import { serviceSupportApi } from '../../store/serviceSupport';
import { accessSystemApi } from '../../store/accessSystem';
import { builderSupportApi } from '../../store/builderSupport';
import { chatApi } from '../../store/chat';
import { fileApi } from '../../store/file';
import { personalAdvertisingAPI } from '../../store/personalAdvertising';
import appSlice from 'store/app/app.slice';

export const rootReducer = combineReducers({
  [technicalSupportSlice.name]: technicalSupportSlice.reducer,
  [builderSupportSlice.name]: builderSupportSlice.reducer,
  [serviceSupportSlice.name]: serviceSupportSlice.reducer,
  [plotsSlice.name]: plotsSlice.reducer,
  [appNewSlice.name]: appNewSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [adresesSlice.name]: adresesSlice.reducer,
  [reportsSlice.name]: reportsSlice.reducer,
  [settlementsSlice.name]: settlementsSlice.reducer,
  [videosSlice.name]: videosSlice.reducer,
  [newsSlice.name]: newsSlice.reducer,
  [templatesSlice.name]: templatesSlice.reducer,

  app: appSlice,
  [authAPI.reducerPath]: authAPI.reducer,
  [usersAPI.reducerPath]: usersAPI.reducer,
  [companyAPI.reducerPath]: companyAPI.reducer,
  [staffAPI.reducerPath]: staffAPI.reducer,
  [settlementsAPI.reducerPath]: settlementsAPI.reducer,
  [streetsAPI.reducerPath]: streetsAPI.reducer,
  [plotsAPI.reducerPath]: plotsAPI.reducer,
  [realtyAPI.reducerPath]: realtyAPI.reducer,
  [technicalSupportApi.reducerPath]: technicalSupportApi.reducer,
  [documentsAPI.reducerPath]: documentsAPI.reducer,
  [newsAPI.reducerPath]: newsAPI.reducer,
  [faqAPI.reducerPath]: faqAPI.reducer,
  [reportsAPI.reducerPath]: reportsAPI.reducer,
  [surveyAPI.reducerPath]: surveyAPI.reducer,
  [mastersAPI.reducerPath]: mastersAPI.reducer,
  [videosAPI.reducerPath]: videosAPI.reducer,
  [mastersSectionsAPI.reducerPath]: mastersSectionsAPI.reducer,
  [partnersAPI.reducerPath]: partnersAPI.reducer,
  [partnerSectionsAPI.reducerPath]: partnerSectionsAPI.reducer,
  [contactsAPI.reducerPath]: contactsAPI.reducer,
  [cardContactAPI.reducerPath]: cardContactAPI.reducer,
  [advertisingAPI.reducerPath]: advertisingAPI.reducer,
  [statusesAPI.reducerPath]: statusesAPI.reducer,
  [videoContentApi.reducerPath]: videoContentApi.reducer,
  [templatesReportsAPI.reducerPath]: templatesReportsAPI.reducer,
  [servicesAPI.reducerPath]: servicesAPI.reducer,
  [servicesSectionsAPI.reducerPath]: servicesSectionsAPI.reducer,
  [serviceSupportApi.reducerPath]: serviceSupportApi.reducer,
  [accessSystemApi.reducerPath]: accessSystemApi.reducer,
  [builderSupportApi.reducerPath]: builderSupportApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [technicalSupportTemplatesApi.reducerPath]: technicalSupportTemplatesApi.reducer,
  [eventAPI.reducerPath]: eventAPI.reducer,
  [specialContactsAPI.reducerPath]: specialContactsAPI.reducer,
  [publicPartnersAPI.reducerPath]: publicPartnersAPI.reducer,
  [publicMastersApi.reducerPath]: publicMastersApi.reducer,
  [notificationsAPI.reducerPath]: notificationsAPI.reducer,
  [publicServicesAPI.reducerPath]: publicServicesAPI.reducer,
  [personalAdvertisingAPI.reducerPath]: personalAdvertisingAPI.reducer,
});
