import React, { useEffect, useState } from 'react';
import { Modal } from '7-shared/ui';
import { ReportForm } from './reportForm/reportForm';
import { TypeSwitcher } from './typeSwitcher/typeSwitcher';
import {
  IReports,
  reportsActions,
  selectReportsModalState,
  selectReportsTemplatesListTableData,
} from '6-entities/reports';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { REPORT_TYPE } from '6-entities/reports';
import { ReportTemplateForm } from './reportTemplateForm/reportTemplateForm';
import { isEmpty } from '7-shared/lib/lodash';

export const WrapperReportForm = () => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<REPORT_TYPE>(REPORT_TYPE.personal);
  const { open, currentItem, isLoading } = useAppSelector(selectReportsModalState);
  const templates = useAppSelector(selectReportsTemplatesListTableData);
  const onCloseModalHandler = () => {
    dispatch(reportsActions.setReportsModalState({ open: false, currentItem: undefined }));
  };
  useEffect(() => {
    if (!isEmpty(currentItem)) {
      currentItem?.created_by_template ? setState(REPORT_TYPE.template) : setState(currentItem?.type as REPORT_TYPE);
    }
  }, [currentItem]);

  return (
    <Modal
      onClose={onCloseModalHandler}
      loading={isLoading}
      opened={open}
      title={isEmpty(currentItem) ? 'Добавление отчета' : 'Редактирование отчета'}
    >
      {isEmpty(currentItem) ? (
        <TypeSwitcher value={state} onChange={setState} disabled={!isEmpty(currentItem)} />
      ) : null}
      {state === REPORT_TYPE.common || state === REPORT_TYPE.personal ? (
        <ReportForm currentItem={currentItem as IReports} />
      ) : (
        <ReportTemplateForm templates={templates.data} currentItem={currentItem as IReports} />
      )}
    </Modal>
  );
};
