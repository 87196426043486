import { createAsyncThunk } from '@reduxjs/toolkit';
import { builderSupportApi } from '../api/builderSupport.api';
import { builderSupportActions } from './builderSupport.slice';
import { mapBuilderSupportList } from '../lib/mapBuilderSupportList';
import { mapFiltersBuilderSupport } from '../lib/mapFiltersBuilderSuppor';

export const getBuilderSupportListThunk = createAsyncThunk<void, void, { state: RootState }>(
  'builderSupport/getBuilderSupportList',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(builderSupportActions.setListTableData({ isLoading: true }));

      const filters = mapFiltersBuilderSupport(getState().builderSupport.filters);
      const pagination = getState().builderSupport.pagination;

      const result = await builderSupportApi.getBuilderSupportList(pagination.page, pagination.page_size, filters);
      const list = result.results.map((i) => mapBuilderSupportList(i));
      dispatch(builderSupportActions.setListTableData({ data: list }));
      dispatch(builderSupportActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(builderSupportActions.setListTableData({ isLoading: false }));
    }
  }
);
export const getBuilderSupportReportThunk = createAsyncThunk<any, void, { state: RootState }>(
  'builderSupport/getBuilderSupportReport',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(builderSupportActions.setListTableData({ isLoading: true }));
      const filters = mapFiltersBuilderSupport(getState().builderSupport.filters);
      builderSupportApi.getBuilderSupportReport(filters);
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(builderSupportActions.setListTableData({ isLoading: false }));
    }
  }
);
