import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';
import { ITemplateReports, ITemplateReportsForm } from './types';

export const templatesApi = {
  getTemplatesReports(page: number, page_size?: number): Promise<IPaginationResponse<ITemplateReports>> {
    return instance
      .get(`/cms/report-templates/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  editTemplateReports(id: string, data: ITemplateReportsForm) {
    return instance.put(`cms/report-templates/${id}/`, data).then((res) => res.data);
  },
  createTemplateReports(data: ITemplateReportsForm) {
    return instance.post(`cms/report-templates/`, data).then((res) => res.data);
  },
  deleteTemplateReports(id: string) {
    return instance.delete(`cms/report-templates/${id}/`).then((res) => res.data);
  },
};
