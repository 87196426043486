import React, { useEffect } from 'react';
import { BackArrow, Flex, Title } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { LikesPagination, LikesTable } from '5-features/likes';
import { useParams } from 'react-router-dom';
import { getCurrentNewsThunk, newsActions, selectNewsListPagination } from '6-entities/news';

export const LikesList = () => {
  const dispatch = useAppDispatch();

  const { onChange, onClear, params, pagination } = useQueryParams();
  const { id } = useParams();
  const tablePagination = useAppSelector(selectNewsListPagination);

  useEffect(() => {
    if (tablePagination?.page && id) {
      dispatch(getCurrentNewsThunk(+id));
    }
  }, [tablePagination?.page]);

  useEffect(() => {
    return () => {
      dispatch(newsActions.setInitialNewsstate());
    };
  }, []);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(newsActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  return (
    <Flex direction={'column'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Flex gap={10} align={'center'}>
          <BackArrow />
          <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
            Информация о лайках
          </Title>
        </Flex>
      </Flex>
      <LikesTable />
      <LikesPagination onChange={onChange} />
    </Flex>
  );
};
