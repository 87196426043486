import { ITimeStamps } from './app.interface';
import { ISettlement } from './settlements.interface';

export interface IUser {
  id: number;
  code: string;
  date_joined: string;
  email: string;
  first_name: string;
  get_notifications: boolean;
  is_active: boolean;
  is_test: boolean;
  last_name: string;
  middle_name: string;
  phone: string;
  realty: IUserRealty[];
  role: ROLES.CLIENT | ROLES.OWNER | ROLES.GUEST;
  settlements: ISettlement[];
  is_active_in_app: boolean;
  last_visit: string;
  account_id?: string;
  addresses: IUserAddresses[];
}
export interface Ilikes {
  date_joined: string;
  email: string;
  first_name: string;
  get_notifications: boolean;
  id: number;
  is_active: boolean;
  last_name: string;
  middle_name: string;
  phone: string;
  role: ROLES.CLIENT | ROLES.OWNER | ROLES.GUEST;
  name_settlement: string;
}

export interface IUserAddresses {
  account: string;
  address: {
    corpus: string | null;
    number: string;
    realty_id: number;
    street__name: string;
  };
  land_plot_id: number;
  land_plot_number: string;
  settlement: {
    id: number;
    name: string;
    address: string;
    description: string;
    is_guest: boolean;
  };
  user_role?: ROLES.CLIENT | ROLES.OWNER;
}

export interface IUserRealty extends ITimeStamps {
  corpus: string;
  id: number;
  number: string;
  street: IUserStreet;
}

export interface IUserStreet extends ITimeStamps {
  id: number;
  name: string;
  settlement: IUserSettlement;
}
export interface IUserSettlement {
  address: string;
  description: string;
  id: number;
  name: string;
}

export interface IUserForm {
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  addresses: IUserFormAddresses[];
  detail?: undefined;
  land_plot?: undefined;
  code?: string;
}

export interface IGuestUserForm {
  settlement_id: string;
  detail?: undefined;
}

export interface IUserFormAddresses {
  user_role?: ROLES.CLIENT | ROLES.OWNER;
  settlement_id?: number | string;
  land_plot_id?: number | string;
}

export enum ROLES {
  CLIENT = 'client',
  OWNER = 'owner',
  GUEST = 'guest',
  OWNER_AND_CLIENT = 'owner_and_client',
}
