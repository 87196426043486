import React, { ReactComponentElement } from 'react';
import {
  IconAddressBook,
  IconBadgeAd,
  IconBrandYoutube,
  IconBuildingCastle,
  IconCircleCheck,
  IconHammer,
  IconHeartHandshake,
  IconHome2,
  IconInfoCircle,
  IconMail,
  IconShieldLock,
  IconNews,
  IconPolaroid,
  IconTools,
  IconUsers,
  IconVideo,
  TablerIcon,
  IconRecycle,
  IconHomeCog,
} from '@tabler/icons';
import { Indicator } from '@mantine/core';
import { PERMISSIONS } from 'interface';
import { ROUTE_PATH } from 'shared/constants';
import { TFunction } from 'i18next';
import { routes } from '7-shared/lib/routes';

export interface INavbarLinks {
  path: string;
  title: string;
  icon: ReactComponentElement<TablerIcon>;
  permissions: PERMISSIONS;
}

export const navBarLinks = (
  t: TFunction,
  isTechnicalSupportDot: boolean,
  isServiceSupportDot: boolean,
  isBuilderSupportDot: boolean,
  iconSize = 22
): INavbarLinks[] => [
  {
    path: ROUTE_PATH.USERS,
    title: `${t('app.navbar.users')}`,
    icon: <IconUsers size={iconSize} />,
    permissions: PERMISSIONS.USERS,
  },
  {
    path: ROUTE_PATH.SETTLEMENTS,
    title: `${t('app.navbar.settlements')}`,
    icon: <IconHome2 size={iconSize} />,
    permissions: PERMISSIONS.SETTLEMENTS,
  },
  {
    path: ROUTE_PATH.SURVEYS,
    title: `${t('app.navbar.surveys')}`,
    icon: <IconCircleCheck size={iconSize} />,
    permissions: PERMISSIONS.SURVEYS,
  },
  {
    path: ROUTE_PATH.NEWS,
    title: `${t('app.navbar.news')}`,
    icon: <IconNews size={iconSize} />,
    permissions: PERMISSIONS.NEWS,
  },
  {
    path: ROUTE_PATH.DOCUMENTS,
    title: `${t('app.navbar.documents')}`,
    icon: <IconInfoCircle size={iconSize} />,
    permissions: PERMISSIONS.INFORMATION,
  },
  {
    path: ROUTE_PATH.VIDEOS,
    title: `${t('app.navbar.videos')}`,
    icon: <IconVideo size={iconSize} />,
    permissions: PERMISSIONS.VIDEO,
  },
  {
    path: ROUTE_PATH.REPORTS,
    title: `${t('app.navbar.reports')}`,
    icon: <IconPolaroid size={iconSize} />,
    permissions: PERMISSIONS.REPORTS,
  },
  {
    path: routes.technical_support.root,
    title: `${t('app.navbar.technical_support')}`,
    icon: (
      <Indicator inline size={isTechnicalSupportDot ? 9 : 0} offset={4} position="top-end" color="red">
        <IconMail size={iconSize} />
      </Indicator>
    ),
    permissions: PERMISSIONS.TECHNICAL_SUPPORT,
  },
  {
    path: ROUTE_PATH.SERVICE_SUPPORT,
    title: `${t('app.navbar.service_support')}`,
    icon: (
      <Indicator inline size={isServiceSupportDot ? 9 : 0} offset={4} position="top-end" color="red">
        <IconRecycle size={iconSize} />
      </Indicator>
    ),
    permissions: PERMISSIONS.SERVICE_SUPPORT,
  },
  {
    path: ROUTE_PATH.BUILDER_SUPPORT,
    title: `${t('app.navbar.builder_support')}`,
    icon: (
      <Indicator inline size={isBuilderSupportDot ? 9 : 0} offset={4} position="top-end" color="red">
        <IconHomeCog size={iconSize} />
      </Indicator>
    ),
    permissions: PERMISSIONS.BUILDER_SUPPORT,
  },
  {
    path: ROUTE_PATH.ACCESS_SYSTEM,
    title: `${t('app.navbar.security')}`,
    icon: <IconShieldLock size={iconSize} />,
    permissions: PERMISSIONS.ACCESS_SYSTEM,
  },
  {
    path: ROUTE_PATH.COMPANIES,
    title: `${t('app.navbar.companies')}`,
    icon: <IconBuildingCastle size={iconSize} />,
    permissions: PERMISSIONS.COMPANIES,
  },
  {
    path: ROUTE_PATH.MASTERS,
    title: `${t('app.navbar.masters')}`,
    icon: <IconHammer size={iconSize} />,
    permissions: PERMISSIONS.MASTERS,
  },
  {
    path: ROUTE_PATH.PARTNERS,
    title: `${t('app.navbar.partners')}`,
    icon: <IconHeartHandshake size={iconSize} />,
    permissions: PERMISSIONS.PARTNERS,
  },
  {
    path: ROUTE_PATH.SERVICES,
    title: `${t('app.navbar.services')}`,
    icon: <IconTools size={iconSize} />,
    permissions: PERMISSIONS.SERVICES,
  },
  {
    path: ROUTE_PATH.CONTACTS,
    title: `${t('app.navbar.contacts')}`,
    icon: <IconAddressBook size={iconSize} />,
    permissions: PERMISSIONS.CONTACTS,
  },
  {
    path: ROUTE_PATH.ADVERTISING,
    title: `${t('app.navbar.advertising')}`,
    icon: <IconBadgeAd size={iconSize} />,
    permissions: PERMISSIONS.ADVERTISING,
  },
  {
    path: ROUTE_PATH.VIDEO_CONTENT,
    title: `${t('app.navbar.video_content')}`,
    icon: <IconBrandYoutube size={iconSize} />,
    permissions: PERMISSIONS.VIDEO_CONTENT,
  },
];
