import React from 'react';
import { selectTechnicalSupportListTableData } from '6-entities/technicalSupport';
import { useAppSelector } from '7-shared/hooks';
import { filterColumnsByAccessor } from '7-shared/utils';

import { columns } from '../../model/technicalSupportListColumns';
import { selectConfigIsLoading, selectConfigTexnicalSupport } from '6-entities/app';
import { selectIsLoader } from '7-shared/api';
import { Table } from '7-shared/ui';

export const TechnicalSupportTable = () => {
  const tableData = useAppSelector(selectTechnicalSupportListTableData);
  const tableSettings = useAppSelector(selectConfigTexnicalSupport);
  const configIsLoading = useAppSelector(selectConfigIsLoading);
  const adresesIsLoading = useAppSelector(selectIsLoader);

  return (
    <Table
      columns={filterColumnsByAccessor(tableSettings, columns)}
      rows={tableData.data}
      tableLoading={tableData.isLoading || configIsLoading || adresesIsLoading}
      wholeScreen={false}
    />
  );
};
