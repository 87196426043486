import React from 'react';
import { Tooltip } from '7-shared/ui';
import { IReports, REPORT_TYPE } from '6-entities/reports';
import { ReportsTableActions } from '../ui/reportsTableActions/reportsTableActions';

export interface ReportColumnTable {
  accessor: keyof IReports;
  render?: (value: IReports, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: ReportColumnTable[] = [
  {
    accessor: 'title',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.title}`}>
        {value.title}.
      </Tooltip>
    ),
    width: 500,
    ellipsis: true,
  },
  {
    accessor: 'address',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.address}`}>
        {value.type === REPORT_TYPE.common
          ? `${value?.settlement?.name ? value?.settlement?.name : ''}`
          : `${value?.realty?.street?.settlement?.name ? `${value?.realty?.street?.settlement?.name},` : ''} ${
              value?.realty?.street?.name ? `${value?.realty?.street?.name},` : ''
            } ${value?.realty?.number ? value?.realty?.number : ''} ${
              value?.realty?.corpus ? `,${value?.realty?.corpus}` : ''
            }`}
      </Tooltip>
    ),
  },
  {
    accessor: 'link',
    render: (value, index) => (
      <a href={value.link} target="_blank">
        Ссылка
      </a>
    ),
  },
  {
    accessor: 'actions',
    render: (value) => <ReportsTableActions record={value} />,
  },
];
