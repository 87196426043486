import React from 'react';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';
import { columns } from '../../model/templatesListColumns';
import { selectReportsTemplatesListTableData } from '6-entities/reports';

export const TemplatesTable = <R extends object>() => {
  const tableData = useAppSelector(selectReportsTemplatesListTableData);
  const rows = tableData.data;
  return <Table noHeader={true} columns={columns} rows={rows} tableLoading={tableData.isLoading} />;
};
