import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from '7-shared/lib/lodash';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { Button, Input, Textarea } from '7-shared/ui';
import {
  createSettlementThunk,
  editSettlementThunk,
  ISettlementForm,
  selectSettlementsModalState,
  settlementsActions,
} from '6-entities/settlement';
import { settlementsSchema } from '../../model/settlements.schema';
import { notification } from '7-shared/lib/notification';
import { useNavigate } from 'react-router-dom';
import { routes } from '7-shared/lib/routes';

export const SettlementsForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentItem } = useAppSelector(selectSettlementsModalState);
  const { control, handleSubmit, setError, reset } = useForm<ISettlementForm>({
    mode: 'onChange',
    resolver: yupResolver(settlementsSchema),
    defaultValues: currentItem,
  });

  useEffect(() => {
    reset({
      name: currentItem?.name,
      address: currentItem?.address,
      description: currentItem?.description,
    });
    return () => {
      dispatch(settlementsActions.setSettlementsModalState({ open: false, currentItem: undefined }));
    };
  }, [currentItem, reset]);

  const onSubmit = (data: ISettlementForm) => {
    isEmpty(currentItem) ? createSettlementHandler(data) : editSettlementHandler(data);
  };

  const onCloseModalHandler = () => {
    dispatch(settlementsActions.setSettlementsModalState({ open: false, currentItem: undefined }));
    reset({});
    navigate(routes.settlements.root);
  };
  const createSettlementHandler = (data: ISettlementForm) => {
    dispatch(createSettlementThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Создание КП запрещено',
        });
      });
  };
  const editSettlementHandler = (data: ISettlementForm) => {
    dispatch(editSettlementThunk({ id: currentItem?.id as number, data }))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Редактирование КП запрещено',
        });
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-2 w-full items-center justify-center'}>
      <Input
        name={'name'}
        control={control}
        label={'Наименование'}
        placeholder={'Наименование'}
        withAsterisk
        className={'w-full'}
      />
      <Input
        name={'address'}
        control={control}
        label={'Адрес'}
        placeholder={'Адрес'}
        withAsterisk
        className={'w-full'}
      />
      <Textarea
        className={'w-full'}
        name={'description'}
        control={control}
        label={'Описание'}
        placeholder={'Описание'}
        minRows={8}
        maxRows={8}
      />

      <Button mt={30} size={'md'} type={'submit'} fullWidth>
        {isEmpty(currentItem) ? 'Создать' : 'Сохранить'}
      </Button>
    </form>
  );
};
