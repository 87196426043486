import { IPlots } from '../model/types';
import { IPlotsDto } from '../api/types';

export function mapPlotsList(dto: IPlotsDto): IPlots {
  return {
    id: dto.id,
    landPlotNumber: dto.number,
    account: dto.account?.number,
    street: dto.address?.street__name,
    house: dto.address?.corpus ? `${dto.address?.number} к.${dto.address.corpus}` : dto.address?.number,
    frame: dto.address?.corpus as string,
  };
}
