import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IMaster, IMasterForm, IPaginationResponse, IQueryParams } from 'interface';
import { customFetchBase } from '../fetchBase';

export const mastersAPI = createApi({
  reducerPath: 'mastersAPI',
  tagTypes: ['masters', 'pre-masters'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getMasters: build.query<IPaginationResponse<IMaster[]>, IQueryParams>({
      query: ({ page, size, search, status }) => ({
        url: `/cms/masters/`,
        params: { page, page_size: size, search, status: status },
      }),
      providesTags: ['masters'],
    }),
    createMaster: build.mutation<IMaster, IMasterForm>({
      query: (data) => ({
        url: `cms/masters/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['masters'],
    }),
    editMaster: build.mutation<IMaster, IEditBody<IMasterForm>>({
      query: (data) => ({
        url: `cms/masters/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['masters'],
    }),
    deleteMaster: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/masters/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['masters'],
    }),
    getPreMasters: build.query<IPaginationResponse<IMaster[]>, IQueryParams>({
      query: ({ page, size, search, status, time_created__lte, time_created__gte }) => ({
        url: `/cms/pre-masters/`,
        params: { page, page_size: size, search, status__in: status, time_created__gte, time_created__lte },
      }),
      providesTags: ['pre-masters'],
    }),
    editPreMaster: build.mutation<IMaster, IEditBody<IMasterForm>>({
      query: (data) => ({
        url: `cms/pre-masters/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['masters', 'pre-masters'],
    }),
    deletePreMaster: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/pre-masters/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['pre-masters'],
    }),
  }),
});

export const {
  useCreateMasterMutation,
  useEditPreMasterMutation,
  useGetPreMastersQuery,
  useDeleteMasterMutation,
  useEditMasterMutation,
  useGetMastersQuery,
  useDeletePreMasterMutation,
} = mastersAPI;
