import React from 'react';

import { IBuilderSupport } from '6-entities/builderSupport';
import { Badge, Rating, Tooltip } from '7-shared/ui';
import { getUserRoleColorHandler, getUserRoleNameHandler } from '7-shared/utils';
import { routes } from '7-shared/lib/routes';
import { ROLES } from '7-shared/accesses';

import { builder_support_statuses } from './statuses';
import { Link } from 'react-router-dom';

export interface BuilderSupportColumnTable {
  accessor: keyof IBuilderSupport;
  title: string | JSX.Element;
  render?: (value: IBuilderSupport, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: BuilderSupportColumnTable[] = [
  {
    accessor: 'status',
    title: 'Статус',
    render: (value) => (
      <Badge size="lg" variant="filled" color={builder_support_statuses.find((i) => i.value === value.status)?.color}>
        {builder_support_statuses.find((i) => i.value === value.status)?.label}
      </Badge>
    ),
    width: 230,
    ellipsis: true,
  },
  {
    accessor: 'id',
    title: '№ заявки',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.id}`}>
        <Link to={`${routes.builder_support.root}/${value?.id}`} className={'text-16 font-600 text-black'}>
          {value?.id ?? ''}
        </Link>
      </Tooltip>
    ),
    width: 165,
  },
  {
    accessor: 'name',
    title: 'ФИО',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.name}`}>
        <Link to={`${routes.builder_support.root}/${value?.id}`} className={'text-16 font-600 text-black'}>
          {value?.name ?? ''}
        </Link>
      </Tooltip>
    ),
    width: 200,
  },
  {
    accessor: 'account_id',
    title: 'Лицевой счет',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.account_id}`}>
        {value?.account_id ?? ''}
      </Tooltip>
    ),
    width: 210,
  },
  {
    accessor: 'role',
    title: 'Роль',
    render: (value) => (
      <Badge color={getUserRoleColorHandler(value.role ? value.role : ROLES.CLIENT)}>
        {getUserRoleNameHandler(value.role ? value.role : ROLES.CLIENT)}
      </Badge>
    ),
    width: 140,
  },
  {
    accessor: 'phone',
    title: 'Телефон',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.phone}`}>
        {value?.phone ?? ''}
      </Tooltip>
    ),
    width: 200,
  },
  {
    accessor: 'email',
    title: 'Email',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.email}`}>
        {value?.email ?? ''}
      </Tooltip>
    ),
    width: 300,
  },
  {
    accessor: 'settlement',
    title: 'КП',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.settlement}`}>
        {value?.settlement ?? ''}
      </Tooltip>
    ),
    width: 175,
    ellipsis: true,
  },
  {
    accessor: 'address',
    title: 'Адрес',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.address}`}>
        {value?.address ?? ''}
      </Tooltip>
    ),
    width: 255,
    ellipsis: true,
  },
  {
    accessor: 'plot',
    title: 'ЗУ',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.plot}`}>
        {value?.plot ?? ''}
      </Tooltip>
    ),
    width: 150,
    ellipsis: true,
  },
  {
    accessor: 'comment',
    title: 'Комментарий',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.comment}`}>
        {value?.comment ?? ''}
      </Tooltip>
    ),
    width: 200,
    ellipsis: true,
  },
  {
    accessor: 'dateOfCreation',
    title: 'Дата создания',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.dateOfCreation}`}>
        {value?.dateOfCreation ?? ''}
      </Tooltip>
    ),
    width: 355,
  },
  {
    accessor: 'dateOfClosed',
    title: 'Дата закрытия',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.dateOfClosed}`}>
        {value?.dateOfClosed ?? ''}
      </Tooltip>
    ),
    width: 355,
    ellipsis: true,
  },
  {
    accessor: 'manager',
    title: 'Исполнитель',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.manager}`}>
        {value?.manager ?? ''}
      </Tooltip>
    ),
    width: 290,
    ellipsis: true,
  },
  {
    accessor: 'score',
    title: 'Оценка',
    render: (value) => <Rating value={value.score} readOnly />,
    width: 155,
    ellipsis: true,
  },
];
