import React, { useEffect } from 'react';

import {
  TechnicalSupporPagination,
  TechnicalSupportFilters,
  TechnicalSupportReports,
  TechnicalSupportSettings,
  TechnicalSupportTable,
} from '5-features/technicalSupport';
import {
  getTechnicalSupportListThunk,
  ITechnicalSupportListFilters,
  selectTechnicalSupportListFilters,
  selectTechnicalSupportListPagination,
  technicalSupportActions,
} from '6-entities/technicalSupport';
import { Flex, ScrollArea, Title } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { getSettlementListThunk } from '7-shared/api/adreses';

export const TechnicalSupportList = () => {
  const dispatch = useAppDispatch();

  const { onChange, onClear, params, pagination } = useQueryParams<ITechnicalSupportListFilters>();

  const filters = useAppSelector(selectTechnicalSupportListFilters);
  const tablePagination = useAppSelector(selectTechnicalSupportListPagination);

  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getTechnicalSupportListThunk());
    }
  }, [tablePagination?.page, filters]);

  useEffect(() => {
    if (params && JSON.stringify(params) !== JSON.stringify(filters)) {
      dispatch(technicalSupportActions.setListFilters({ ...params }));
    }
  }, [params]);

  useEffect(() => {
    dispatch(getSettlementListThunk());
    return () => {
      dispatch(technicalSupportActions.setInitialTechnicalSupportstate());
    };
  }, []);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(technicalSupportActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  return (
    <Flex direction={'column'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
          Техническая поддержка
        </Title>
        <TechnicalSupportReports onChange={onChange} onClear={onClear} params={params} />
      </Flex>
      <Flex className={'mr-[10px]'} justify={'flex-end'} align={'center'}>
        <TechnicalSupportSettings />
      </Flex>
      <ScrollArea>
        <div className={'flex flex-col gap-4'}>
          <TechnicalSupportFilters onChange={onChange} onClear={onClear} params={params} />
          <TechnicalSupportTable />
        </div>
      </ScrollArea>
      <TechnicalSupporPagination onChange={onChange} />
    </Flex>
  );
};
