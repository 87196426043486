import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';
import { IReport } from './types';
import { IImportReportForm, IReportForm, IReportsFilters, IReportViaTemplateForm } from '../model/types';

export const reportsApi = {
  getReportsList(page: number, page_size?: number, filters?: IReportsFilters): Promise<IPaginationResponse<IReport>> {
    return instance
      .get(`/cms/reports/`, {
        params: { page, page_size, ...filters },
      })
      .then((res) => res.data);
  },
  editReport(id: string, data: IReportForm) {
    return instance.put(`/cms/reports/${id}/`, data).then((res) => res.data);
  },
  createReport(data: IReportForm) {
    return instance.post(`/cms/reports/`, data).then((res) => res.data);
  },
  createReportViaTemplate(data: IReportViaTemplateForm) {
    return instance.post(`/cms/reports/create/via/template/`, data).then((res) => res.data);
  },
  importFileReports(data: IImportReportForm) {
    return instance.post(`/cms/reports/create/multiply/${data.report_template_id}/`, data.file).then((res) => res.data);
  },
  deleteReport(id: string) {
    return instance.delete(`cms/reports/${id}/`).then((res) => res.data);
  },
};
