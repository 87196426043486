import { createApi } from '@reduxjs/toolkit/query/react';
import { IDocument, IEditBody, IPaginationResponse, IQueryParams } from 'interface';
import { customFetchBase } from 'store/fetchBase';

export const documentsAPI = createApi({
  reducerPath: 'documentsAPI',
  tagTypes: ['documents'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getDocuments: build.query<IPaginationResponse<IDocument[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/documents/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['documents'],
    }),
    createDocument: build.mutation<IDocument, FormData>({
      query: (data) => ({
        url: `/cms/documents/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['documents'],
    }),
    editDocument: build.mutation<IDocument, IEditBody<FormData>>({
      query: (data) => ({
        url: `/cms/documents/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['documents'],
    }),
    deleteDocument: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/documents/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['documents'],
    }),
  }),
});

export const { useDeleteDocumentMutation, useEditDocumentMutation, useCreateDocumentMutation, useGetDocumentsQuery } =
  documentsAPI;
