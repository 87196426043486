import * as yup from 'yup';
import { validate } from '7-shared/constants';

export const cameraSchema = yup.object().shape({
  name: yup.string().required(validate.required).max(200, `${validate.max} 200`),
  login: yup.string().required(validate.required).max(50, `${validate.max} 50`),
  password: yup.string().required(validate.required).max(128, `${validate.max} 128`),
  ip: yup.string().required(validate.required).max(50, `${validate.max} 50`),
  endpoint: yup.string().required(validate.required).max(100, `${validate.max} 100`),
});
