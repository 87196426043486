import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { IPagination } from 'interface';
import { useSetQueryParams } from './useSetQueryParams';

interface IUseQueryParams {
  onChange: (fieldName: string, value: string) => void;
  onClear: (fieldName: string) => void;
  params?: Record<string, string>;
  pagination: IPagination;
}

/**
 * @description Хук для управления состоянием фильтрации, позволяет управлять состоянием через функции и url.
 * @return {onChange} Функция для изменения списка фильтров и изменения урла.
 * @return {onClear} Функция для очищения поля.
 * @return {params} Список активных фильтров.
 * @return {pagination} Параметры для пагинации.
 */
export const useQueryParams = (): IUseQueryParams => {
  const [params, setParams] = useState<Record<string, string>>();

  const { onChangeQuery, deleteQueryFromUrl, getQueryFromUrl } = useSetQueryParams();

  const onChangeParams = (fieldName: string, value: string) => {
    if (!value) {
      onClearParams(fieldName);
    } else {
      const newFilter = { ...params, [fieldName]: value, page: fieldName === 'page' ? value : '1' };
      setParams(newFilter);
      onChangeQuery(newFilter);
    }
  };

  const onClearParams = (fieldName: string) => {
    const newFilter = omit(params, fieldName);
    setParams({ ...newFilter, page: '1' });
    deleteQueryFromUrl(fieldName);
  };

  const setParamsInitialValues = () => {
    const paramsFromUrl = getQueryFromUrl();
    setParams(paramsFromUrl);
  };

  useEffect(() => {
    setParamsInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onChange: onChangeParams,
    onClear: onClearParams,
    params,
    pagination: {
      onChange: onChangeParams,
      currentPage: params?.page ? Number(params?.page) : 1,
      limit: params?.limit ? Number(params.limit) : 10,
    },
  };
};
