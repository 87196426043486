import React, { useEffect, useState } from 'react';
import { Button, Flex } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import {
  ImportReportsForm,
  ReportsFilters,
  ReportsPagination,
  ReportsTable,
  WrapperReportForm,
} from '5-features/reports';
import { IconPlus } from '7-shared/assets';
import {
  getReportsListThunk,
  getTemplatesReportsThunk,
  IReportsFilters,
  reportsActions,
  selectReportsListFilters,
  selectReportsListPagination,
} from '6-entities/reports';
import { getSettlementListThunk } from '7-shared/api';

export const ReportsList = () => {
  const dispatch = useAppDispatch();

  const { params, pagination } = useQueryParams<IReportsFilters>();

  const filters = useAppSelector(selectReportsListFilters);
  const tablePagination = useAppSelector(selectReportsListPagination);
  const [isOpen, setIsOpen] = useState(false);

  const openModalTemplates = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    dispatch(getSettlementListThunk());
    dispatch(getTemplatesReportsThunk());
  }, []);

  const onPressAddHandler = () => {
    dispatch(reportsActions.setReportsModalState({ open: true }));
  };
  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(filters)) {
      dispatch(reportsActions.setListFilters({ ...params }));
    }
  }, [params]);

  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getReportsListThunk());
    }
  }, [tablePagination?.page, filters]);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(reportsActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  useEffect(() => {
    return () => {
      dispatch(reportsActions.setInitialReportsstate());
    };
  }, []);

  return (
    <Flex direction={'column'} className={'flex-col  h-full'} gap={10}>
      <Flex gap={'md'} justify={'flex-end'}>
        <Button rightIcon={<IconPlus size={20} />} variant="outline" onClick={openModalTemplates}>
          Импортировать
        </Button>
        <Button rightIcon={<IconPlus size={20} />} className={'w-max'} variant="outline" onClick={onPressAddHandler}>
          Добавить отчет
        </Button>
      </Flex>
      <ReportsFilters />
      <ReportsTable />
      <ReportsPagination />
      <WrapperReportForm />
      <ImportReportsForm openModalTemplates={openModalTemplates} isOpen={isOpen} />
    </Flex>
  );
};
