import { ISettlement } from './settlements.interface';
import { IUser, ROLES } from './users.interface';

export interface ISurvey {
  answers: IAnswer[];
  expire_date: string;
  id: number;
  publish_date: string;
  respondents_number: number;
  settlements: ISettlement[];
  show_all: boolean;
  status: SURVEY_STATUS;
  title: string;
  type: string;
}

export interface IAnswer {
  body: string;
  created: string;
  id: number;
  response_number: number;
  response_percentage: number;
  updated: string;
}

export interface ISurveyForm {
  title: string;
  expire_date: string;
  answers: IAnswerForm[];
  settlements: number[];
  show_all: boolean;
  detail?: string;
}

export interface IUserVoteAddress {
  land_plot_number: string;
  settlement_name: string;
  user_role?: ROLES;
  realty_address: {
    corpus: string | null;
    number: string;
    street: string;
  };
}

export interface IAnswerForm {
  body: string;
}

export interface ISurveysVoicesResults extends IAnswer {
  users_voted: {
    user: IUser;
    address: IUserVoteAddress;
  }[];
}

export enum SURVEY_STATUS {
  COMPLETED = 'completed',
  CURRENT = 'current',
}
