import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRealty, ISettlement, IStreets } from '../api/types';

interface IInitialState {
  isLoader: boolean;
  settlements: ISettlement[];
  streets: IStreets[];
  realty: IRealty[];
}

const initialState: IInitialState = {
  isLoader: false,
  settlements: [],
  streets: [],
  realty: [],
};

export const adresesSlice = createSlice({
  name: 'adreses',
  initialState,
  reducers: {
    setSettlements: (state, action: PayloadAction<ISettlement[]>) => {
      state.settlements = action.payload;
    },
    setStreets: (state, action: PayloadAction<IStreets[]>) => {
      state.streets = action.payload;
    },
    setRealty: (state, action: PayloadAction<IRealty[]>) => {
      state.realty = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoader = action.payload;
    },
  },
});

export const selectSettlements = (state: RootState) => state.adreses.settlements;
export const selectStreets = (state: RootState) => state.adreses.streets;
export const selectRealty = (state: RootState) => state.adreses.realty;
export const selectIsLoader = (state: RootState) => state.adreses.isLoader;

export const adresesActions = adresesSlice.actions;
