import defaultTo from 'lodash/defaultTo';
import { IUser, STAFF_ROLES } from 'interface';

/**
 * @description Собрать имя пользователя
 * @param {IUser} data данные пользователя.
 * @param {boolean} isGuest является ли гостем.
 * @return {string} Если это авторизованный пользователь, то возвращает полное имя пользователя, либо "Гость".
 */
export const getFullName = (data: IUser, isGuest = false) => {
  return isGuest ? 'Гость' : `${defaultTo(data?.first_name, '')} ${defaultTo(data?.last_name, '')}`;
};

/**
 * @description Получаем имя роли сотрудника.
 * @param {STAFF_ROLES} role роль сотрудника.
 * @return {string} Возвращает название роли.
 */
export const getStaffRoleNameHandler = (role: STAFF_ROLES) => {
  switch (role) {
    case STAFF_ROLES.MANAGER:
      return 'Менеджер';
    case STAFF_ROLES.DIRECTOR:
      return 'Руководитель';
    case STAFF_ROLES.GUARD:
      return 'Охранник';
  }
};
