import { createApi } from '@reduxjs/toolkit/query/react';
import { ICategory, IInvitePartnerForm, IPaginationResponse } from 'interface';
import { customFetchBase } from '../fetchBase';

export const publicServicesAPI = createApi({
  reducerPath: 'publicServicesAPI',
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    createInviteService: build.mutation<null, IInvitePartnerForm>({
      query: (data) => ({
        url: `open/pre-services/`,
        method: 'POST',
        body: data,
      }),
    }),
    getPublicServicesSections: build.query<IPaginationResponse<ICategory[]>, string>({
      query: (company_id) => ({
        url: `open/service-sections/${company_id}/?page=1&page_size=10000`,
      }),
    }),
  }),
});

export const { useCreateInviteServiceMutation, useGetPublicServicesSectionsQuery } = publicServicesAPI;
