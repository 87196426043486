import { createApi } from '@reduxjs/toolkit/query/react';
import { ICategory, IInviteMasterForm, IPaginationResponse } from 'interface';
import { customFetchBase } from '../fetchBase';

export const publicMastersApi = createApi({
  reducerPath: 'publicMastersApi',
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    createInviteMaster: build.mutation<null, IInviteMasterForm>({
      query: (data) => ({
        url: `open/pre-masters/`,
        method: 'POST',
        body: data,
      }),
    }),
    getPublicMastersSections: build.query<IPaginationResponse<ICategory[]>, string>({
      query: (company_id) => ({
        url: `open/master-sections/${company_id}/?page=1&page_size=10000`,
      }),
    }),
  }),
});

export const { useCreateInviteMasterMutation, useGetPublicMastersSectionsQuery } = publicMastersApi;
