import { createAsyncThunk } from '@reduxjs/toolkit';
import { adresesApi } from '../api/adreses.api';
import { adresesActions } from '../model/adreses.slice';
import { mapSettlementList } from '../lib/mapSettlementList';
import { mapStreetsList } from '../lib/mapStreetsList';
import { mapRealtyList } from '../lib/mapRealtyList';

export const getSettlementListThunk = createAsyncThunk<void, void, { state: RootState }>(
  'adreses/getSettlement',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(adresesActions.setIsLoading(true));
      const resultSettlement = await adresesApi.getSettlement();
      const listSettlement = resultSettlement.results.map((i) => mapSettlementList(i));
      dispatch(adresesActions.setSettlements(listSettlement));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(adresesActions.setIsLoading(false));
    }
  }
);
export const getStreetsListThunk = createAsyncThunk<void, string, { state: RootState }>(
  'adreses/getStreetsList',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(adresesActions.setIsLoading(true));
      const resultStreet = await adresesApi.getStreetList(id);
      const listStreet = resultStreet.results.map((i) => mapStreetsList(i));
      dispatch(adresesActions.setStreets(listStreet));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(adresesActions.setIsLoading(false));
    }
  }
);

export const getRealtyThunk = createAsyncThunk<void, string, { state: RootState }>(
  'adreses/getRealty',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(adresesActions.setIsLoading(true));
      const result = await adresesApi.getRealtyList(id);

      const list = result.results.map((i) => mapRealtyList(i));

      dispatch(adresesActions.setRealty(list));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(adresesActions.setIsLoading(false));
    }
  }
);
