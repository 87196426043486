import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { Button, Modal, Select } from '7-shared/ui';
import { selectSettlements } from '7-shared/api';
import * as yup from 'yup';
import { validate } from '7-shared/constants';
import { createVideoThunk, editVideoThunk, IVideoForm, selectVideosModalState, videosActions } from '6-entities/videos';
import { collectObjectSelect } from '../../model/utils/collectObjectSelect.utils';
import { isEmpty } from '7-shared/lib/lodash';
import { notification } from '7-shared/lib/notification';

export const VideoForm = () => {
  const dispatch = useAppDispatch();
  const { open, currentItem, isLoading } = useAppSelector(selectVideosModalState);
  const settlements = collectObjectSelect(useAppSelector(selectSettlements));

  const schema = yup.object().shape({
    settlement: yup.string().required(validate.required),
  });
  const { control, handleSubmit, reset } = useForm<IVideoForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onCloseModalHandler = () => {
    dispatch(videosActions.setVideosModalState({ open: false, currentItem: undefined }));
    reset();
  };

  //Заполнение значениеями по умолчанию, при редактировании КП
  useEffect(() => {
    reset({ settlement: `${currentItem?.settlement.id}` });
  }, [currentItem]);

  const onSubmit = (data: IVideoForm) => {
    if (isEmpty(currentItem)) {
      createVideoHandler(data);
    } else {
      editVideoHandler(data);
    }
  };

  const createVideoHandler = (data: IVideoForm) => {
    dispatch(createVideoThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Создание КП запрещено',
        });
      });
  };

  const editVideoHandler = (data: IVideoForm) => {
    dispatch(editVideoThunk({ id: currentItem?.id as number, data }))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error.message ?? 'Редактирование КП запрещено',
        });
      });
  };

  return (
    <Modal
      onClose={onCloseModalHandler}
      loading={isLoading}
      opened={open}
      size={'auto'}
      title={isEmpty(currentItem) ? 'Добавление КП' : 'Редактирование КП'}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'h-auto flex flex-col gap-2 w-full items-center justify-center'}
      >
        <Select
          withinPortal={true}
          className={'w-full'}
          name={'settlement'}
          placeholder={'КП'}
          control={control}
          data={settlements}
        />
        <Button mt={30} size={'md'} type={'submit'} fullWidth className={'mt-[15px] w-full'}>
          {isEmpty(currentItem) ? 'Сохранить КП' : 'Сохранить изменения'}
        </Button>
      </form>
    </Modal>
  );
};
