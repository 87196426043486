import { ITimeStamps } from './app.interface';

export interface ICompany extends ICompanyForm, ITimeStamps {
  id: number;
  staff_number: number;
}

export interface ICompanyForm {
  address: string;
  city: string;
  email: string;
  name: string;
  phone: string;
}

export interface IStaff extends ITimeStamps {
  id: number;
  permissions: { codename: string }[];
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  role: STAFF_ROLES;
  company_id: number;
  settlements?: number[];
}

export interface IStaffForm {
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  email: string;
  role: STAFF_ROLES;
  permissions: string[];
  company_id: number;
  detail?: number;
  settlement_id?: number | number[];
}

export enum STAFF_ROLES {
  MANAGER = 'manager',
  DIRECTOR = 'director',
  GUARD = 'guard',
}
