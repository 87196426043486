import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserConfigDto } from '../model/types';

interface IInitialState {
  is_exists_new_feedback_support: boolean;
  is_exists_new_feedback_communal: boolean;
  is_exists_new_feedback_builder: boolean;
  configTechnicalSupport: string[];
  configServiceSupport: string[];
  configBuilderSupport: string[];
  isLoading: boolean;
}

const initialState: IInitialState = {
  is_exists_new_feedback_support: false,
  is_exists_new_feedback_communal: false,
  is_exists_new_feedback_builder: false,
  configTechnicalSupport: [],
  configServiceSupport: [],
  configBuilderSupport: [],
  isLoading: false,
};

export const appNewSlice = createSlice({
  name: 'appNew',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<IUserConfigDto>) => {
      return { ...state, ...action.payload };
    },
    setIsLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      return { ...state, ...action.payload };
    },
  },
});
// TODO переименовать потом appNew в app
export const selectIsExistsNewFeedbackSupport = (state: RootState) => state.appNew.is_exists_new_feedback_support;
export const selectIsExistsNewFeedbackCommunal = (state: RootState) => state.appNew.is_exists_new_feedback_communal;
export const selectIsExistsNewFeedbackBuilder = (state: RootState) => state.appNew.is_exists_new_feedback_builder;
export const selectConfigTexnicalSupport = (state: RootState) => state.appNew.configTechnicalSupport;
export const selectConfigServiceSupport = (state: RootState) => state.appNew.configServiceSupport;
export const selectConfigBuilderSupport = (state: RootState) => state.appNew.configBuilderSupport;
export const selectConfigIsLoading = (state: RootState) => state.appNew.isLoading;

export const appNewActions = appNewSlice.actions;
