import React from 'react';
import { plotsActions, selectPlotsListPagination } from '6-entities/plots';
import { Pagination } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';

export const PlotsPagination = () => {
  const dispatch = useAppDispatch();

  const { onChange } = useQueryParams();

  const tablePagination = useAppSelector(selectPlotsListPagination);

  const onChangePagination = (newPage: number) => {
    dispatch(plotsActions.setListPagination({ page: newPage }));
    onChange('page', `${newPage}`);
  };

  return (
    <Pagination
      setPage={onChangePagination}
      activePage={tablePagination.page}
      total={Math.ceil(tablePagination.total_count / tablePagination.page_size) ?? 1}
    />
  );
};
