import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams } from 'interface';
import { INews, INewsForm } from 'interface/news.interface';
import { customFetchBase } from '../fetchBase';

export const newsAPI = createApi({
  reducerPath: 'newsAPI',
  tagTypes: ['news', 'currentNews'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getNews: build.query<IPaginationResponse<INews[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/news/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['news'],
    }),
    getCurrentNews: build.query<INews, string | undefined>({
      query: (id) => ({
        url: `cms/news/${id}`,
      }),
      providesTags: ['news', 'currentNews'],
    }),
    createNews: build.mutation<INews, INewsForm>({
      query: (data) => ({
        url: `cms/news/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['news'],
    }),
    editNews: build.mutation<INews, IEditBody<INewsForm>>({
      query: (data) => ({
        url: `cms/news/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['news', 'currentNews'],
    }),
    deleteNews: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/news/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['news'],
    }),
  }),
});

export const {
  useDeleteNewsMutation,
  useCreateNewsMutation,
  useEditNewsMutation,
  useGetCurrentNewsQuery,
  useGetNewsQuery,
} = newsAPI;
