import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditBody,
  IImportReportForm,
  IPaginationResponse,
  IQueryParams,
  IReport,
  IReportForm,
  IReportViaTemplateForm,
} from 'interface';
import { customFetchBase } from '../../fetchBase';

export const reportsAPI = createApi({
  reducerPath: 'reportsAPI',
  tagTypes: ['reports'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getReports: build.query<IPaginationResponse<IReport[]>, IQueryParams>({
      query: ({ page, size, search, type, settlements__name }) => ({
        url: `/cms/reports/?page=${page}&page_size=${size}&search=${search ?? ''}&type=${type ?? ''}&settlement=${
          settlements__name ?? ''
        }`,
      }),
      providesTags: ['reports'],
    }),
    createReport: build.mutation<IReport, IReportForm>({
      query: (data) => ({
        url: `cms/reports/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['reports'],
    }),
    createReportViaTemplate: build.mutation<IReport, IReportViaTemplateForm>({
      query: (data) => ({
        url: `/cms/reports/create/via/template/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['reports'],
    }),
    editReport: build.mutation<IReport, IEditBody<IReportForm>>({
      query: (data) => ({
        url: `cms/reports/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['reports'],
    }),
    deleteReport: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/reports/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['reports'],
    }),
    importFileReports: build.mutation<any, IImportReportForm>({
      query: (data) => ({
        url: `/cms/reports/create/multiply/${data.report_template_id}/`,
        method: 'POST',
        body: data.file,
      }),
      invalidatesTags: ['reports'],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useCreateReportMutation,
  useEditReportMutation,
  useDeleteReportMutation,
  useCreateReportViaTemplateMutation,
  useImportFileReportsMutation,
} = reportsAPI;
