import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  PlotForm,
  PlotsFilters,
  PlotsPagination,
  PlotsTable,
  PlotsTableSorted,
  SettlementInfo,
} from '5-features/plots';
import {
  getPlotsListThunk,
  getStreetsListThunk,
  IPlotsListFilters,
  plotsActions,
  selectPlotsListFilters,
  selectPlotsListPagination,
} from '6-entities/plots';
import { Button, Flex } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { IconPlus } from '7-shared/assets';

export const PlotsList = () => {
  const dispatch = useAppDispatch();

  const { params, pagination } = useQueryParams<IPlotsListFilters>();

  const { id } = useParams();
  const filters = useAppSelector(selectPlotsListFilters);
  const tablePagination = useAppSelector(selectPlotsListPagination);

  const onPressAddHandler = () => {
    dispatch(plotsActions.setPlotsModalState({ open: true }));
  };

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(filters)) {
      dispatch(plotsActions.setListFilters({ ...params }));
    }
  }, [params]);

  useEffect(() => {
    if (tablePagination?.page && id) {
      dispatch(getPlotsListThunk(`${id}`));
      dispatch(getStreetsListThunk(`${id}`));
    }
  }, [tablePagination?.page, filters]);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(plotsActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  useEffect(() => {
    return () => {
      dispatch(
        plotsActions.setListFilters({
          search: undefined,
          street_id: undefined,
          realty_id: undefined,
          ordering: undefined,
        })
      );
    };
  }, []);

  return (
    <Flex direction={'column'} className={'w-full h-full'} gap={10}>
      <SettlementInfo />
      <Button ml={'auto'} variant={'outline'} onClick={onPressAddHandler} rightIcon={<IconPlus size={14} />}>
        Добавить
      </Button>
      <PlotsFilters />
      <PlotsTableSorted />
      <PlotsTable />
      <PlotsPagination />
      <PlotForm />
    </Flex>
  );
};
