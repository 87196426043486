import { createApi } from '@reduxjs/toolkit/query/react';
import { ICompany, ICompanyForm, IEditBody, IPaginationResponse, IQueryParams } from 'interface';
import { customFetchBase } from '../fetchBase';

export const companyAPI = createApi({
  reducerPath: 'companyAPI',
  tagTypes: ['company'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getCompanies: build.query<IPaginationResponse<ICompany[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `cms/companies?page=${page}&page_size=${size}`,
      }),
      providesTags: ['company'],
    }),
    createCompany: build.mutation<ICompany, ICompanyForm>({
      query: (data) => ({
        url: `cms/companies/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['company'],
    }),
    editCompany: build.mutation<ICompany, IEditBody<ICompanyForm>>({
      query: (data) => ({
        url: `cms/companies/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['company'],
    }),
    deleteCompany: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/companies/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['company'],
    }),
  }),
});

export const { useGetCompaniesQuery, useCreateCompanyMutation, useDeleteCompanyMutation, useEditCompanyMutation } =
  companyAPI;
