import React from 'react';
import { Control, FieldValues, PathValue, Path, useController } from 'react-hook-form';
import { Checkbox as MantineCheckbox, CheckboxProps } from '@mantine/core';

interface IProps<T extends FieldValues> extends Omit<CheckboxProps, 'checked' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
  classNames?: any;
}

export const Checkbox = <T extends FieldValues>({
  control,
  name,
  classNames = {},
  defaultValue,
  label,
  ...rest
}: IProps<T>) => {
  const {
    field: { onChange, value },
  } = useController<T>({
    name,
    control,
    defaultValue,
  });
  return <MantineCheckbox classNames={classNames} checked={value} label={label} onChange={onChange} {...rest} />;
};
