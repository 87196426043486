import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';
import { IFilters, ITechnicalSupportDto } from './types';
import { AxiosResponse } from 'axios';

export const technicalSupportApi = {
  getTechnicalSupportList(
    page: number,
    page_size: number,
    filters?: IFilters
  ): Promise<IPaginationResponse<ITechnicalSupportDto>> {
    return instance
      .get('cms/feedback', {
        params: { page, page_size, ...filters },
      })
      .then((res) => res.data);
  },
  getTechnicalSupportReport(filters?: IFilters) {
    return instance
      .get('cms/feedback/report/', {
        params: { ...filters },
        responseType: 'blob',
      })
      .then(async (res: AxiosResponse<Blob>) => window.location.assign(window.URL.createObjectURL(await res.data)));
  },
};
