import React from 'react';
import { IconDots, IconPencil, IconTrash } from '7-shared/assets';
import { ActionIcon, DeleteModal, Menu } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useModal } from '7-shared/hooks';
import { notification } from '7-shared/lib/notification';
import {
  deleteTemplateReportsThunk,
  ITemplateReports,
  reportsActions,
  selectReportsTemplatesModalState,
} from '6-entities/reports';

interface IProps {
  record: ITemplateReports;
}

export const TemplatesTableActions = ({ record }: IProps) => {
  const dispatch = useAppDispatch();
  const { isShown, toggle: toogleDeleteModal } = useModal();
  const { isLoading } = useAppSelector(selectReportsTemplatesModalState);

  const deleteSettlementHandler = () => {
    dispatch(deleteTemplateReportsThunk(`${record.id}`))
      .unwrap()
      .then(() => toogleDeleteModal())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Удаление отчета запрещено',
        });
      });
  };

  const onEditHandler = () => {
    dispatch(reportsActions.setReportsModalTemplatesState({ open: true, currentItem: record }));
  };

  return (
    <>
      <DeleteModal
        deleteText={'Вы уверены что хотитет удалить шаблон?'}
        onClose={toogleDeleteModal}
        loading={isLoading}
        open={isShown}
        onConfirm={deleteSettlementHandler}
      />
      <Menu shadow="md" width={180}>
        <Menu.Target>
          <ActionIcon>
            <IconDots size={22} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item icon={<IconPencil size={16} />} onClick={onEditHandler}>
            Редактировать
          </Menu.Item>
          <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={toogleDeleteModal}>
            Удалить
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
