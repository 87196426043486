import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, ISpecialContact, ISpecialContactForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const specialContactsAPI = createApi({
  reducerPath: 'specialContactsAPI',
  tagTypes: ['contacts'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getSpecialContacts: build.query<IPaginationResponse<ISpecialContact[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/feedback-urgent-contacts/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['contacts'],
    }),
    createSpecialContacts: build.mutation<ISpecialContact, ISpecialContactForm>({
      query: (data) => ({
        url: `/cms/feedback-urgent-contacts/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['contacts'],
    }),
    editSpecialContacts: build.mutation<ISpecialContact, IEditBody<ISpecialContactForm>>({
      query: (data) => ({
        url: `/cms/feedback-urgent-contacts/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['contacts'],
    }),
    deleteSpecialContacts: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/feedback-urgent-contacts/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['contacts'],
    }),
  }),
});

export const {
  useDeleteSpecialContactsMutation,
  useCreateSpecialContactsMutation,
  useEditSpecialContactsMutation,
  useGetSpecialContactsQuery,
} = specialContactsAPI;
