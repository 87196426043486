import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IBuilderSupport,
  IBuilderSupportInfoForm,
  IBuilderSupportQueryParams,
  IBuilderSupportReplace,
  IEditBody,
  IPaginationResponse,
  IQueryParams,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const builderSupportApi = createApi({
  reducerPath: 'builderSupportApi',
  tagTypes: ['builderSupportList', 'builderSupportListItem'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getBuilderSupportItem: build.query<IBuilderSupport, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/feedback-builder/${id}`,
      }),
      providesTags: ['builderSupportListItem'],
    }),
    createBuilderSupportComment: build.mutation<IBuilderSupport, IEditBody<IBuilderSupportInfoForm>>({
      query: (data) => ({
        url: `cms/feedback-builder/response/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['builderSupportList', 'builderSupportListItem'],
    }),
    replaceBuilderSupportItem: build.mutation<IBuilderSupport, IEditBody<IBuilderSupportReplace>>({
      query: (data) => ({
        url: `cms/feedback-any/redirect/${data.id}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: ['builderSupportList'],
    }),
  }),
});

export const {
  useCreateBuilderSupportCommentMutation,
  useGetBuilderSupportItemQuery,
  useReplaceBuilderSupportItemMutation,
} = builderSupportApi;
