import React from 'react';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';
import { columns } from '../../model/settlementsListColumns';
import { selectSettlementsListTableData } from '6-entities/settlement';

export const SettlementsTable = () => {
  const tableData = useAppSelector(selectSettlementsListTableData);
  return <Table columns={columns} rows={tableData.data} tableLoading={tableData.isLoading} />;
};
