import { Select as MantineSelect, type SelectProps } from '@mantine/core';
import React from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';

interface IProps<T extends FieldValues> extends Omit<SelectProps, 'value' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
}

export const Select = <T extends FieldValues>({ name, control, defaultValue, onChange, ...rest }: IProps<T>) => {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
  });

  const onChangeHandler = (value: string) => {
    fieldOnChange(value);
    onChange?.(value);
  };

  return (
    <MantineSelect value={value} onChange={onChangeHandler} error={fieldState.error?.message} {...field} {...rest} />
  );
};
