import { IUser } from './users.interface';
import { IPaginationQuery } from './app.interface';
import { IUserAddress } from '../6-entities/builderSupport/api/types';

export interface IBuilderSupport {
  status: BUILDER_SUPPORT_STATUS;
  comments: IBuilderSupportComment[];
  email: string;
  id: number;
  message: string;
  time_created: string;
  time_updated: string;
  user: IUser;
  guest_data: IBuilderGuestUserData;
  media: IBuilderSupportMedia[];
  user_address: IUserAddress;
  chat_id: number;
  score: number;
}

export enum BUILDER_SUPPORT_STATUS {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  WAITING_RESPONSE = 'waiting_response',
  REDIRECTED = 'redirected',
  CLOSED = 'closed',
  RETURNED_TO_WORK = 'returned_to_work',
}

export interface IBuilderSupportComment {
  comment: string;
  current_status: BUILDER_SUPPORT_STATUS;
  previous_status: BUILDER_SUPPORT_STATUS;
  time_created: string;
}

export interface IBuilderSupportMedia {
  file: string;
}
export interface IBuilderGuestUserData {
  id: number;
  full_name: string;
  phone: string;
  address: string;
}

export interface IBuilderSupportInfoForm {
  comment?: string;
  status: BUILDER_SUPPORT_STATUS;
  detail?: string;
}

export interface IBuilderSupportReplace {
  current_type: 'builder';
  target_type: 'communal' | 'support';
}

export interface IBuilderSupportReplaceForm {
  status: 'support' | 'communal';
  detail?: string;
}

export type IBuilderSupportFilters = Readonly<'search' | 'status' | 'settlements__name' | 'page' | 'size'>;
export type IBuilderSupportQueryParams = IBuilderSupportFilters | IPaginationQuery;
