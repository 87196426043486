import React from 'react';
import { Tooltip, Flex } from '7-shared/ui';
import { SettlementsTableActions } from '../ui/settlementsTableActions/settlementsTableActions';
import { ISettlement } from '6-entities/settlement';
import { Link } from 'react-router-dom';
import { routes } from '7-shared/lib/routes';
import { IconMapPin } from '7-shared/assets';

export interface SettlemtsColumnTable {
  accessor: keyof ISettlement;
  title?: string | JSX.Element;
  render?: (value: ISettlement, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: SettlemtsColumnTable[] = [
  {
    accessor: 'name',
    title: 'Название',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value.name}`}>
        <Link
          to={`${routes.settlenent.root}/${value.id}/plots`}
          className={'text-16 font-600 text-black max-w-[600px]'}
        >
          {value.name}
        </Link>
      </Tooltip>
    ),
    width: 300,
    ellipsis: true,
  },
  {
    accessor: 'address',
    title: 'Адрес',
    render: (value, index) => (
      <Flex>
        <IconMapPin stroke={'white'} />
        <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value.address}`}>
          {value.address}
        </Tooltip>
      </Flex>
    ),
    width: 500,
    ellipsis: true,
  },
  {
    accessor: 'settings',
    title: '',
    render: (value) => <SettlementsTableActions record={value} />,
    width: 100,
    ellipsis: true,
  },
];
