import { TextInput, type TextInputProps } from '@mantine/core';
import React, { ChangeEvent } from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';

interface IProps<T extends FieldValues> extends Omit<TextInputProps, 'value' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
  regExp?: RegExp;
}

export const Input = <T extends FieldValues>({ name, control, defaultValue, regExp, ...rest }: IProps<T>) => {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
  });

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ?? '';
    if (regExp && !regExp.test(value)) {
      return;
    }
    fieldOnChange(e);
  };

  return (
    <TextInput
      value={value?.trimStart() ?? ''}
      onChange={onChangeHandler}
      error={fieldState.error?.message}
      {...rest}
      {...field}
    />
  );
};
