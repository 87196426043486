import { Box, Button, Flex, Modal } from '../../index';
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (data: any) => void;
  image?: string;
  shape?: 'rect' | 'round';
  aspect: number;
}

//TODO refactor this component
export const ImgCrop = ({ visible, onOk, onCancel, image, aspect = 1 / 1, shape }: IProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCloseCrop = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    onCancel();
  };

  const saveCroppedImage = useCallback(async () => {
    if (image)
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        onOk(croppedImage);
      } catch (e) {
        console.error(e);
      }
  }, [image, croppedAreaPixels]);
  if (!visible) {
    return null;
  }
  return (
    <Modal onClose={onCancel} opened={visible} title={'Редактирование изображения'}>
      <Flex direction={'column'} className={'h-[500px]'}>
        <Box className={'h-[400px]'}>
          <Cropper
            cropShape={shape}
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Flex>
          <Button variant={'outline'} onClick={onCloseCrop} size={'md'} type={'button'} className={'mt-[15px] w-full'}>
            Отмена
          </Button>
          <Button onClick={saveCroppedImage} size={'md'} type={'button'} className={'mt-[15px] w-full bg-[#228be6]'}>
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize((image as any).width, (image as any).height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-(image as any).width / 2, -(image as any).height / 2);

  // draw rotated image
  ctx.drawImage(image as any, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  return canvas.toDataURL('image/jpeg');
}
