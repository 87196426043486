import React from 'react';
import { IPlotsListFilters, plotsActions } from '6-entities/plots';
import { Flex, TableSort } from '7-shared/ui';
import { useAppDispatch, useQueryParams } from '7-shared/hooks';

export const PlotsTableSorted = () => {
  const dispatch = useAppDispatch();

  const { onChange, onClear, params } = useQueryParams<IPlotsListFilters>();

  const onChangeFilter = (key: keyof IPlotsListFilters, value: string) => {
    onChange(key, value);
    dispatch(plotsActions.setListPagination({ page: 1 }));
    dispatch(plotsActions.setListFilters({ ...params, [key]: value }));
  };

  const onClearFilter = (key: keyof IPlotsListFilters) => {
    onClear(key);
    dispatch(plotsActions.setListPagination({ page: 1 }));
    dispatch(plotsActions.setListFilters({ ...params, [key]: undefined }));
  };

  const sortSchema = {
    name: 'ordering',
    value: params?.ordering,
    values: [
      { key: 'number', title: 'По номеру земельного участка', sort: '', isChecked: true },
      { key: 'street', title: 'По наименованию улицы', sort: '', isChecked: false },
    ],
    onChange: onChangeFilter,
    onClear: onClearFilter,
  };

  return (
    <Flex justify={'flex-end'}>
      <TableSort sortSchema={sortSchema} />
    </Flex>
  );
};
