import { IUser } from './users.interface';
import { IPaginationQuery, ITimeStamps } from './app.interface';
import { IUserAddress } from '../6-entities/technicalSupport/api/types';

export interface ITechnicalSupport {
  status: TECHNICAL_SUPPORT_STATUS;
  comments: ITechnicalSupportComment[];
  email: string;
  id: number;
  message: string;
  time_created: string;
  time_updated: string;
  user: IUser;
  guest_data: IGuestUserData;
  media: ITechnicalSupportMedia[];
  user_address: IUserAddress;
  chat_id: number;
  score: number;
}

export enum TECHNICAL_SUPPORT_STATUS {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  WAITING_RESPONSE = 'waiting_response',
  REDIRECTED = 'redirected',
  CLOSED = 'closed',
  RETURNED_TO_WORK = 'returned_to_work',
}

export interface ITechnicalSupportComment {
  comment: string;
  current_status: TECHNICAL_SUPPORT_STATUS;
  previous_status: TECHNICAL_SUPPORT_STATUS;
  time_created: string;
}

export interface ITechnicalSupportMedia {
  file: string;
}

export interface IGuestUserData {
  id: number;
  full_name: string;
  phone: string;
  address: string;
}

export interface ITechnicalSupportInfoForm {
  comment?: string;
  status: TECHNICAL_SUPPORT_STATUS;
  detail?: string;
}

export interface ITechnicalSupportReplaceForm {
  status: 'communal' | 'builder';
  detail?: string;
}

export interface ITechnicalSupportReplace {
  current_type: 'support';
  target_type: 'communal' | 'builder';
}

export type ITechnicalSupportFilters = Readonly<'search' | 'status' | 'settlements__name' | 'page' | 'size'>;
export type ITechnicalSupportQueryParams = ITechnicalSupportFilters | IPaginationQuery;

export interface ITechnicalTemplate extends ITimeStamps {
  id: number;
  text: string;
  title: string;
}

export interface ITechnicalTemplateForm {
  type: 'communal' | 'builder' | 'support';
  text: string;
  title: string;
}

type IQueryTemplateParams = 'type';

export type ITechnicalTemplateQueryParams = IPaginationQuery | IQueryTemplateParams;
