import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';
import { ICamera, ICameraForm } from './types';

export const camerasApi = {
  getCameras(id: number, page: number, page_size?: number): Promise<IPaginationResponse<ICamera>> {
    return instance
      .get(`/cms/camera-list/${id}/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  createCamera(data: ICameraForm) {
    return instance.post(`/cms/cameras/`, data).then((res) => res.data);
  },
  editCamera(id: number, data: ICameraForm) {
    return instance.put(`/cms/cameras/${id}/`, data).then((res) => res.data);
  },
  deleteCamera(id: number) {
    return instance.delete(`cms/cameras/${id}/`).then((res) => res.data);
  },
  startStream(id: number) {
    return instance.get(`/cms/cameras/start-stream/${id}/`).then((res) => res.data);
  },
  stopStream(id: number) {
    return instance.get(`/cms/cameras/stop-stream/${id}/`).then((res) => res.data);
  },
  pauseStream(id: number) {
    return instance.put(`/cms/cameras/pausa/${id}/`, {}).then((res) => res.data);
  },
};
