import React from 'react';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';
import { columns } from '../../model/reportListColumns';
import { selectReportsListTableData, REPORT_TYPE } from '6-entities/reports';

export const ReportsTable = <R extends object>() => {
  const tableData = useAppSelector(selectReportsListTableData);
  const rows = tableData.data;
  return (
    <Table
      rowStyle={({ type }): Record<string, string> | undefined =>
        type === REPORT_TYPE.personal || type === REPORT_TYPE.template ? { backgroundColor: '#ffe8bc' } : undefined
      }
      noHeader={true}
      columns={columns}
      rows={rows}
      tableLoading={tableData.isLoading}
    />
  );
};
