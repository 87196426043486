import React from 'react';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { createConfigThunk, selectConfigServiceSupport } from '6-entities/app';
import { columnsList } from '../../model/columnsList';
import { TableColumnsSettings } from '7-shared/ui';

export const ServiceSupportSettings = () => {
  const dispatch = useAppDispatch();

  const tableSettings = useAppSelector(selectConfigServiceSupport);

  const changeActiveColumnHandler = (keys: string[]) => {
    dispatch(
      createConfigThunk({
        type: 'titles_feedback_communal',
        values: keys,
      })
    );
  };

  return (
    <TableColumnsSettings
      applySettings={changeActiveColumnHandler}
      activeColumns={tableSettings}
      columnsList={columnsList}
    />
  );
};
