import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserInformation } from 'interface';
import { AUTH_TOKEN } from 'shared/constants';
import { localStorageUtils } from 'shared/utils';
import { authAPI } from '../auth';

const initialState = {
  user: {} as IUserInformation,
  isAuth: false as boolean,
  isInit: false as boolean,
  isTechnicalSupportDot: false as boolean,
  isServiceSupportDot: false as boolean,
};
const appSlice = createSlice({
  name: `app`,
  initialState,
  reducers: {
    setIsInit: (state, action: PayloadAction<boolean>) => {
      state.isInit = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authAPI.endpoints.login.matchFulfilled, (state, { payload }) => {
      localStorageUtils.setValue(AUTH_TOKEN.access, payload.access_token);
      state.isAuth = true;
    });
    builder.addMatcher(authAPI.endpoints.getUserInfo.matchFulfilled, (state, { payload }) => {
      state.user = payload;
    });
    builder.addMatcher(authAPI.endpoints.authMe.matchFulfilled, (state) => {
      state.isAuth = true;
    });
    builder.addMatcher(authAPI.endpoints.logout.matchFulfilled, (state) => {
      state.isAuth = false;
    });
  },
});

export const { setIsInit, setIsAuth } = appSlice.actions;
export const getIsInit = (state: RootState) => state.app.isInit;
export const getIsAuth = (state: RootState) => state.app.isAuth;
export const getUserInfo = (state: RootState) => state.app.user;

export default appSlice.reducer;
