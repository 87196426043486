import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, INotification, INotificationForm, IPaginationResponse, IQueryParams } from 'interface';
import { customFetchBase } from '../fetchBase';

export const notificationsAPI = createApi({
  reducerPath: 'notificationsAPI',
  tagTypes: ['notifications', 'currentNotification'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getNotifications: build.query<IPaginationResponse<INotification[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/notices/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['notifications'],
    }),
    getCurrentNotification: build.query<INotification, string | undefined>({
      query: (id) => ({
        url: `cms/notices/${id}`,
      }),
      providesTags: ['notifications', 'currentNotification'],
    }),
    createNotification: build.mutation<INotification, INotificationForm>({
      query: (data) => ({
        url: `cms/notices/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['notifications'],
    }),
    editNotification: build.mutation<INotification, IEditBody<INotificationForm>>({
      query: (data) => ({
        url: `cms/notices/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['notifications', 'currentNotification'],
    }),
    deleteNotification: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/notices/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});

export const {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useEditNotificationMutation,
  useGetCurrentNotificationQuery,
  useGetNotificationsQuery,
} = notificationsAPI;
