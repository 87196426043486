import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalState } from '7-shared/types';
import { IUser } from '../model/types';

interface IInitialState {
  modal: IModalState<IUser>;
  isVisibleConfirmingModal: boolean;
}

const initialState: IInitialState = {
  modal: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
  isVisibleConfirmingModal: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersModalState: (state, action: PayloadAction<Partial<IModalState<IUser>>>) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setIsVisibleConfirmingModalState: (state, action: PayloadAction<boolean>) => {
      state.isVisibleConfirmingModal = action.payload;
    },
  },
});

export const selectUsersModalState = (state: RootState) => state.users.modal;
export const selectIsVisibleConfirmingModalState = (state: RootState) => state.users.isVisibleConfirmingModal;

export const usersActions = usersSlice.actions;
