import * as yup from 'yup';
import { validation } from '7-shared/constants';

export const plotSchema = yup.object().shape({
  number: yup.string().max(18, `${validation.max} 18`).required(`${validation.required}`),
  account: yup.string().nullable().max(200, `${validation.max} 200`),
  street_id: yup.string().nullable(),
  realty_number: yup
    .string()
    .nullable()
    .when('street_id', {
      is: (value: string) => !!value,
      then: (schema) => schema.required(`${validation.required}`),
    }),
  realty_corpus: yup.string().nullable().max(200, `${validation.max} 200`),
});
