import {
  ACCESS_SYSTEM_STATUSES,
  BUILDER_SUPPORT_STATUS,
  ROLES,
  SERVICE_SUPPORT_STATUS,
  TECHNICAL_SUPPORT_STATUS,
} from 'interface';

export const filters_names = {
  search: 'search',
  role: 'role',
  accepted: 'accepted',
  status: 'status',
  type: 'type',
  settlements_name: 'settlements__name',
};

export const filters_placeholder = {
  search_all: 'Поиск (ФИО, email или номер телефона)',
  search_users: 'Поиск (ФИО или номер телефона)',
  search_advertising: 'Поиск (имя организации или номер телефона)',
  search_reports: 'Поиск (Название или адрес)',
  role: 'Роль',
  settlements_name: 'КП',
  status: 'Статус',
  report_type: 'Тип отчета',
};

export const filter_types = {
  input: 'input',
  select: 'select',
};

export const users_roles_filters = [
  { value: ROLES.CLIENT, label: 'Клиент' },
  { value: ROLES.OWNER, label: 'Житель' },
  { value: ROLES.GUEST, label: 'Гость' },
];

export const service_support_statuses = [
  { value: SERVICE_SUPPORT_STATUS.NEW, label: 'Новая', color: 'red' },
  { value: SERVICE_SUPPORT_STATUS.IN_PROGRESS, label: 'В работе', color: 'blue' },
  { value: SERVICE_SUPPORT_STATUS.RETURNED_TO_WORK, label: 'Возвращена в работу', color: 'yellow' },
  { value: SERVICE_SUPPORT_STATUS.WAITING_RESPONSE, label: 'Ждет уточнения', color: 'orange' },
  { value: SERVICE_SUPPORT_STATUS.CLOSED, label: 'Закрыта', color: 'green' },
  { value: SERVICE_SUPPORT_STATUS.REDIRECTED, label: 'Перенаправлена', color: 'violet' },
];
export const technical_support_statuses = [
  { value: TECHNICAL_SUPPORT_STATUS.NEW, label: 'Новая', color: 'red' },
  { value: TECHNICAL_SUPPORT_STATUS.IN_PROGRESS, label: 'В работе', color: 'blue' },
  { value: TECHNICAL_SUPPORT_STATUS.RETURNED_TO_WORK, label: 'Возвращена в работу', color: 'yellow' },
  { value: TECHNICAL_SUPPORT_STATUS.WAITING_RESPONSE, label: 'Ждет уточнения', color: 'orange' },
  { value: TECHNICAL_SUPPORT_STATUS.CLOSED, label: 'Закрыта', color: 'green' },
  { value: TECHNICAL_SUPPORT_STATUS.REDIRECTED, label: 'Перенаправлена', color: 'violet' },
];
export const builder_support_statuses = [
  { value: BUILDER_SUPPORT_STATUS.NEW, label: 'Новая', color: 'red' },
  { value: BUILDER_SUPPORT_STATUS.IN_PROGRESS, label: 'В работе', color: 'blue' },
  { value: BUILDER_SUPPORT_STATUS.RETURNED_TO_WORK, label: 'Возвращена в работу', color: 'yellow' },
  { value: BUILDER_SUPPORT_STATUS.WAITING_RESPONSE, label: 'Ждет уточнения', color: 'orange' },
  { value: BUILDER_SUPPORT_STATUS.CLOSED, label: 'Закрыта', color: 'green' },
  { value: BUILDER_SUPPORT_STATUS.REDIRECTED, label: 'Перенаправлена', color: 'violet' },
];

export const advertising_statuses = [
  { value: 'access_display', label: 'Доступен' },
  { value: 'prolong_display', label: 'Требует продления' },
  { value: 'no_display', label: 'Снят с показа' },
];

export const masters_statuses = [
  { value: 'access_display', label: 'Доступен' },
  { value: 'prolong_display', label: 'Требует продления' },
  { value: 'no_display', label: 'Снят с показа' },
];
export const partners_statuses = [
  { value: 'access_display', label: 'Доступен' },
  { value: 'prolong_display', label: 'Требует продления' },
  { value: 'no_display', label: 'Снят с показа' },
];
export const services_statuses = [
  { value: 'access_display', label: 'Доступен' },
  { value: 'prolong_display', label: 'Требует продления' },
  { value: 'no_display', label: 'Снят с показа' },
];
export const access_system_statuses = [
  { value: ACCESS_SYSTEM_STATUSES.CANCELED, label: 'Отменен' },
  { value: ACCESS_SYSTEM_STATUSES.ACTIVE, label: 'Активен' },
  { value: ACCESS_SYSTEM_STATUSES.ON_TERRITORY, label: 'На территории' },
  { value: ACCESS_SYSTEM_STATUSES.PERFORMED, label: 'Исполнен' },
  { value: ACCESS_SYSTEM_STATUSES.EXPIRED, label: 'Просрочен' },
];
