import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Mutex } from 'async-mutex';
import { AUTH_TOKEN, BASE_URL } from 'shared/constants';
import { largePayloadErrorHandler, localStorageUtils, noAccessErrorHandler } from 'shared/utils';
import Cookies from 'universal-cookie';
import { setIsAuth } from './app';

const baseURL = BASE_URL;
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers) => {
    headers.set('withCredentials', 'true');
    return headers;
  },
});
export const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if ((result.error?.status as any) === 413) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      largePayloadErrorHandler((result.error?.data as any)?.detail);
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  if ((result.error?.status as any) === 403) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      noAccessErrorHandler((result.error?.data as any)?.detail);
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  if ((result.error?.status as any) === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const cookies = new Cookies();
        const refreshToken = cookies.get(AUTH_TOKEN.refresh);
        const refreshResult: any = await baseQuery(
          { url: `/token/refresh/`, method: 'POST', body: { refresh: refreshToken } },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          localStorageUtils.setValue(AUTH_TOKEN.access, refreshResult?.data?.access);
          cookies.set(AUTH_TOKEN.access, refreshResult?.data?.access, { path: '/' });
          cookies.set(AUTH_TOKEN.refresh, refreshResult?.data?.refresh, { path: '/' });
          api.dispatch(setIsAuth(true));
          result = await baseQuery(args, api, extraOptions);
        } else {
          localStorageUtils.removeValue(AUTH_TOKEN.access);
          cookies.remove(AUTH_TOKEN.access);
          cookies.remove(AUTH_TOKEN.refresh);
        }
      } finally {
        await release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
