import { createStyles } from '@mantine/core';

interface Iprops {
  wholeScreen: boolean;
}

export const useStyles = createStyles((theme, { wholeScreen }: Iprops) => {
  const width = wholeScreen ? '100%' : 'min-content';

  return {
    root: {
      width: width,
      textAlign: 'left',
      'td:last-child': {
        textAlign: 'left',
      },
    },
  };
});
