import { createAsyncThunk } from '@reduxjs/toolkit';
import { settlementsApi } from '../api/settlements.api';
import { settlementsActions } from './settlements.slice';
import { ISettlementForm } from '../api/types';
import { ICommonErrorReject, IRejectRequest } from '7-shared/api/types';
import { mapSettlementList } from '../lib/mapSettlementList';

export const getSettlementListThunk = createAsyncThunk<void, void, { state: RootState }>(
  'settlements/getSettlement',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(settlementsActions.setListTableData({ isLoading: true }));
      const pagination = getState().settlements.pagination;
      const result = await settlementsApi.getSettlement(pagination.page, pagination.page_size);
      const listStreet = result.results.map((i) => mapSettlementList(i));
      dispatch(settlementsActions.setListTableData({ data: listStreet }));
      dispatch(settlementsActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(settlementsActions.setListTableData({ isLoading: false }));
    }
  }
);
export const createSettlementThunk = createAsyncThunk<void, ISettlementForm, { state: RootState }>(
  'settlements/createSettlement',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(settlementsActions.setListTableData({ isLoading: true }));
      await settlementsApi.createSettlement(data);
    } catch (error) {
      return rejectWithValue((error as IRejectRequest<ISettlementForm>)?.response?.data);
    } finally {
      dispatch(settlementsActions.setListTableData({ isLoading: false }));
    }
  }
);

export const editSettlementThunk = createAsyncThunk<void, { id: number; data: ISettlementForm }, { state: RootState }>(
  'settlements/editSettlement',
  async ({ id, data }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(settlementsActions.setListTableData({ isLoading: true }));
      await settlementsApi.editSettlement(id, data);
    } catch (error) {
      return rejectWithValue((error as IRejectRequest<ISettlementForm>)?.response?.data);
    } finally {
      dispatch(settlementsActions.setListTableData({ isLoading: false }));
    }
  }
);
export const deleteSettlementThunk = createAsyncThunk<void, number, { state: RootState }>(
  'settlements/deleteSettlement',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(settlementsActions.setListTableData({ isLoading: true }));
      await settlementsApi.deleteSettlement(id);
      dispatch(getSettlementListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(settlementsActions.setListTableData({ isLoading: false }));
    }
  }
);
