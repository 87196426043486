import { instance } from '../../index';
import { IPaginationResponse } from '../../../types';
import { IRealtyDto, ISettlementDto, IStreetsDto } from './types';

export const adresesApi = {
  getSettlement(page: number = 1, page_size: number = 100): Promise<IPaginationResponse<ISettlementDto>> {
    return instance
      .get(`cms/settlements/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  getStreetList(id: string, page: number = 1, page_size: number = 100): Promise<IPaginationResponse<IStreetsDto>> {
    return instance
      .get(`cms/street-list/${id}/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  getRealtyList(id: string, page: number = 1, page_size: number = 100): Promise<IPaginationResponse<IRealtyDto>> {
    return instance
      .get(`cms/realty_list/${id}/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
};
