import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';
import { IVideo, IVideoForm } from './types';

export const videosApi = {
  getVideos(page: number, page_size?: number): Promise<IPaginationResponse<IVideo>> {
    return instance
      .get(`/cms/video/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  getCurrentVideo(id: number): Promise<IVideo> {
    return instance.get(`/cms/video/${id}`).then((res) => res.data);
  },
  createVideo(data: IVideoForm) {
    return instance.post(`/cms/video/`, data).then((res) => res.data);
  },
  editVideo(id: number, data: IVideoForm) {
    return instance.put(`/cms/video/${id}/`, data).then((res) => res.data);
  },
  deleteVideo(id: number) {
    return instance.delete(`cms/video/${id}/`).then((res) => res.data);
  },
};
