import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapVideosList } from '../lib/mapVideosList';
import { videosActions } from '../model/videos.slice';
import { ICommonErrorReject } from '7-shared/api/types';
import { videosApi } from '../api/videos.api';
import { IVideoForm } from '../api/types';

export const getVideosListThunk = createAsyncThunk<void, void, { state: RootState }>(
  'videos/getVideos',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setListTableData({ isLoading: true }));
      const pagination = getState().videos.pagination;
      const result = await videosApi.getVideos(pagination.page, pagination.page_size);
      const list = result.results.map((i) => mapVideosList(i));
      dispatch(videosActions.setListTableData({ data: result.results }));
      dispatch(videosActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(videosActions.setListTableData({ isLoading: false }));
    }
  }
);

export const editVideoThunk = createAsyncThunk<void, { id: number; data: IVideoForm }, { state: RootState }>(
  'videos/editVideo',
  async ({ id, data }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(videosActions.setVideosModalState({ isLoading: true }));
      await videosApi.editVideo(id, data);
      dispatch(getVideosListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setVideosModalState({ isLoading: false }));
    }
  }
);
export const createVideoThunk = createAsyncThunk<void, IVideoForm, { state: RootState }>(
  'videos/createVideo',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(videosActions.setVideosModalState({ isLoading: true }));
      await videosApi.createVideo(data);
      dispatch(getVideosListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setVideosModalState({ isLoading: false }));
    }
  }
);
export const deleteVideoThunk = createAsyncThunk<void, number, { state: RootState }>(
  'videos/deleteVideo',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(videosActions.setListTableData({ isLoading: true }));
      await videosApi.deleteVideo(id);
      dispatch(getVideosListThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setListTableData({ isLoading: false }));
    }
  }
);
