import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IEvent, IEventForm, IPaginationResponse, IQueryParams } from 'interface';
import { customFetchBase } from '../fetchBase';

export const eventAPI = createApi({
  reducerPath: 'eventAPI',
  tagTypes: ['events', 'currentEvent'],
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getEvents: build.query<IPaginationResponse<IEvent[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/events/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['events'],
    }),
    getCurrentEvent: build.query<IEvent, string | undefined>({
      query: (id) => ({
        url: `cms/events/${id}`,
      }),
      providesTags: ['events', 'currentEvent'],
    }),
    createEvent: build.mutation<IEvent, IEventForm>({
      query: (data) => ({
        url: `cms/events/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['events'],
    }),
    editEvent: build.mutation<IEvent, IEditBody<IEventForm>>({
      query: (data) => ({
        url: `cms/events/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['events', 'currentEvent'],
    }),
    deleteEvent: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/events/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['events'],
    }),
  }),
});

export const {
  useCreateEventMutation,
  useDeleteEventMutation,
  useEditEventMutation,
  useGetCurrentEventQuery,
  useGetEventsQuery,
} = eventAPI;
