import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalState, ITablePagination, ITableState } from '7-shared/types';
import { ITemplatesDto } from './types';

interface IInitialState {
  table: ITableState<ITemplatesDto>;
  pagination: ITablePagination;
  modal: IModalState<ITemplatesDto>;
}

const initialState: IInitialState = {
  table: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 0,
    total_count: 0,
    page_size: 10,
  },
  modal: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
};

export const templatesSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<ITemplatesDto>>>) => {
      state.table = { ...state.table, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setTemplateModalState: (state, action: PayloadAction<Partial<IModalState<ITemplatesDto>>>) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setInitialTechnicalTemplateState: () => initialState,
  },
});

export const selectTemplateListTableData = (state: RootState) => state.template.table;
export const selectTemplateListPagination = (state: RootState) => state.template.pagination;
export const selectTemplateModalState = (state: RootState) => state.template.modal;

export const templatesActions = templatesSlice.actions;
