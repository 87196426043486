import { BUILDER_SUPPORT_STATUS } from '6-entities/builderSupport';
import { ROLES } from '7-shared/accesses';

export const builder_support_statuses = [
  { value: BUILDER_SUPPORT_STATUS.NEW, label: 'Новая', color: 'red' },
  { value: BUILDER_SUPPORT_STATUS.IN_PROGRESS, label: 'В работе', color: 'blue' },
  { value: BUILDER_SUPPORT_STATUS.RETURNED_TO_WORK, label: 'Возвращена в работу', color: 'yellow' },
  { value: BUILDER_SUPPORT_STATUS.WAITING_RESPONSE, label: 'Ждет уточнения', color: 'orange' },
  { value: BUILDER_SUPPORT_STATUS.CLOSED, label: 'Закрыта', color: 'green' },
  { value: BUILDER_SUPPORT_STATUS.REDIRECTED, label: 'Перенаправлена', color: 'violet' },
];
export const builder_support_role = [
  { value: ROLES.CLIENT, label: 'Клиент' },
  { value: ROLES.OWNER, label: 'Житель' },
  { value: ROLES.GUEST, label: 'Гость' },
];
export const builder_support_score = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];
