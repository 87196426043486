import React from 'react';
import { Button, Modal } from '7-shared/ui';

interface IError {
  link: string;
  realty: string;
  corpus: string;
  settlement: string;
  street: string;
}
interface IProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

export const ErrorImport = ({ data, open, onClose }: IProps) => {
  const errorField = data?.detail?.errors?.map((el: any) => el[0]);
  const tableData = data?.detail?.errors?.map((el: any) => el[1]);
  const checkError = (index: number, cellName: string) => {
    if (errorField?.[index]?.includes(cellName)) {
      return 'text-red-600';
    }
    return '';
  };
  const header = ['КП', 'улица', 'Дом', 'Корпус', 'Ссылка'];
  return (
    <Modal size={'auto'} onClose={onClose} opened={open} title={'Ошибки загрузки даннных'}>
      <div className={'w-[800px] flex justify-center mt-[40px]'}>
        <table className={'w-[800px]  text-left font-500'}>
          <thead>
            <tr>
              {header?.map((el, index) => (
                <th key={index} scope={'col'} className={'text-[14px]  text-center pb-[20px] first:text-left font-500'}>
                  {el}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((el: IError, index: number) => (
              <tr key={index} className={'border-b border-black_text_opacity_20 text-center'}>
                <td className={`${checkError(index, 'settlement')}`}>{el?.settlement}</td>
                <td className={`${checkError(index, 'street')}`}>{el?.street}</td>
                <td className={`${checkError(index, 'realty')}`}>{el?.realty}</td>
                <td className={`${checkError(index, 'corpus')}`}>{el?.corpus}</td>
                <td className={`${checkError(index, 'link')}`}>{el?.link}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={'flex gap-5 mt-10 px-[100px]'}>
        <a
          href={data.detail.report_file}
          className={
            'px-[20px] text-center text-black block w-full hover:text-black flex justify-center items-center bg-red-500 rounded bg-opacity-50 rounded-[10px]'
          }
        >
          {' Выгрузить таблицу с ошибками в Exсel'}
        </a>
        <Button variant="outline" onClick={onClose} className={'w-full'}>
          {'OK'}
        </Button>
      </div>
    </Modal>
  );
};
