import { createApi } from '@reduxjs/toolkit/query/react';
import { IChatMessage, IPaginationResponse } from 'interface';
import { AUTH_TOKEN } from 'shared/constants';
import { localStorageUtils } from 'shared/utils';
import { customFetchBase } from '../fetchBase';
import { showNotification } from '@mantine/notifications';

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getChatMessages: build.query<IPaginationResponse<IChatMessage[]>, number>({
      query: (id) => ({
        url: `cms/chat-messages/${id}/?page=1&page_size=10000`,
      }),
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const accessToken = localStorageUtils.getValue(AUTH_TOKEN.access);
        await cacheDataLoaded;
        const ws = new WebSocket(`${process.env?.REACT_APP_WEBSOCKETS_URL}/${arg}/?token=${accessToken}`);
        try {
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            updateCachedData((draft) => {
              draft.results.push(data.data);
            });
          };
          ws.addEventListener('message', listener);
        } catch {
          showNotification({
            color: 'red',
            title: 'Ошибка',
            message: 'Что то пошло не так',
          });
        }
        await cacheEntryRemoved;
        ws.close();
      },
    }),
  }),
});

export const { useGetChatMessagesQuery } = chatApi;
