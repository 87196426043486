import React, { useState } from 'react';
import { Control, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import {
  createStreetThunk,
  deleteStreetThunk,
  editStreetThunk,
  IPlotForm,
  selectSettlement,
  selectStreets,
} from '6-entities/plots';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { closeModal, modals, Select, Flex } from '7-shared/ui';
import { notification } from '7-shared/lib/notification';

import { mapStreets, SelectPlotItem } from '../../../model/preparePlotSelectData';
import { CustomStreetForm } from './customStreetForm';
import { IStreetFormData } from '../../../model/streetFormData';
import { IconX } from '7-shared/assets';

interface IProps {
  control: Control<IPlotForm>;
  watch: UseFormWatch<IPlotForm>;
  setValue: UseFormSetValue<IPlotForm>;
}

export const StreetItem = ({ control, watch, setValue }: IProps) => {
  const dispatch = useAppDispatch();

  const [isHiddenDropdown, setIsHiddenDropdown] = useState<boolean>(false);

  const [formData, setFormData] = useState<IStreetFormData>({
    showInput: false,
    value: '',
    label: '',
    mode: 'create',
    error: undefined,
  });

  const streets = useAppSelector(selectStreets);
  const settlement = useAppSelector(selectSettlement);

  const onClickDeleteHandler = (value: string) => {
    setIsHiddenDropdown(true);
    modals.openConfirmModal({
      modalId: 'confirm_delete_modal',
      title: 'Вы уверены, что хотите удалить улицу?',
      labels: { confirm: 'Удалить', cancel: 'Отмена' },
      confirmProps: { variant: 'outline', color: 'red', type: 'button' },
      cancelProps: { type: 'button' },
      onCancel: () => closeModal('confirm_delete_modal'),
      onConfirm: () => onConfirmDelete(value),
      centered: true,
      closeOnConfirm: false,
    });
  };

  const onConfirmDelete = async (value: string) => {
    await dispatch(deleteStreetThunk(value))
      .unwrap()
      .then(() => {
        setIsHiddenDropdown(false);
        closeModal('confirm_delete_modal');
      })
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Что-то пошло не так',
        });
      });
  };

  const onClickEdit = (value: string, label: string) => {
    setIsHiddenDropdown(true);
    setFormData({
      value,
      label,
      showInput: true,
      mode: 'edit',
      error: undefined,
    });
  };

  const onConfirmEditHandler = async () => {
    if (!settlement?.id) {
      return;
    }
    const data = {
      settlement_id: settlement.id,
      name: formData.label,
    };
    await dispatch(editStreetThunk({ street_id: formData.value, data }))
      .unwrap()
      .then(() => {
        setIsHiddenDropdown(false);
        setFormData({
          showInput: false,
          value: '',
          label: '',
          mode: 'create',
          error: undefined,
        });
      })
      .catch((error) => {
        setFormData({
          ...formData,
          error: error?.detail ?? error?.non_field_errors,
        });
      });
  };

  const onClickCreate = () => {
    setIsHiddenDropdown(true);
    setFormData({
      value: '',
      label: '',
      showInput: true,
      mode: 'create',
      error: undefined,
    });
  };

  const onConfirmCreateHandler = async () => {
    if (!settlement?.id) {
      return;
    }
    const data = {
      settlement_id: settlement.id,
      name: formData.label,
    };
    await dispatch(createStreetThunk(data))
      .unwrap()
      .then(() => {
        setIsHiddenDropdown(false);
        setFormData({
          showInput: false,
          value: '',
          label: '',
          mode: 'create',
          error: undefined,
        });
      })
      .catch((error) => {
        setFormData({
          ...formData,
          error: error?.detail ?? error?.non_field_errors,
        });
      });
  };

  const onClickOnSelect = () => {
    setIsHiddenDropdown(false);
    setFormData({
      showInput: false,
      value: '',
      label: '',
      mode: 'create',
      error: undefined,
    });
  };
  const onClearSelect = () => {
    setValue('street_id', null);
    setValue('realty_number', null);
    setValue('realty_corpus', null);
  };

  return (
    <Flex direction={'column'} className={'w-full'} gap={10}>
      <Select
        onClick={onClickOnSelect}
        name={'street_id'}
        control={control}
        itemComponent={SelectPlotItem}
        data={mapStreets(streets, onClickCreate, onClickEdit, onClickDeleteHandler)}
        className={'w-full'}
        classNames={{
          dropdown: `${isHiddenDropdown && 'hidden'}`,
        }}
        rightSection={watch('street_id') && <IconX size={18} color={'gray'} onClick={onClearSelect} />}
        creatable
        clearable
        getCreateLabel={() => '+ Добавить новую улицу'}
        onCreate={() => {
          onClickCreate();
          return '';
        }}
        label={'Улица'}
        searchable
        nothingFound={'Ничего не найдено'}
        maxDropdownHeight={160}
        dropdownPosition={'bottom'}
        placeholder={'Улица'}
      />
      <CustomStreetForm
        onConfirmCreateHandler={onConfirmCreateHandler}
        onConfirmEditHandler={onConfirmEditHandler}
        formData={formData}
        setFormData={setFormData}
      />
    </Flex>
  );
};
