import { IconCategory, IconUsers } from '7-shared/assets';
import { routes } from '7-shared/lib/routes';

export const tabs = {
  support: {
    value: 'support',
    label: 'Техническая поддержка',
    link: routes.technical_support.root,
    icon: <IconUsers size={18} />,
  },
  templates: {
    value: 'templates',
    label: 'Шаблоны',
    link: routes.technical_support.template,
    icon: <IconCategory size={18} />,
  },
};
