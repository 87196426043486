import { createApi } from '@reduxjs/toolkit/query/react';
import { IUploadFileResponse } from 'interface';
import { customFetchBase } from '../fetchBase';

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: customFetchBase,
  keepUnusedDataFor: 1,
  endpoints: (build) => ({
    uploadFile: build.mutation<IUploadFileResponse, FormData>({
      query: (data) => ({
        url: `upload-file/`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useUploadFileMutation } = fileApi;
