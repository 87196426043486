import { Flex, Menu, Text } from '../../mantine';
import {
  IconArrowsSort,
  IconCheck,
  IconSortAscending,
  IconSortDescending,
  IconChevronDown,
} from '../../../assets/tabler';
import React from 'react';

interface IProps {
  sortSchema: any;
}

export const TableSort = ({ sortSchema }: IProps) => {
  const onChangeHandler = (key: string) => {
    if (!sortSchema.value) {
      sortSchema.onClear(sortSchema.name);
    }
    if (key === 'number') {
      sortSchema.onClear(sortSchema.name);
    }
    if (sortSchema.value === key || sortSchema.value === `-${key}`) {
      sortSchema.onChange(sortSchema.name, sortSchema.value.slice(0, 1) === '-' ? key : `-${key}`);
    } else {
      sortSchema.onChange(sortSchema.name, key);
    }
  };

  const checkSortIcon = (key: string): React.ReactNode => {
    if (sortSchema.value === `-${key}`) {
      return <IconSortAscending size={14} />;
    }
    if (sortSchema.value === key) {
      return <IconSortDescending size={14} />;
    }
    return <IconArrowsSort size={14} />;
  };

  const checkCurrentSort = (key: string) => {
    if (!sortSchema.value && key === 'number') {
      return true;
    }
    if (sortSchema.value === `-${key}`) {
      return true;
    }
    return sortSchema.value === key;
  };

  const getCurrentSortName = (key: string) => {
    if (!sortSchema.value || key === 'number') {
      return sortSchema.values.find((i: any) => i.key === 'number')?.title;
    }
    if (sortSchema.value === key || sortSchema.value === `-${key}`) {
      return sortSchema.values.find((i: any) => i.key === sortSchema.value || `-${i.key}` === sortSchema.value)?.title;
    }
    return sortSchema.value === key;
  };

  return (
    <Menu shadow="md" width={300}>
      <Menu.Target>
        <Flex className={'cursor-pointer'} align={'center'} gap={5}>
          <Text className={'text-black_text_opacity_65'}>Сортировать</Text>
          <Text className={'text-blue'}>{getCurrentSortName(sortSchema.value)}</Text>
          <IconChevronDown size={22} />
        </Flex>
      </Menu.Target>

      <Menu.Dropdown>
        {sortSchema.values.map((i: any) => (
          <Menu.Item
            icon={checkCurrentSort(i.key) ? <IconCheck size={14} /> : <IconCheck size={14} className={'opacity-0'} />}
            rightSection={checkSortIcon(i.key)}
            key={i.key}
            onClick={() => onChangeHandler(i.key)}
          >
            {i.title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
