import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IPlot, IPlotForm, IQueryParams } from 'interface';
import { customFetchBase } from '../fetchBase';

export const plotsAPI = createApi({
  reducerPath: 'plotsAPI',
  tagTypes: ['plots'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getPlots: build.query<IPaginationResponse<IPlot[]>, IQueryParams>({
      query: ({ page, size, id = '' }) => ({
        url: `/cms/land-plots/${id}?page=${page}&page_size=${size}`,
      }),
      providesTags: ['plots'],
    }),
    getAllPlots: build.query<IPaginationResponse<IPlot[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/land-plots/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['plots'],
    }),
    getSettlementPlots: build.query<IPaginationResponse<IPlot[]>, IQueryParams>({
      query: ({ size, id = '', ...params }) => ({
        url: `/cms/land_plot_list/${id}/`,
        params: {
          page_size: size,
          ...params,
        },
      }),
      providesTags: ['plots'],
    }),
    getPlot: build.query<IPlot, string | undefined>({
      query: (id) => ({
        url: `cms/land-plots/${id}`,
      }),
      providesTags: ['plots'],
    }),
    createPlot: build.mutation<IPlot, IPlotForm>({
      query: (data) => ({
        url: `cms/land-plots/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['plots'],
    }),
    editPlot: build.mutation<IPlot, IEditBody<IPlotForm>>({
      query: (data) => ({
        url: `cms/land-plots/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['plots'],
    }),
    deletePlot: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/land-plots/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['plots'],
    }),
  }),
});

export const {
  useCreatePlotMutation,
  useEditPlotMutation,
  useDeletePlotMutation,
  useGetSettlementPlotsQuery,
  useGetPlotQuery,
} = plotsAPI;
