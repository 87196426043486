export const useGetTextWidth = (inputText?: string | number | null, backupRatio = 0.5) => {
  const container = document.createElement('canvas');

  const text = (inputText ?? '').toString();

  const context = container.getContext('2d');

  if (context) {
    context.font = window.getComputedStyle(document.body).getPropertyValue('font');
    return context.measureText(text).width;
  } else {
    const fontSize = parseFloat(window.getComputedStyle(document.body).getPropertyValue('font-size'));
    return fontSize * backupRatio * text.length;
  }
};
