import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, IStreet, IStreetForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const streetsAPI = createApi({
  reducerPath: 'streetsAPI',
  tagTypes: ['streets', 'street'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getStreets: build.query<IPaginationResponse<IStreet[]>, IQueryParams>({
      query: ({ page, size, id = '' }) => ({
        url: `/cms/street-list/${id}?page=${page}&page_size=${size}`,
      }),
      providesTags: ['streets'],
    }),
    getStreet: build.query<IStreet, string | undefined>({
      query: (id) => ({
        url: `cms/streets/${id}`,
      }),
      providesTags: ['street'],
    }),
    createStreet: build.mutation<IStreet, IStreetForm>({
      query: (data) => ({
        url: `cms/streets/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['streets'],
    }),
    editStreet: build.mutation<IStreet, IEditBody<IStreetForm>>({
      query: (data) => ({
        url: `cms/streets/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['streets', 'street'],
    }),
    deleteStreet: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/streets/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['streets'],
    }),
  }),
});

export const {
  useCreateStreetMutation,
  useEditStreetMutation,
  useDeleteStreetMutation,
  useGetStreetQuery,
  useGetStreetsQuery,
} = streetsAPI;
