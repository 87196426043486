import React from 'react';
import { REPORT_TYPE } from '6-entities/reports';
import { Flex } from '7-shared/ui';

interface IProps {
  value: REPORT_TYPE;
  onChange: (id: REPORT_TYPE) => void;
  disabled: boolean;
}

export const TypeSwitcher = ({ value, onChange, disabled }: IProps) => {
  return (
    <Flex aria-disabled={disabled} className={'flex flex-col items-center gap-[8px] mb-[10px] cursor-pointer'}>
      <span className={'text-black_text_opacity_40 text-1.4'}>Выберите тип создания:</span>
      <div className={'flex bg-black_text_opacity_5 rounded-10'}>
        <div
          className={
            value !== REPORT_TYPE.template
              ? 'font-500 py-[10px] px-[20px] bg-white rounded-10 shadow text-blue'
              : 'font-500 py-[10px] px-[20px] text-black_text_opacity_50'
          }
          onClick={() => disabled || onChange(REPORT_TYPE.personal)}
        >
          без шаблона
        </div>
        <div
          className={
            value === REPORT_TYPE.template
              ? 'font-500 py-[10px] px-[20px] bg-white rounded-10 shadow text-blue'
              : 'font-500 py-[10px] px-[20px] text-black_text_opacity_50'
          }
          onClick={() => {
            disabled || onChange(REPORT_TYPE.template);
          }}
        >
          по шаблону
        </div>
      </div>
    </Flex>
  );
};
