export const routes = {
  main: { name: 'Главная', path: '/' },
  users: { name: 'Пользователи', path: '/users' },
  settlements: { name: 'Коттеджные поселки', path: '/settlements' },
  surveys: { name: 'Опросы', path: '/surveys' },
  videos: { name: 'Камеры', path: '/videos' },
  reports: { name: 'Ход строительства', path: '/reports' },
  streets: { name: 'Улицы', path: `/settlements/streets` },
  plots: { name: 'Земельные участки', path: `/settlements/plots` },
  realty: { name: 'Дома', path: '/settlements/streets/realty' },
  createSettlement: {
    name: 'Создание поселка',
    path: `/settlements/create-settlement/`,
  },
  cameras: { name: 'Видеонаблюдение', path: '/cameras' },
  templates_reports: { name: 'Шаблоны', path: '/reports/templates' },
};

export enum ROUTE_PATH {
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/password-recovery/',

  MAIN = '/',

  USERS = '/users',
  SETTLEMENTS = '/settlements',
  SURVEYS = '/surveys',
  NEWS = '/news',
  NEWS_STATUSES = '/news/statuses',
  NEWS_EVENTS = '/news/events',
  NEWS_LIKES = '/news/likes',
  NOTIFICATIONS = '/news/notifications',
  CREATE_NOTIFICATIONS = '/notifications/create',
  EDIT_NOTIFICATIONS = '/notifications/edit',
  INFORMATION = '/information',
  VIDEOS = '/videos',
  REPORTS = '/reports',
  TECHNICAL_SUPPORT = '/technical-support',
  TECHNICAL_SUPPORT_TEMPLATES = '/tech/templates',
  SERVICE_SUPPORT = '/service-support',
  SERVICE_SUPPORT_TEMPLATES = '/service-support/templates',
  BUILDER_SUPPORT = '/builder-support',
  BUILDER_SUPPORT_TEMPLATES = '/builder-support/templates',
  COMPANIES = '/companies',

  SETTLEMENT = '/settlement',
  STREETS = '/streets',
  PLOTS = '/plots',
  CREATE_SETTLEMENT = '/settlements/create-settlement/:id?',
  CREATE_NEWS = '/news/create',
  EDIT_NEWS = '/news/edit',
  CREATE_EVENT = '/events/create',
  EDIT_EVENT = '/events/edit',
  DOCUMENTS = '/information',
  FAQ = '/information/faq',
  MASTERS = '/masters',
  MASTERS_CATEGORIES = '/masters/categories',
  MASTERS_SUB_CATEGORIES = '/masters/categories/:id',
  MASTERS_SECOND_SUB_CATEGORIES = '/masters/categories/sub-category/:id',
  MASTERS_SECOND_CATEGORIES = '/masters/categories/sub-category',
  PARTNERS = '/partners',
  PARTNERS_CATEGORIES = '/partners/categories',
  PARTNERS_STATISTICS = '/partners/statistics',
  PARTNERS_SCORE = '/partners/score',
  PARTNERS_SUB_CATEGORIES = '/partners/categories/:id',
  PARTNERS_SECOND_SUB_CATEGORIES = '/partners/categories/sub-category/:id',
  PARTNERS_SECOND_CATEGORIES = '/partners/categories/sub-category',
  CAMERAS = '/cameras/:id',
  CONTACTS = '/contacts',
  SPECIAL_CONTACTS = '/contacts/special',
  ADVERTISING = '/advertising',
  ADVERTISING_PERSONAL = '/advertising/personal',
  VIDEO_CONTENT = '/video-content',
  TEMPLATE_REPORTS = '/reports/templates',
  LIKES = '/likes/:id',
  SERVICES = '/services',
  SERVICES_CATEGORIES = '/services/categories',
  SERVICES_SUB_CATEGORIES = '/services/categories/:id',
  SERVICES_SECOND_SUB_CATEGORIES = '/services/categories/sub-category/:id',
  SERVICES_SECOND_CATEGORIES = '/services/categories/sub-category',
  ACCESS_SYSTEM = '/security',

  INVITE_PARTNER = '/invite-partner',
  INVITED_PARTNER_LIST = '/partners/invited-list',
  INVITE_MASTER = '/invite-master',
  INVITED_MASTER_LIST = '/masters/invited-list',
  INVITE_SERVICE = '/invite-service',
  INVITED_SERVICE_LIST = '/services/invited-list',
}
