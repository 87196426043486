import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  createPlotThunk,
  editPlotThunk,
  IPlotForm,
  plotsActions,
  selectPlotsModalState,
  selectSettlement,
  selectStreets,
} from '6-entities/plots';
import { isEmpty } from '7-shared/lib/lodash';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { Button, Flex, Input, Modal } from '7-shared/ui';
import { formErrorHandler, IFormReject } from '7-shared/lib/form';

import { plotSchema } from '../../model/plot.schema';
import { StreetItem } from './customStreetForm/StreetItem';

export const PlotForm = () => {
  const dispatch = useAppDispatch();
  const { open, currentItem, isLoading } = useAppSelector(selectPlotsModalState);
  const streets = useAppSelector(selectStreets);
  const settlement = useAppSelector(selectSettlement);

  const { control, handleSubmit, reset, setError, watch, setValue } = useForm<IPlotForm>({
    mode: 'onChange',
    resolver: yupResolver(plotSchema),
  });

  useEffect(() => {
    if (open) {
      reset({
        number: currentItem?.landPlotNumber,
        account: currentItem?.account,
        realty_number: currentItem?.house,
        realty_corpus: currentItem?.frame,
        street_id: currentItem?.street ? String(streets?.find((i) => i.name === currentItem?.street)?.id) : undefined,
      });
    }
  }, [currentItem, reset, open]);

  const onSubmit = (data: IPlotForm) => {
    if (!settlement?.id) {
      return;
    }
    const newData: IPlotForm = {
      number: data.number,
      account: isEmpty(data.account) ? null : data.account,
      realty_number: isEmpty(data.realty_number) ? null : data.realty_number,
      realty_corpus: isEmpty(data.realty_corpus) ? null : data.realty_corpus,
      street_id: data.street_id,
    };

    isEmpty(currentItem)
      ? createPlotHandler({ ...newData, settlement_id: +settlement.id })
      : editPlotHandler(currentItem.id, newData);
  };

  const createPlotHandler = (data: IPlotForm) => {
    dispatch(createPlotThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((e: IFormReject<IPlotForm>) => formErrorHandler({ errors: e, setError }));
  };

  const editPlotHandler = (id: number, data: IPlotForm) => {
    dispatch(editPlotThunk({ id, data }))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((e: IFormReject<IPlotForm>) => formErrorHandler({ errors: e, setError }));
  };

  const onCloseModalHandler = () => {
    dispatch(plotsActions.setPlotsModalState({ open: false, currentItem: undefined }));
    reset();
  };

  return (
    <Modal
      onClose={onCloseModalHandler}
      loading={isLoading}
      opened={open}
      title={isEmpty(currentItem) ? 'Добавление данных' : 'Редактирование данных'}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-2 w-full items-center justify-center'}>
        <Input
          name={'number'}
          control={control}
          label={'Номер ЗУ'}
          placeholder={'Номер ЗУ'}
          withAsterisk
          className={'w-full'}
        />
        <Input
          name={'account'}
          control={control}
          label={'Лицевой счет'}
          regExp={/^(\s*|\d+)$/}
          placeholder={'Лицевой счет'}
          className={'w-full'}
        />
        <StreetItem setValue={setValue} watch={watch} control={control} />

        <Flex className={'w-full'} justify={'space-between'} gap={10}>
          <Input
            name={'realty_number'}
            control={control}
            label={'Номер дома'}
            disabled={!watch('street_id')}
            placeholder={'Номер дома'}
            withAsterisk={!!watch('street_id')}
            className={'w-full'}
          />
          <Input
            name={'realty_corpus'}
            control={control}
            disabled={!watch('street_id') || !watch('realty_number')}
            className={'w-full'}
            label={'Корпус'}
            placeholder={'Корпус'}
          />
        </Flex>
        <Input name={'detail'} control={control} hidden />
        <Button mt={30} size={'md'} type={'submit'} fullWidth>
          {isEmpty(currentItem) ? 'Создать' : 'Сохранить'}
        </Button>
      </form>
    </Modal>
  );
};
