import { ITimeStamps } from './app.interface';

export interface IAccessSystem extends ITimeStamps {
  brand: string;
  category: ACCESS_SYSTEM_ITEM_CATEGORY;
  comment: string;
  id: number;
  number: string;
  status: ACCESS_SYSTEM_STATUSES;
  travel_date: string;
  arrival_time: string;
  departure_time: string;
  user_phone: string;
  address: {
    land_plot_number: string;
    realty_address: {
      corpus: string;
      number: string;
      street: string;
    };
  };
}

export interface IAccessSystemUser {
  phone: string;
  address: IAccessSystemUserAddress[];
}

export interface IPerformAccessSystem {
  departure_time: string;
}

export interface IOnTerritoryAccessSystem {
  arrival_time: string;
}

export interface IAccessSystemUserAddress {
  corpus: string;
  number: string;
  street: string;
}

export enum ACCESS_SYSTEM_ITEM_CATEGORY {
  DELIVERY = 'delivery',
  TAXI = 'taxi',
  GUEST = 'guest',
  COURIER = 'courier',
  MASTER = 'master',
}

export enum ACCESS_SYSTEM_STATUSES {
  ACTIVE = 'active',
  PERFORMED = 'performed',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  ON_TERRITORY = 'on_territory',
}

export interface IAccessSystemReportFilters {
  settlement_id: string;
  from_date: string;
  to_date: string;
}

export type IAccessSystemFilters = Readonly<'search' | 'status' | 'travel_date__lte' | 'travel_date__gte'>;

export interface INewPassForm {
  brand: string;
  number: string;
  category: ACCESS_SYSTEM_ITEM_CATEGORY;
  user_id: number | string;
  address: string;
  comment: string;
  travel_date: Date | string;
  arrival_time?: null | string;
  departure_time?: null | string;
  land_plot_id?: string;
  street?: string;
  phone: string;
}
