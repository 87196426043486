import React from 'react';
import {
  getRealtyThunk,
  IPlotsListFilters,
  IRealty,
  IStreets,
  plotsActions,
  selectPlotsListFilters,
  selectRealty,
  selectStreets,
} from '6-entities/plots';
import { defaultTo, isEmpty } from '7-shared/lib/lodash';
import { IFilterSchema, TableFilters } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';

export const PlotsFilters = () => {
  const dispatch = useAppDispatch();

  const { onChange, onClear, params } = useQueryParams<IPlotsListFilters>();

  const filters = useAppSelector(selectPlotsListFilters);
  const streets = useAppSelector(selectStreets);
  const realty = useAppSelector(selectRealty);

  const onChangeFilter = (key: keyof IPlotsListFilters, value: string) => {
    onChange(key, value);
    dispatch(plotsActions.setListPagination({ page: 1 }));
    dispatch(plotsActions.setListFilters({ ...params, [key]: value }));
  };

  const onClearFilter = (key: keyof IPlotsListFilters) => {
    onClear(key);
    dispatch(plotsActions.setListPagination({ page: 1 }));
    dispatch(plotsActions.setListFilters({ ...params, [key]: undefined }));
  };

  const onChangeFilterStreet = (key: keyof IPlotsListFilters, value: string) => {
    onChange(key, value);
    dispatch(plotsActions.setListPagination({ page: 1 }));
    dispatch(plotsActions.setListFilters({ ...params, [key]: value }));
    if (key === 'street_id' && !isEmpty(value)) dispatch(getRealtyThunk(value));
  };

  const onClearFilterStreet = (key: keyof IPlotsListFilters) => {
    const keyArr = ['street_id', 'realty_id'];
    onClear(keyArr);
    dispatch(plotsActions.setListFilters({ ...params, [keyArr[0]]: undefined, [keyArr[1]]: undefined }));
    dispatch(plotsActions.setListPagination({ page: 1 }));
  };

  const filtersSchema: IFilterSchema<IPlotsListFilters>[] = [
    {
      name: 'search',
      type: 'input',
      placeholder: 'Поиск (по ЗУ)',
      className: 'min-w-[500px]',
      value: params?.search,
      onChange: (key, value) => {
        onChangeFilter(key, value);
      },
      onClear: onClearFilter,
    },
    {
      name: 'street_id',
      type: 'select',
      options: defaultTo(streets, []).map((i: IStreets) => ({
        value: `${i.id}`,
        label: i.name,
      })),
      placeholder: 'Улица',
      className: 'min-w-[160px] ',
      value: params?.street_id,
      onChange: onChangeFilterStreet,
      onClear: onClearFilterStreet,
    },
    {
      name: 'realty_id',
      type: 'select',
      options: defaultTo(realty, []).map((i: IRealty) => ({
        value: `${i.id}`,
        label: i.name,
      })),
      placeholder: 'Дом',
      className: 'min-w-[160px]',
      value: params?.realty_id,
      onChange: onChangeFilter,
      onClear: onClearFilter,
      disabled: !filters?.street_id,
    },
  ];
  return <TableFilters schema={filtersSchema} />;
};
