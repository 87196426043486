import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, ISettlement, ISettlementForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const settlementsAPI = createApi({
  reducerPath: 'settlementsAPI',
  tagTypes: ['settlements', 'settlement'],
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getSettlements: build.query<IPaginationResponse<ISettlement[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `cms/settlements?page=${page}&page_size=${size}`,
      }),
      providesTags: ['settlements'],
    }),
    getSettlement: build.query<ISettlement, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/settlements/${id}`,
      }),
      providesTags: ['settlement'],
    }),
    createSettlement: build.mutation<ISettlement, ISettlementForm>({
      query: (data) => ({
        url: `cms/settlements/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['settlements'],
    }),
    editSettlement: build.mutation<ISettlement, IEditBody<ISettlementForm>>({
      query: (data) => ({
        url: `cms/settlements/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['settlements', 'settlement'],
    }),
    deleteSettlement: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/settlements/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['settlements'],
    }),
  }),
});

export const {
  useGetSettlementsQuery,
  useCreateSettlementMutation,
  useDeleteSettlementMutation,
  useEditSettlementMutation,
  useGetSettlementQuery,
  useLazyGetSettlementQuery,
} = settlementsAPI;
