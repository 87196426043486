import React, { useEffect } from 'react';
import {
  BuilderSupportFilters,
  BuilderSupportPagination,
  BuilderSupportReports,
  BuilderSupportSettings,
  BuilderSupportTable,
} from '5-features/builderSupport';
import {
  builderSupportActions,
  getBuilderSupportListThunk,
  IBuilderSupportListFilters,
  selectBuilderSupportListFilters,
  selectBuilderSupportListPagination,
} from '6-entities/builderSupport';
import { Flex, ScrollArea, Title } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { getSettlementListThunk } from '7-shared/api';

export const BuilderSupportList = () => {
  const dispatch = useAppDispatch();

  const { onChange, onClear, params, pagination } = useQueryParams<IBuilderSupportListFilters>();

  const filters = useAppSelector(selectBuilderSupportListFilters);
  const tablePagination = useAppSelector(selectBuilderSupportListPagination);

  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getBuilderSupportListThunk());
    }
  }, [tablePagination?.page, filters]);

  useEffect(() => {
    if (params && JSON.stringify(params) !== JSON.stringify(filters)) {
      dispatch(builderSupportActions.setListFilters({ ...params }));
    }
  }, [params]);
  useEffect(() => {
    dispatch(getSettlementListThunk());
    return () => {
      dispatch(builderSupportActions.setInitialBuilderSupportstate());
    };
  }, []);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(builderSupportActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  return (
    <Flex direction={'column'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
          Застройщик
        </Title>
        <BuilderSupportReports onChange={onChange} onClear={onClear} params={params} />
      </Flex>
      <Flex className={'mr-[10px]'} justify={'flex-end'} align={'center'}>
        <BuilderSupportSettings />
      </Flex>
      <ScrollArea>
        <div className={'flex flex-col gap-4'}>
          <BuilderSupportFilters onChange={onChange} onClear={onClear} params={params} />
          <BuilderSupportTable />
        </div>
      </ScrollArea>
      <BuilderSupportPagination onChange={onChange} />
    </Flex>
  );
};
