import { ISettlement } from './settlements.interface';
import { ITimeStamps } from './app.interface';

export interface IServiceForm {
  name: string;
  phone: string;
  email: string;
  cover: string;
  preview: string;
  short_description: string;
  full_description: string;
  expire_date: string;
  sections: number[];
  subsections: number[];
  category: any[];
  second_subsections: number[];
  show_all: boolean;
  settlements: ISettlement[];
  staff: IStaffServiceForm[];
  detail?: string;
}
export interface IService extends ITimeStamps, IServiceForm {
  id: number;
  staff: IStaffService[];
  status: SERVICES_DISPLAY_STATUS;
  section_descriptions: IServiceSectionDescription[];
  subsection_descriptions: IServiceSubSectionDescription[];
  second_subsection_descriptions: IServiceSecondSubSectionDescription[];
}

export enum SERVICES_DISPLAY_STATUS {
  ACCESS = 'access_display',
  PROLONG = 'prolong_display',
  NO = 'no_display',
}

export interface IServiceSectionDescription {
  section_id: number;
  short_description: string;
  full_description: string;
}
export interface IServiceSubSectionDescription {
  subsection_id: number;
  short_description: string;
  full_description: string;
}
export interface IServiceSecondSubSectionDescription {
  second_subsection_id: number;
  short_description: string;
  full_description: string;
}

export interface IStaffServiceForm {
  full_name: string;
  short_description: string;
  avatar: string;
  id?: number;
}

export interface IServiceTreeOptions {
  disabled: boolean;
  id: number;
  value: string;
  checked: boolean;
  level: number;
  options: IServiceTreeOptions[];
  full_description?: string;
  short_description?: string;
}

export interface IStaffService extends IStaffServiceForm, ITimeStamps {
  id: number;
}

export type IServicesQueryParams = Readonly<'search' | 'status' | 'page' | 'size'>;
