import { useEffect } from 'react';
import { notification } from '7-shared/lib/notification';
import { NOTIFICATION_TYPE } from 'interface';

export const useNotification = (visible: boolean, type: NOTIFICATION_TYPE, message: string, description?: string) => {
  const openNotification = () => {
    if (type === NOTIFICATION_TYPE.ERROR) {
      notification({
        status: 'error',
        title: description ? description : '',
        message: message ?? 'Что-то пошло не так',
      });
    }
    if (type === NOTIFICATION_TYPE.SUCCESS) {
      notification({
        status: 'success',
        title: description ? description : '',
        message: message,
      });
    }
  };

  useEffect(() => {
    if (visible) {
      openNotification();
    }
  }, [visible]);
};
