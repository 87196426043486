import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';

import { IPlotsDto, IRealtyDto, ISettlement, IStreetForm, IStreetsDto } from './types';
import { IPlotForm, IPlotsListFilters } from '../model/types';

export const plotsApi = {
  getPlotsList(
    id: string,
    page: number,
    page_size?: number,
    filters?: IPlotsListFilters
  ): Promise<IPaginationResponse<IPlotsDto>> {
    return instance
      .get(`cms/land_plot_list/${id}/`, {
        params: { page, page_size, ...filters },
      })
      .then((res) => res.data);
  },
  getStreetList(id: string, page: number = 1, page_size: number = 100): Promise<IPaginationResponse<IStreetsDto>> {
    return instance
      .get(`cms/street-list/${id}/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  getRealtyList(id: string, page: number = 1, page_size: number = 100): Promise<IPaginationResponse<IRealtyDto>> {
    return instance
      .get(`cms/realty_list/${id}/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  editPlot(id: number, data: IPlotForm): Promise<IPlotForm> {
    return instance.put(`cms/land-plots/${id}/`, data).then((res) => res.data);
  },
  deletePlot(id: string) {
    return instance.delete(`cms/land-plots/${id}/`).then((res) => res.data);
  },
  createPlot(data: IPlotForm) {
    return instance.post(`cms/land-plots/`, data).then((res) => res.data);
  },
  editStreet(street_id: string, data: IStreetForm) {
    return instance.put(`cms/streets/${street_id}/`, data).then((res) => res.data);
  },
  deleteStreet(id: string) {
    return instance.delete(`cms/streets/${id}/`).then((res) => res.data);
  },
  createStreet(data: IStreetForm) {
    return instance.post(`cms/streets/`, data).then((res) => res.data);
  },
  getSettlementById(id: string): Promise<ISettlement> {
    return instance.get(`cms/settlements/${id}/`).then((res) => res.data);
  },
};
