const public_routes = {};

const private_routes = {
  technical_support: {
    root: '/technical-support',
    template: '/technical-support/templates',
    id: '/technical-support/:id',
  },
  builder_support: {
    root: '/builder-support',
    template: '/builder-support/templates',
    id: '/builder-support/:id',
  },
  service_support: {
    root: '/service-support',
    template: '/service-support/templates',
    id: '/service-support/:id',
  },
  settlenent: {
    root: '/settlement',
    id: 'settlement/:id',
  },
  plots: {
    root: '/plots',
    id: '/plots/:id',
  },
  reports: {
    root: '/reports',
    template: '/reports/templates',
  },
  settlements: {
    root: '/settlements',
    createSettlement: '/settlements/create-settlement/:id?',
  },
  createSettlement: {
    root: `/settlements/create-settlement/`,
  },
  videos: {
    root: '/videos',
    cameras: '/cameras',
    id: '/cameras/:id',
  },
  likes: {
    root: '/likes',
  },
};

export const routes = {
  ...public_routes,
  ...private_routes,
};
