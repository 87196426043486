import { IUserRealty } from './users.interface';
import { ITimeStamps } from './app.interface';
import { ISettlement } from './settlements.interface';

export interface IReport extends ITimeStamps {
  id: number;
  title: string;
  description: string;
  preview: string;
  type: REPORT_TYPE;
  link: string;
  address: string;
  settlement: ISettlement;
  realty: IUserRealty;
  created_by_template: boolean;
  template: number;
}

export interface IReportForm {
  type: REPORT_TYPE;
  title: string;
  description: string;
  preview: string;
  link: string;
  settlement_id?: number;
  realty_id?: number;
  street_id?: number;
  report_template_id?: number;
}

export enum REPORT_TYPE {
  personal = 'personal',
  common = 'common',
  template = 'template',
}

export interface ITemplateReportsForm {
  title: string;
  description: string;
  preview: string;
}
export interface ITemplateReports extends ITimeStamps, ITemplateReportsForm {
  id: number;
}
export interface IReportViaTemplateForm {
  link: string;
  realty_id: number;
  report_template_id: number;
}
export interface IImportReportForm {
  report_template_id: number;
  file: any;
}
