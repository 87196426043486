import React from 'react';
import { Tooltip } from '7-shared/ui';
import { ILikes } from '6-entities/news';

export interface ILikesColumnTable {
  accessor: keyof ILikes;
  title?: string | JSX.Element;
  render?: (value: ILikes, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: ILikesColumnTable[] = [
  {
    accessor: 'number',
    render: (value, index) => <span>{index + 1}.</span>,
    width: 50,
  },
  {
    accessor: 'first_name',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.first_name}`}>
        {value?.first_name}
      </Tooltip>
    ),
  },
  {
    accessor: 'name_settlement',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.name_settlement}`}>
        {value?.name_settlement ?? ''}
      </Tooltip>
    ),
  },
];
