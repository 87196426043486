import React, { useEffect } from 'react';
import { Center } from '@mantine/core';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'shared/constants';
import { getIsAuth } from 'store/app';

export const AuthLayout = () => {
  const isAuth = useSelector(getIsAuth);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuth) {
      navigate(ROUTE_PATH.MAIN);
    }
  }, [isAuth, navigate]);
  return (
    <Center className={'h-screen w-full bg-auth_bg'}>
      <Outlet />
    </Center>
  );
};
