import { createAsyncThunk } from '@reduxjs/toolkit';
import { technicalSupportApi } from '../api/technicalSupport.api';
import { technicalSupportActions } from './technicalSupport.slice';
import { mapTechnicalSupportList } from '../lib/mapTechnicalSupportList';
import { mapFiltersTechnicalSupport } from '../lib/mapFiltersTechnicalSuppor';

export const getTechnicalSupportListThunk = createAsyncThunk<void, void, { state: RootState }>(
  'technicalSupport/getTechnicalSupportList',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(technicalSupportActions.setListTableData({ isLoading: true }));

      const filters = mapFiltersTechnicalSupport(getState().technicalSupport.filters);
      const pagination = getState().technicalSupport.pagination;
      const result = await technicalSupportApi.getTechnicalSupportList(pagination.page, pagination.page_size, filters);
      const list = result.results.map((i) => mapTechnicalSupportList(i));
      dispatch(technicalSupportActions.setListTableData({ data: list }));
      dispatch(technicalSupportActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(technicalSupportActions.setListTableData({ isLoading: false }));
    }
  }
);
export const getTechnicalSupportReportThunk = createAsyncThunk<any, void, { state: RootState }>(
  'technicalSupport/getTechnicalSupportReport',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(technicalSupportActions.setListTableData({ isLoading: true }));
      const filters = mapFiltersTechnicalSupport(getState().technicalSupport.filters);
      technicalSupportApi.getTechnicalSupportReport(filters);
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(technicalSupportActions.setListTableData({ isLoading: false }));
    }
  }
);
