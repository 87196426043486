import React from 'react';
import { Tooltip } from '7-shared/ui';
import { ITemplatesDto } from '6-entities/templates';
import { BuilderTemplatesTableActions } from '../ui/builderTemplatesTableActions/builderTemplatesTableActions';

export interface IBuilderTemplatesColumnTable {
  accessor: keyof ITemplatesDto;
  render?: (value: ITemplatesDto, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: IBuilderTemplatesColumnTable[] = [
  {
    accessor: 'title',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.title}`}>
        {value?.title}
      </Tooltip>
    ),
    width: 200,
    ellipsis: true,
  },
  {
    accessor: 'text',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.text}`}>
        {value?.text}
      </Tooltip>
    ),
    width: 300,
  },

  {
    accessor: 'actions',
    render: (value) => <BuilderTemplatesTableActions record={value} />,
    width: 50,
  },
];
