import React from 'react';
import { useAppDispatch, useAppSelector, useModal } from '7-shared/hooks';
import { ActionIcon, DeleteModal, Menu } from '7-shared/ui';
import { IconDots, IconPencil, IconTrash } from '7-shared/assets';
import { notification } from '7-shared/lib/notification';
import { deleteVideoThunk, IVideo, selectVideosModalState, videosActions } from '6-entities/videos';

interface IProps {
  record: IVideo;
}

export const VideosTableActions = ({ record }: IProps) => {
  const dispatch = useAppDispatch();
  const { isShown, toggle: toogleDeleteModal } = useModal();
  const { isLoading } = useAppSelector(selectVideosModalState);

  const deleteSettlementHandler = () => {
    dispatch(deleteVideoThunk(record.id))
      .unwrap()
      .then(() => toogleDeleteModal())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Удаление КП запрещено',
        });
      });
  };

  const onEditHandler = () => {
    dispatch(videosActions.setVideosModalState({ open: true, currentItem: record }));
  };

  return (
    <>
      <DeleteModal
        deleteText={'Вы уверены что хотитет удалить КП?'}
        onClose={toogleDeleteModal}
        loading={isLoading}
        open={isShown}
        onConfirm={deleteSettlementHandler}
      />
      <Menu shadow="md" width={180}>
        <Menu.Target>
          <ActionIcon>
            <IconDots size={22} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item icon={<IconPencil size={16} />} onClick={onEditHandler}>
            Редактировать
          </Menu.Item>
          <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={toogleDeleteModal}>
            Удалить
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
