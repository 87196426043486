import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { AddPhoto } from '7-shared/ui';

interface IProps {
  name: string;
  control: any;
  defaultFile?: any;
}

export const AddFileButton = ({ name, defaultFile, control }: IProps) => {
  const [file, setFile] = useState<any>('');

  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    setFile(defaultFile);
  }, [defaultFile]);
  useEffect(() => {
    onChange(file);
  }, [file]);

  return <AddPhoto type={'file'} setImage={setFile} defaultUrl={file} />;
};
