import { ITemplates } from '../api/types';
import { ITemplatesDto } from '../model/types';

export function mapTemplatesList(dto: ITemplates): ITemplatesDto {
  return {
    id: dto.id,
    text: dto.text,
    title: dto.title,
    type: dto.type,
  };
}
