import { statuses } from './statuses';
import { notifications } from '@mantine/notifications';

interface INotification {
  status: 'success' | 'error' | 'warning';
  title: string;
  message?: string;
  autoClose?: number | boolean;
}

export const notification = ({ status, message, title, autoClose = 3000 }: INotification) => {
  return notifications.show({
    title: title,
    message: message,
    icon: statuses[status].icon,
    color: statuses[status].color,
    autoClose,
  });
};
