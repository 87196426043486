import React, { useEffect } from 'react';
import { BackArrow, Button, Flex, Title } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { getCamerasThunk, selectSettlement, selectVideosListPagination, videosActions } from '6-entities/videos';
import { useParams } from 'react-router-dom';
import { IconPlus } from '7-shared/assets';
import { CameraForm, CamerasPagination, CamerasTable } from '5-features/videos';

export const CamerasList = () => {
  const dispatch = useAppDispatch();

  const { pagination } = useQueryParams();
  const { id } = useParams();
  const tablePagination = useAppSelector(selectVideosListPagination);
  const settlement = useAppSelector(selectSettlement);

  const onPressAddHandler = () => {
    dispatch(videosActions.setCamerasModalState({ open: true }));
  };

  useEffect(() => {
    if (tablePagination?.page && id) {
      dispatch(getCamerasThunk(+id));
    }
  }, [tablePagination?.page, id]);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(videosActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  useEffect(() => {
    return () => {
      dispatch(videosActions.setInitialVideosState());
    };
  }, []);
  return (
    <Flex direction={'column'} className={'flex-col  h-full'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Flex gap={10} align={'center'}>
          <BackArrow />
          <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
            {settlement.name}
          </Title>
        </Flex>
        <Button rightIcon={<IconPlus size={20} />} className={'w-max'} variant="outline" onClick={onPressAddHandler}>
          Добавить камеру
        </Button>
      </Flex>

      <CamerasTable />
      <CamerasPagination />
      <CameraForm />
    </Flex>
  );
};
