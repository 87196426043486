import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, IVideoContent, IVideoContentForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const videoContentApi = createApi({
  reducerPath: `videoContentApi`,
  tagTypes: ['videos', 'current_video'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getVideosContent: build.query<IPaginationResponse<IVideoContent[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/video-links/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['videos'],
    }),
    createVideoContent: build.mutation<IVideoContent, IVideoContentForm>({
      query: (data) => ({
        url: `/cms/video-links/`,
        method: 'POST',
        body: { name: data.name, link: data.link, preview: data.preview },
      }),
      invalidatesTags: ['videos'],
    }),
    createVideoContentGuest: build.mutation<IVideoContent, IVideoContentForm>({
      query: (data) => ({
        url: `/cms/video-links/guest/`,
        method: 'POST',
        body: { name: data.name, link: data.link, preview: data.preview },
      }),
      invalidatesTags: ['videos'],
    }),
    editVideoContent: build.mutation<IVideoContent, IEditBody<IVideoContentForm>>({
      query: (data) => ({
        url: `/cms/video-links/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['videos'],
    }),
    deleteVideoContent: build.mutation<null, IQueryParams>({
      query: (data) => ({
        url: `/cms/video-links/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['videos'],
    }),
  }),
});

export const {
  useCreateVideoContentMutation,
  useCreateVideoContentGuestMutation,
  useDeleteVideoContentMutation,
  useEditVideoContentMutation,
  useGetVideosContentQuery,
} = videoContentApi;
