export const filterColumnsByAccessor = (
  strings: string[],
  objects: Record<string, any>[]
): Record<string, unknown>[] => {
  return objects.filter((obj) => strings.includes(obj.accessor));
};

export const filterFiltersByName = (strings: string[], objects: Record<string, any>[]): Record<string, unknown>[] => {
  return objects.filter((obj) => {
    if (obj.name === 'account_id') {
      return strings.includes(obj.name);
    }
    if (obj.name.includes('_')) {
      return strings.includes(obj.name.substring(0, obj.name.indexOf('_')));
    } else return strings.includes(obj.name);
  });
};
