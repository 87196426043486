import React, { useEffect } from 'react';
import { ITechnicalSupportListFilters, technicalSupportActions } from '6-entities/technicalSupport';
import { defaultTo } from '7-shared/lib/lodash';
import { IFilterSchema, SelectRatingItem, TableFilters } from '7-shared/ui';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';

import { technical_support_role, technical_support_score, technical_support_statuses } from '../../model/statuses';
import {
  getRealtyThunk,
  getStreetsListThunk,
  IRealty,
  ISettlement,
  IStreets,
  selectRealty,
  selectSettlements,
  selectStreets,
} from '7-shared/api/adreses';
import { selectConfigTexnicalSupport } from '6-entities/app';
import { filterFiltersByName } from '7-shared/utils';
import { IconStar } from '7-shared/assets';
import { regex } from '7-shared/constants/inputRegExp';
import { getMissingKeys, getRemoteKeysObject } from '../../model/utils/getMissingKeys';

interface IProps {
  onChange: (key: keyof ITechnicalSupportListFilters, value: string) => void;
  onClear: (key: keyof ITechnicalSupportListFilters | string[]) => void;
  params?: ITechnicalSupportListFilters;
}

export const TechnicalSupportFilters = ({ onChange, onClear, params }: IProps) => {
  const dispatch = useAppDispatch();

  const tableSettings = useAppSelector(selectConfigTexnicalSupport);
  const settlements = useAppSelector(selectSettlements);
  const streets = useAppSelector(selectStreets);
  const realty = useAppSelector(selectRealty);

  const onChangeFilter = (key: keyof ITechnicalSupportListFilters, value: string) => {
    onChange(key, value);
    dispatch(technicalSupportActions.setListPagination({ page: 1 }));
    dispatch(technicalSupportActions.setListFilters({ ...params, [key]: value }));
  };

  const onClearFilter = (key: keyof ITechnicalSupportListFilters) => {
    onClear(key);
    dispatch(technicalSupportActions.setListPagination({ page: 1 }));
    dispatch(technicalSupportActions.setListFilters({ ...params, [key]: undefined }));
  };
  const onClearFilterSettlement = () => {
    onClear(['settlement', 'address_street', 'address_realty']);
    dispatch(technicalSupportActions.setListPagination({ page: 1 }));
    dispatch(
      technicalSupportActions.setListFilters({
        ...params,
        settlement: undefined,
        address_street: undefined,
        address_realty: undefined,
      })
    );
  };

  const onClearFilterStreet = () => {
    onClear(['address_street', 'address_realty']);
    dispatch(technicalSupportActions.setListPagination({ page: 1 }));
    dispatch(
      technicalSupportActions.setListFilters({ ...params, address_street: undefined, address_realty: undefined })
    );
  };
  const onChangeFilterSettlement = (key: keyof ITechnicalSupportListFilters, value: string) => {
    onChange(key, value);
    dispatch(technicalSupportActions.setListPagination({ page: 1 }));
    dispatch(technicalSupportActions.setListFilters({ ...params, [key]: value }));
    dispatch(getStreetsListThunk(value));
  };

  const onChangeFilterStreet = (key: keyof ITechnicalSupportListFilters, value: string) => {
    onChange(key, value);
    dispatch(technicalSupportActions.setListPagination({ page: 1 }));
    dispatch(technicalSupportActions.setListFilters({ ...params, [key]: value }));
    dispatch(getRealtyThunk(value));
  };

  const filtersSchema: IFilterSchema<ITechnicalSupportListFilters>[] = [
    {
      name: 'status',
      type: 'select',
      options: technical_support_statuses,
      placeholder: 'Статус',
      className: 'min-w-[230px] max-w-[230px]',
      value: params?.status,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'id',
      type: 'input',
      placeholder: 'Поиск (№ заявки)',
      className: 'min-w-[160px] max-w-[160px]',
      value: params?.id,
      onChange: (key, value) => {
        if (regex.numberEmptyLine.test(value)) onChangeFilter(key, value);
      },
      onClear: onClearFilter,
    },
    {
      name: 'name',
      type: 'input',
      placeholder: 'Поиск (ФИО)',
      className: 'min-w-[190px] max-w-[190px]',
      value: params?.name,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'account_id',
      type: 'input',
      placeholder: 'Поиск (Лицевой счет)',
      className: 'min-w-[205px] max-w-[205px]',
      value: params?.account_id,
      onChange: (key, value) => {
        if (regex.numberEmptyLine.test(value)) onChangeFilter(key, value);
      },
      onClear: onClearFilter,
    },
    {
      name: 'role',
      type: 'select',
      options: technical_support_role,
      placeholder: 'Роль',
      className: 'min-w-[130px] max-w-[130px]',
      value: params?.role,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'phone',
      type: 'input',
      placeholder: 'Поиск (Телефона)',
      className: 'min-w-[190px] max-w-[190px]',
      value: params?.phone,
      onChange: (key, value) => {
        if (regex.numberEmptyLinePlus.test(value)) onChangeFilter(key, value);
      },
      onClear: onClearFilter,
    },
    {
      name: 'email',
      type: 'input',
      placeholder: 'Поиск (Email)',
      className: 'min-w-[295px] max-w-[295px]',
      value: params?.email,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'settlement',
      type: 'select',
      options: defaultTo(settlements, []).map((i: ISettlement) => ({ value: `${i.id}`, label: i.name }), []),
      placeholder: 'КП',
      className: 'min-w-[170px] max-w-[170px]',
      value: params?.settlement,
      onChange: onChangeFilterSettlement,
      onClear: onClearFilterSettlement,
    },
    {
      name: 'address_street',
      type: 'select',
      options: defaultTo(streets, []).map((i: IStreets) => ({ value: `${i.id}`, label: i.name }), []),
      placeholder: 'Улица',
      className: 'min-w-[140px] max-w-[140px]',
      value: params?.address_street,
      onChange: onChangeFilterStreet,
      onClear: onClearFilterStreet,
      disabled: !params?.settlement,
    },
    {
      name: 'address_realty',
      type: 'select',
      options: defaultTo(realty, []).map((i: IRealty) => ({ value: `${i.id}`, label: i.name }), []),
      placeholder: 'Дом',
      className: 'min-w-[95px] max-w-[95px]',
      value: params?.address_realty,
      onChange: onChangeFilter,
      onClear: onClearFilter,
      disabled: !params?.settlement || !params?.address_street,
    },
    {
      name: 'plot',
      type: 'input',
      placeholder: 'Поиск (ЗУ)',
      className: 'min-w-[145px] max-w-[145px]',
      value: params?.plot,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'comment',
      type: 'input',
      placeholder: 'Поиск (Комментарий)',
      className: 'min-w-[190px] max-w-[190px]',
      value: params?.comment,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'dateOfCreation_start',
      type: 'date',
      placeholder: 'C',
      className: 'min-w-[170px] max-w-[170px]',
      value: params?.dateOfCreation_start,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'dateOfCreation_end',
      type: 'date',
      placeholder: 'По',
      className: 'min-w-[170px] max-w-[170px]',
      value: params?.dateOfCreation_end,
      minDate: params?.dateOfCreation_start,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'dateOfClosed_start',
      type: 'date',
      placeholder: 'C',
      className: 'min-w-[170px] max-w-[170px]',
      value: params?.dateOfClosed_start,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'dateOfClosed_end',
      type: 'date',
      placeholder: 'По',
      className: 'min-w-[170px] max-w-[170px]',
      value: params?.dateOfClosed_end,
      minDate: params?.dateOfClosed_start,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'manager',
      type: 'input',
      placeholder: 'Поиск (По фамилии исполнителя)',
      className: 'min-w-[280px] max-w-[280px]',
      value: params?.manager,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'score',
      type: 'select',
      options: technical_support_score,
      placeholder: 'Оценка',
      className: 'min-w-[145px] max-w-[145px]',
      value: params?.score,
      onChange: onChangeFilter,
      onClear: onClearFilter,
      icon: <IconStar size={'16'} />,
      itemComponent: SelectRatingItem,
    },
  ];

  useEffect(() => {
    if (params) {
      const keys = getRemoteKeysObject(tableSettings, params);
      onClear(getMissingKeys(tableSettings, params));
      dispatch(technicalSupportActions.setListFilters({ ...params, ...keys }));
    }
  }, [tableSettings]);

  return <TableFilters schema={filterFiltersByName(tableSettings, filtersSchema)} />;
};
