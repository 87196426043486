import { IServicelSupport } from '6-entities/serviceSupport';

interface IColumnsLIst {
  name: keyof IServicelSupport;
  label: string;
}

export const columnsList: IColumnsLIst[] = [
  { name: 'status', label: 'Статус' },
  { name: 'id', label: '№ заявки' },
  { name: 'name', label: 'ФИО' },
  { name: 'account_id', label: 'Лицевой счет' },
  { name: 'role', label: 'Роль' },
  { name: 'phone', label: 'Телефон' },
  { name: 'email', label: 'Email' },
  { name: 'settlement', label: 'КП' },
  { name: 'address', label: 'Адрес' },
  { name: 'plot', label: 'ЗУ' },
  { name: 'comment', label: 'Комментарий' },
  { name: 'dateOfCreation', label: 'Дата создания' },
  { name: 'dateOfClosed', label: 'Дата закрытия' },
  { name: 'manager', label: 'Исполнитель' },
  { name: 'score', label: 'Оценка' },
];
