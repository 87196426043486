import React from 'react';
import { Tooltip } from '7-shared/ui';
import { IVideo } from '6-entities/videos';
import { Link } from 'react-router-dom';
import { routes } from '7-shared/lib/routes';
import { VideosTableActions } from '../ui/videosTableActions/videosTableActions';

export interface IVideosColumnTable {
  accessor: keyof IVideo;
  render?: (value: IVideo, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: IVideosColumnTable[] = [
  {
    accessor: 'settlement',
    render: (value, index) => (
      <Link className={'text-black hover:text-black'} to={`${routes.videos.cameras}/${value.id}`}>
        <Tooltip className={'max-w-[900px]'} index={index} tooltip={`${value?.id}`}>
          {value.settlement.name}
        </Tooltip>
      </Link>
    ),
  },
  {
    accessor: 'cameras_number',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.cameras_number}`}>
        {value.cameras_number}
      </Tooltip>
    ),
    width: 100,
  },
  {
    accessor: 'actions',
    render: (value) => <VideosTableActions record={value} />,
    width: 100,
  },
];
