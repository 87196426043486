import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';
import { IFilters, IServicesSupportDto } from './types';
import { AxiosResponse } from 'axios/index';

export const serviceSupportApi = {
  getServiceSupportList(
    page: number,
    page_size: number,
    filters?: IFilters
  ): Promise<IPaginationResponse<IServicesSupportDto>> {
    return instance
      .get('cms/feedback-communal/', {
        params: { page, page_size, ...filters },
      })
      .then((res) => res.data);
  },
  getServiceSupportReport(filters?: IFilters) {
    return instance
      .get('cms/feedback-communal/report/', {
        params: { ...filters },
        responseType: 'blob',
      })
      .then(async (res: AxiosResponse<Blob>) => window.location.assign(window.URL.createObjectURL(await res.data)));
  },
};
