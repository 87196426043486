import React, { useEffect } from 'react';
import { Flex, ScrollArea, Title } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import {
  ServiceSupportFilters,
  ServiceSupportPagination,
  ServiceSupportReports,
  ServiceSupportSettings,
  ServiceSupportTable,
} from '5-features/serviceSupport';
import {
  getServiceSupportListThunk,
  IServiceSupportListFilters,
  selectServiceSupportListFilters,
  selectServiceSupportListPagination,
  serviceSupportActions,
} from '6-entities/serviceSupport';
import { getSettlementListThunk } from '7-shared/api';

export const ServiceSupportList = () => {
  const dispatch = useAppDispatch();

  const { onChange, onClear, params, pagination } = useQueryParams<IServiceSupportListFilters>();

  const filters = useAppSelector(selectServiceSupportListFilters);
  const tablePagination = useAppSelector(selectServiceSupportListPagination);

  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getServiceSupportListThunk());
    }
  }, [tablePagination?.page, filters]);

  useEffect(() => {
    if (params && JSON.stringify(params) !== JSON.stringify(filters)) {
      dispatch(serviceSupportActions.setListFilters({ ...params }));
    }
  }, [params]);
  useEffect(() => {
    dispatch(getSettlementListThunk());
    return () => {
      dispatch(serviceSupportActions.setInitialServiceSupportstate());
    };
  }, []);
  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(serviceSupportActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);

  return (
    <Flex direction={'column'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
          Обслуживающая организация
        </Title>
        <ServiceSupportReports onChange={onChange} onClear={onClear} params={params} />
      </Flex>
      <Flex className={'mr-[10px]'} justify={'flex-end'} align={'center'}>
        <ServiceSupportSettings />
      </Flex>
      <ScrollArea>
        <div className={'flex flex-col gap-4'}>
          <ServiceSupportFilters onChange={onChange} onClear={onClear} params={params} />
          <ServiceSupportTable />
        </div>
      </ScrollArea>
      <ServiceSupportPagination onChange={onChange} />
    </Flex>
  );
};
