import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, IStaff, IStaffForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const staffAPI = createApi({
  reducerPath: 'staffAPI',
  tagTypes: ['staff'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getStaffs: build.query<IPaginationResponse<IStaff[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `/cms/staff_list/${id}?page=${page}&page_size=${size}`,
      }),
      providesTags: ['staff'],
    }),
    createStaff: build.mutation<IStaff, IStaffForm>({
      query: (data) => ({
        url: `cms/register/staff/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['staff'],
    }),
    editStaff: build.mutation<IStaff, IEditBody<IStaffForm>>({
      query: (data) => ({
        url: `cms/staff/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['staff'],
    }),
    deleteStaff: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/staff/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['staff'],
    }),
    getPermissions: build.query<string[], void>({
      query: () => ({
        url: `/cms/staff/permissions/`,
      }),
    }),
  }),
});

export const {
  useCreateStaffMutation,
  useDeleteStaffMutation,
  useEditStaffMutation,
  useGetStaffsQuery,
  useGetPermissionsQuery,
} = staffAPI;
