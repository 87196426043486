import React, { useEffect, useState } from 'react';
import { useGetRealtyQuery, useGetSettlementsQuery, useGetStreetsQuery } from 'store/settlements';
import { defaultValue } from 'shared/utils';
import { IOption } from 'interface';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

export const useFormAddress = (
  //TODO нужен дженерик
  setValue: UseFormSetValue<any>,
  settlement_id: any,
  street_id: any,
  realty_id: any
) => {
  const [settlementId, setSettlementId] = useState<any>(settlement_id ?? undefined);
  const [streetId, setStreetId] = useState<any>(street_id ?? undefined);
  const [realtyId, setRealtyId] = useState<any>(realty_id ?? undefined);

  const [settlementsResult, setSettlementsResult] = useState<IOption[]>([]);
  const [streetsResult, setStreetsResult] = useState<IOption[]>([]);
  const [realtiesResult, setRealtiesResult] = useState<IOption[]>([]);

  const { data: settlements } = useGetSettlementsQuery({ page: 1, size: 1000 });
  const { data: streets } = useGetStreetsQuery({ id: settlementId, page: 1, size: 1000 }, { skip: !settlementId });
  const { data: realty } = useGetRealtyQuery({ id: streetId, page: 1, size: 1000 }, { skip: !streetId });

  useEffect(() => {
    setSettlementsResult(
      defaultValue(
        settlements?.results
          .map((settlement) => ({
            id: settlement.id,
            value: settlement.name,
          }))
          .sort((a, b) => a?.value.localeCompare(b?.value, undefined, { numeric: true })),
        []
      )
    );
  }, [settlements]);
  useEffect(() => {
    setStreetsResult(
      defaultValue(
        streets?.results
          .map((street) => ({
            id: street.id,
            value: street.name,
          }))
          .sort((a, b) => a?.value.localeCompare(b?.value, undefined, { numeric: true })),
        []
      )
    );
  }, [streets]);
  useEffect(() => {
    setRealtiesResult(
      defaultValue(
        realty?.results
          .map((realty) => ({
            id: realty.id,
            value: `${realty.number} ${realty?.corpus ? `к.${realty?.corpus}` : ''}`,
          }))
          .sort((a, b) => a?.value.localeCompare(b?.value, undefined, { numeric: true })),
        []
      )
    );
  }, [realty]);
  useEffect(() => {
    if (settlement_id && settlement_id !== settlementId) {
      setSettlementId(settlement_id);
      if (streetId) {
        setStreetId(undefined);
        setRealtyId(undefined);
        setValue('street_id', null as any);
        setValue('realty_id', null as any);
      }
    }
  }, [settlement_id]);
  useEffect(() => {
    if (street_id && street_id !== streetId) {
      setStreetId(street_id);
      if (realtyId) {
        setRealtyId(undefined);
        setValue('realty_id', null as any);
      }
    }
  }, [street_id]);
  useEffect(() => {
    if (realty_id && realty_id !== realtyId) {
      setRealtyId(realty_id);
    }
  }, [realty_id]);

  const onClear = () => {
    setSettlementId(null);
    setStreetId(null);
    setRealtyId(null);
    setStreetsResult([]);
    setRealtiesResult([]);
  };

  return {
    settlements: settlementsResult,
    streets: streetsResult,
    realties: realtiesResult,
    onClear,
  };
};
