import React from 'react';
import { selectServiceSupportListTableData } from '6-entities/serviceSupport';
import { useAppSelector } from '7-shared/hooks';
import { filterColumnsByAccessor } from '7-shared/utils';

import { columns } from '../../model/serviceSupportListColumns';
import { selectConfigIsLoading, selectConfigServiceSupport } from '6-entities/app';
import { selectIsLoader } from '7-shared/api';
import { Table } from '7-shared/ui';

export const ServiceSupportTable = () => {
  const tableData = useAppSelector(selectServiceSupportListTableData);
  const tableSettings = useAppSelector(selectConfigServiceSupport);
  const configIsLoading = useAppSelector(selectConfigIsLoading);
  const adresesIsLoading = useAppSelector(selectIsLoader);

  return (
    <Table
      columns={filterColumnsByAccessor(tableSettings, columns)}
      rows={tableData.data}
      tableLoading={tableData.isLoading || configIsLoading || adresesIsLoading}
      wholeScreen={false}
    />
  );
};
