import React from 'react';
import { Outlet } from 'react-router-dom';
import { Flex } from '7-shared/ui';

export const PlotsLayout = () => {
  return (
    <Flex className={'h-full'} direction={'column'}>
      <Outlet />
    </Flex>
  );
};
