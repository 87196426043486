import React from 'react';
import { defaultTo } from '7-shared/lib/lodash';
import { IFilterSchema, TableFilters } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { ISettlement, selectSettlements } from '7-shared/api';
import { reportsActions, IReportsFilters } from '6-entities/reports';
import { reportType } from '../../model/reportType';

export const ReportsFilters = () => {
  const dispatch = useAppDispatch();

  const { onChange, onClear, params } = useQueryParams<IReportsFilters>();

  const settlements = useAppSelector(selectSettlements);

  const onChangeFilter = (key: keyof IReportsFilters, value: string) => {
    onChange(key, value);
    dispatch(reportsActions.setListPagination({ page: 1 }));
    dispatch(reportsActions.setListFilters({ ...params, [key]: value }));
  };

  const onClearFilter = (key: keyof IReportsFilters) => {
    onClear(key);
    dispatch(reportsActions.setListPagination({ page: 1 }));
    dispatch(reportsActions.setListFilters({ ...params, [key]: undefined }));
  };

  const filtersSchema: IFilterSchema<IReportsFilters>[] = [
    {
      name: 'search',
      type: 'input',
      placeholder: 'Поиск (по названию или адресу)',
      className: 'min-w-[500px]',
      value: params?.search,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'settlement',
      type: 'select',
      options: defaultTo(settlements, []).map((i: ISettlement) => ({ value: `${i.id}`, label: i.name }), []),
      placeholder: 'КП',
      className: 'min-w-[160px] ',
      value: params?.settlement,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'type',
      type: 'select',
      options: reportType,
      placeholder: 'Тип отчета',
      className: 'min-w-[160px]',
      value: params?.type,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
  ];
  return <TableFilters schema={filtersSchema} />;
};
