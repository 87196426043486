import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AddFileButton } from './addFileButton/addFileButton';
import { Button, Modal, Select } from '7-shared/ui';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { IImportReportForm, importFileReportsThunk, selectReportsTemplatesListTableData } from '6-entities/reports';
import { notification } from '7-shared/lib/notification';
import { validate } from '7-shared/constants';
import { ErrorImport } from '../errorImport/errorImport';

interface IProps {
  openModalTemplates: () => void;
  isOpen: boolean;
}

export const ImportReportsForm = ({ openModalTemplates, isOpen }: IProps) => {
  const dispatch = useAppDispatch();
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorData, setErrorData] = useState(null);
  const templates = useAppSelector(selectReportsTemplatesListTableData);

  const options = templates.data.map((el) => ({ value: `${el.id}`, label: el.title }));

  const schema = yup.object().shape({
    report_template_id: yup.number().required(validate.required),
    file: yup.mixed().required(validate.required),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<IImportReportForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onCloseModalHandler = () => {
    openModalTemplates();
    reset();
  };

  const onSubmit = (data: IImportReportForm) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', data.file);
    createReportHandler({ report_template_id: data.report_template_id, file: bodyFormData as any });
  };

  const createReportHandler = (data: IImportReportForm) => {
    dispatch(importFileReportsThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        if (
          'data' in error.response &&
          error.response.status === 400 &&
          Array.isArray((error.response?.data as any)?.detail?.errors)
        ) {
          setOpenError(true);
          setErrorData(error.response.data);
        }
        notification({
          status: 'error',
          title: 'Ошибка',
          message:
            error?.response?.data?.file?.[0] ||
            (typeof (error?.response as any)?.data?.detail === 'string' && error?.response?.data?.detail) ||
            error?.message ||
            'Что-то пошло не так',
        });
      });
  };

  return (
    <>
      {openError && <ErrorImport open={openError} onClose={() => setOpenError(false)} data={errorData} />}
      <Modal size={'sm'} onClose={onCloseModalHandler} opened={isOpen} title={'Автоматическое добавление отчетов'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select
            withinPortal={true}
            className={'w-full mb-3'}
            name={'report_template_id'}
            placeholder={'Шаблон'}
            control={control}
            data={options}
          />
          <AddFileButton control={control} name={'file'} />
          <Button disabled={!isValid} mt={30} size={'md'} type={'submit'} fullWidth className={'mt-[15px] w-full'}>
            Сохранить
          </Button>
        </form>
      </Modal>
    </>
  );
};
