import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IFaq, IFaqForm, IPaginationResponse, IQueryParams, IUpdateFaqIndex } from 'interface';
import { customFetchBase } from '../../fetchBase';

export const faqAPI = createApi({
  reducerPath: 'faqAPI',
  tagTypes: ['faq'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getFaq: build.query<IPaginationResponse<IFaq[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/faq/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['faq'],
    }),
    createFaq: build.mutation<IFaq, IFaqForm>({
      query: (data) => ({
        url: `/cms/faq/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['faq'],
    }),
    editFaq: build.mutation<IFaq, IEditBody<IFaqForm>>({
      query: (data) => ({
        url: `/cms/faq/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['faq'],
    }),
    deleteFaq: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/faq/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['faq'],
    }),
    updateFaqIndex: build.mutation<null, IUpdateFaqIndex>({
      query: (data) => ({
        url: `cms/faq/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['faq'],
    }),
  }),
});

export const {
  useCreateFaqMutation,
  useUpdateFaqIndexMutation,
  useDeleteFaqMutation,
  useEditFaqMutation,
  useGetFaqQuery,
} = faqAPI;
