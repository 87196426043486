import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IGuestUserForm, IPaginationResponse, IQueryParams, IUser, IUserForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const usersAPI = createApi({
  reducerPath: `usersApi`,
  tagTypes: ['users'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getUsers: build.query<IPaginationResponse<IUser[]>, IQueryParams>({
      query: ({ page, size, search, role, settlement_id, realty_id, street_id, ordering }) => ({
        url: `cms/users`,
        params: { page, page_size: size, role, search, settlement_id, street_id, realty_id, ordering },
      }),
      providesTags: ['users'],
    }),
    createUser: build.mutation<IUser, IUserForm>({
      query: (data) => ({
        url: `cms/register/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['users'],
    }),
    editUser: build.mutation<IUser, IEditBody<IUserForm>>({
      query: (data) => ({
        url: `cms/users/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['users'],
    }),
    inviteUser: build.mutation<null, IQueryParams>({
      query: (data) => ({
        url: `cms/users/send/invite-email/${data.id}/`,
        method: 'POST',
      }),
    }),
    createTestUser: build.mutation<IUser, IUserForm>({
      query: (data) => ({
        url: `cms/register/test/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['users'],
    }),
    editTestUser: build.mutation<IUser, IEditBody<IUserForm>>({
      query: (data) => ({
        url: `cms/users/test/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['users'],
    }),
    createGuestUser: build.mutation<IUser, IGuestUserForm>({
      query: (data) => ({
        url: `cms/register/guest/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['users'],
    }),
    activeUser: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/users/activate/${id}/`,
        method: 'PUT',
      }),
      invalidatesTags: ['users'],
    }),
    deactivateUser: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/users/deactivate/${id}/`,
        method: 'PUT',
      }),
      invalidatesTags: ['users'],
    }),
    deleteUser: build.mutation<null, IQueryParams>({
      query: (data) => ({
        url: `cms/users/${data.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['users'],
    }),
    importUsers: build.mutation<any, IQueryParams>({
      query: (data) => ({
        url: `cms/register/multiply/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['users'],
    }),
    exportUploadFileForUsers: build.query<any, void>({
      query: () => ({
        url: `cms/users/upload/template-registry/`,
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        cache: 'no-cache',
      }),
    }),
    exportUsersReports: build.query<any, void>({
      query: () => ({
        url: `cms/users/upload/report/`,
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateGuestUserMutation,
  useActiveUserMutation,
  useCreateTestUserMutation,
  useCreateUserMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useEditTestUserMutation,
  useEditUserMutation,
  useImportUsersMutation,
  useLazyExportUploadFileForUsersQuery,
  useLazyExportUsersReportsQuery,
  useInviteUserMutation,
} = usersAPI;
