import React from 'react';
import { Button, Modal } from '@mantine/core';

interface IProps {
  open: boolean;
  onClose: () => void;
  deleteText: string;
  loading: boolean;
  onConfirm: () => void;
}

export const DeleteModal = ({ open, deleteText, onConfirm, onClose, loading }: IProps) => {
  return (
    <Modal data-cy={'confirm_delete_modal'} onClose={onClose} opened={open} title={deleteText} centered>
      <div className={'flex justify-between gap-6'}>
        <Button
          data-cy={'confirm_delete_modal_cancel'}
          variant={'outline'}
          onClick={onClose}
          size={'md'}
          type={'button'}
          disabled={loading}
          className={'w-full'}
        >
          Отмена
        </Button>
        <Button
          data-cy={'confirm_delete_modal_confirm'}
          color={'red'}
          onClick={onConfirm}
          size={'md'}
          type={'button'}
          disabled={loading}
          className={'w-full bg-red-600'}
        >
          Удалить
        </Button>
      </div>
    </Modal>
  );
};
