import { IUserConfigDto } from '../model/types';
import { IUserConfig } from '../api/types';

export function mapConfigList(dto: IUserConfig): IUserConfigDto {
  const data: IUserConfigDto = {
    is_exists_new_feedback_support: dto.is_exists_new_feedback_support,
    is_exists_new_feedback_communal: dto.is_exists_new_feedback_communal,
    is_exists_new_feedback_builder: dto.is_exists_new_feedback_builder,
    configTechnicalSupport: [],
    configServiceSupport: [],
    configBuilderSupport: [],
  };
  dto.config.forEach((i) => {
    if (i.type === 'titles_feedback_support') {
      data.configTechnicalSupport = i.values.map((j) => j.value);
    }
    if (i.type === 'titles_feedback_communal') {
      data.configServiceSupport = i.values.map((j) => j.value);
    }
    if (i.type === 'titles_feedback_builder') {
      data.configBuilderSupport = i.values.map((j) => j.value);
    }
  });

  return data;
}
