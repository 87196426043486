import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ActionIcon, Checkbox, Menu } from '../../../../ui/index';
import { IconSettings } from '../../../../assets';
import { keys, omitBy } from '../../../../lib/lodash';
import { useStyles } from './styles';

interface IColumnsList {
  name: string;
  label: string;
}
interface DynamicStringObject {
  [key: string]: string | false;
}
interface IProps {
  activeColumns: string[];
  columnsList: IColumnsList[];
  applySettings: (data: string[]) => void;
}

export const TableColumnsSettings: FC<IProps> = ({ activeColumns, columnsList, applySettings }) => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();

  const { handleSubmit, control, watch } = useForm<DynamicStringObject>();

  const onSubmitHandler = handleSubmit(() => {
    const checkedValues = keys(omitBy(watch(), (value) => !value));
    applySettings(checkedValues);
    setOpened(false);
  });

  const openMenu = () => {
    setOpened(true);
  };

  const closeMenu = async () => {
    setOpened(false);
    await onSubmitHandler();
  };

  const disableTheLastCheckbox = (name: string) => {
    const obj = omitBy(watch(), (value) => !value);
    return Object.keys(obj).length === 1 && Object.keys(obj)[0] === name;
  };

  return (
    <>
      <Menu opened={opened} onClose={closeMenu} closeOnItemClick={false} zIndex={200}>
        <Menu.Target>
          <ActionIcon size={'lg'} onClick={openMenu} variant="default">
            <IconSettings size={20} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <form onSubmit={onSubmitHandler}>
            {columnsList?.map((list) => (
              <Menu.Item key={list?.name} className={'cursor-pointer'}>
                <Checkbox
                  disabled={disableTheLastCheckbox(list.name)}
                  classNames={classes}
                  defaultValue={activeColumns.includes(list?.name, 0) ? list?.name : false}
                  control={control}
                  name={list.name}
                  label={list?.label}
                />
              </Menu.Item>
            ))}
          </form>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
