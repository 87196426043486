import { instance, ISettlementDto } from '7-shared/api';
import { ISettlementForm } from './types';
import { IPaginationResponse } from '7-shared/types';

export const settlementsApi = {
  getSettlement(page: number, page_size: number): Promise<IPaginationResponse<ISettlementDto>> {
    return instance
      .get(`cms/settlements/`, {
        params: { page, page_size },
      })
      .then((res) => res.data);
  },
  createSettlement(data: ISettlementForm) {
    return instance.post(`cms/settlements/`, data).then((res) => res.data);
  },
  editSettlement(id: number, data: ISettlementForm) {
    return instance.put(`cms/settlements/${id}/`, data).then((res) => res.data);
  },
  deleteSettlement(id: number) {
    return instance.delete(`cms/settlements/${id}/`).then((res) => res.data);
  },
};
