import { useEffect } from 'react';
import { UseFormSetError } from 'react-hook-form';
//eslint-disable-next-line
export const useFormError = (errors: any, setError: UseFormSetError<any>) => {
  useEffect(() => {
    if (errors) {
      for (const [key, value] of Object.entries(errors)) {
        //eslint-disable-next-line
        setError(key as any, {
          message: value as string,
        });
      }
    }
    //eslint-disable-next-line
  }, [errors]);
};
