import { createAsyncThunk } from '@reduxjs/toolkit';
import { videosActions } from '../model/videos.slice';
import { ICameraForm } from '../api/types';
import { ICommonErrorReject } from '7-shared/api/types';
import { camerasApi } from '../api/cameras.api';
import { videosApi } from '../api/videos.api';
import { mapCamerasSettlementList } from '../lib/mapCamerasSettlementList';

export const getCamerasThunk = createAsyncThunk<void, number, { state: RootState }>(
  'cameras/getCameras',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setListTableCamerasData({ isLoading: true }));
      const pagination = getState().videos.pagination;
      const result = await camerasApi.getCameras(id, pagination.page, pagination.page_size);
      const resultVideos = await videosApi.getCurrentVideo(id);
      const list = mapCamerasSettlementList(resultVideos);
      dispatch(videosActions.setListTableCamerasData({ data: result.results }));
      dispatch(videosActions.setSettlement(list));
      dispatch(videosActions.setListPagination({ total_count: +resultVideos.cameras_number }));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setListTableCamerasData({ isLoading: false }));
    }
  }
);
export const createCameraThunk = createAsyncThunk<void, ICameraForm, { state: RootState }>(
  'cameras/createCamera',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setCamerasModalState({ isLoading: true }));
      await camerasApi.createCamera(data);
      const videosID = getState().videos.settlement.video_id;
      dispatch(getCamerasThunk(videosID));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setCamerasModalState({ isLoading: false }));
    }
  }
);
export const editCameraThunk = createAsyncThunk<void, { id: number; data: ICameraForm }, { state: RootState }>(
  'cameras/editCamera',
  async ({ id, data }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setCamerasModalState({ isLoading: true }));
      await camerasApi.editCamera(id, data);
      const videosID = getState().videos.settlement.video_id;
      dispatch(getCamerasThunk(videosID));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setCamerasModalState({ isLoading: false }));
    }
  }
);

export const deleteCameraThunk = createAsyncThunk<void, number, { state: RootState }>(
  'cameras/deleteCamera',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setListTableCamerasData({ isLoading: true }));
      await camerasApi.deleteCamera(id);
      const videosID = getState().videos.settlement.video_id;
      dispatch(getCamerasThunk(videosID));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setListTableCamerasData({ isLoading: false }));
    }
  }
);
export const startStreamThunk = createAsyncThunk<void, number, { state: RootState }>(
  'cameras/startStream',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setCamerasModalState({ isLoading: true }));
      const result = await camerasApi.startStream(id);
      dispatch(videosActions.setStreamUrl(`${process.env.REACT_APP_VIDEO_URL}${result.endpoint}`));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setCamerasModalState({ isLoading: false }));
    }
  }
);
export const stopStreamThunk = createAsyncThunk<void, number, { state: RootState }>(
  'cameras/stopStream',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setCamerasModalState({ isLoading: true }));
      await camerasApi.stopStream(id);
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setCamerasModalState({ isLoading: false }));
    }
  }
);
export const pauseStreamThunk = createAsyncThunk<void, number, { state: RootState }>(
  'cameras/pauseStream',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(videosActions.setCamerasModalState({ isLoading: true }));
      await camerasApi.pauseStream(id);
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(videosActions.setCamerasModalState({ isLoading: false }));
    }
  }
);
