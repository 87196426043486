export const imageFormats = ['raw', 'jpg', 'jpeg', 'png', 'tiff', 'svg', 'webp', 'ico', 'gif'];
export const videoFormats = ['mp4', '3gp', '3gpp', '3gpp2', '3g2', 'mov', 'webm', 'hevc'];
export const documentsFormats = ['pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx', 'document'];

export const getFileType = (filename: string): 'image' | 'video' | 'doc' | undefined => {
  const extension = getFileExtension(filename);
  if (!extension) {
    return undefined;
  }
  if (imageFormats.includes(extension)) {
    return 'image';
  }
  if (videoFormats.includes(extension)) {
    return 'video';
  }
  if (documentsFormats.includes(extension)) {
    return 'doc';
  }
  return 'doc';
};

export const getFileExtension = (filename: string): string | undefined => {
  return filename?.toLowerCase()?.split('.')?.pop();
};

export const fileToBase64 = async (data: File) => {
  return await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => {
      const result = reader.result;
      if (typeof result === 'string') {
        resolve(result);
      } else {
        reject('Invalid result type: expected string');
      }
    };
    reader.onerror = (error) => reject(error);
  });
};
