import React from 'react';
import { TableColumnsSettings } from '7-shared/ui';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';

import { columnsList } from '../../model/columnsList';
import { createConfigThunk, selectConfigBuilderSupport } from '6-entities/app';

export const BuilderSupportSettings = () => {
  const dispatch = useAppDispatch();

  const tableSettings = useAppSelector(selectConfigBuilderSupport);

  const changeActiveColumnHandler = (keys: string[]) => {
    dispatch(
      createConfigThunk({
        type: 'titles_feedback_builder',
        values: keys,
      })
    );
  };

  return (
    <TableColumnsSettings
      applySettings={changeActiveColumnHandler}
      activeColumns={tableSettings}
      columnsList={columnsList}
    />
  );
};
