import { useDisclosure } from '@mantine/hooks';
import { Button, LoadingOverlay, Modal as MantineModal, ModalProps, Flex } from '@mantine/core';
import React, { FC } from 'react';

interface IProps extends ModalProps {
  children: React.ReactNode;
  loading?: boolean;
  titleSubModal?: string;
  isVisibleConfirmingModal?: boolean;
  classes?: any;
  textButtonClosed?: string;
  textButtonContinue?: string;
}

export const Modal: FC<IProps> = ({
  children,
  onClose,
  title,
  titleSubModal,
  isVisibleConfirmingModal = false,
  size = 'md',
  loading = false,
  classes = {},
  textButtonClosed,
  textButtonContinue,
  ...rest
}) => {
  const [isConfirmingModalOpen, { open: openConfirmingModal, close: closeConfirmingModal }] = useDisclosure(false);
  const handleParentModalClose = () => {
    if (isVisibleConfirmingModal) {
      openConfirmingModal();
    } else {
      if (onClose) onClose();
    }
  };

  const handleSubModalClose = () => {
    closeConfirmingModal();
    if (onClose) onClose();
  };

  const handleCancelClose = () => {
    closeConfirmingModal();
  };

  return (
    <>
      <MantineModal
        classNames={classes}
        size={size}
        onClose={handleParentModalClose}
        title={title}
        overlayProps={{
          opacity: 0.2,
          blur: 3,
        }}
        {...rest}
      >
        <>
          <LoadingOverlay className={'w-full h-full'} visible={loading} overlayBlur={2} />
          {children}
        </>
      </MantineModal>

      {isVisibleConfirmingModal && (
        <MantineModal
          size={580}
          opened={isConfirmingModalOpen}
          onClose={handleCancelClose}
          title={titleSubModal}
          centered
        >
          <Flex justify={'space-between'}>
            <Button uppercase className={'p-2'} variant="outline" color="indigo" size="md" onClick={handleCancelClose}>
              {textButtonContinue}
            </Button>
            <Button uppercase className={'bg-[red] p-2'} color={'red'} size="md" onClick={handleSubModalClose}>
              {textButtonClosed}
            </Button>
          </Flex>
        </MantineModal>
      )}
    </>
  );
};
