import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ICategory,
  ICategoryForm,
  IEditBody,
  IPaginationResponse,
  IQueryParams,
  ISecondSubCategory,
  ISecondSubCategoryForm,
  ISubCategory,
  ISubCategoryForm,
  IUpdateCategoryIndex,
  IUpdateUsersIndex,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const partnerSectionsAPI = createApi({
  reducerPath: 'partnerSectionsAPI',
  tagTypes: ['partnerSections', 'partnerSubSections', 'partnerSecondSubSections'],
  baseQuery: customFetchBase,
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getPartnerSections: build.query<IPaginationResponse<ICategory[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `cms/partner-sections/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['partnerSections'],
    }),
    getPartnerSectionsInfo: build.query<ICategory, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/partner-sections/${id}/`,
      }),
      providesTags: ['partnerSections'],
    }),
    createPartnerSection: build.mutation<ICategory, ICategoryForm>({
      query: (data) => ({
        url: `cms/partner-sections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['partnerSections'],
    }),
    editPartnerSection: build.mutation<ICategory, IEditBody<ICategoryForm>>({
      query: (data) => ({
        url: `cms/partner-sections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['partnerSections'],
    }),
    deletePartnerSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/partner-sections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['partnerSections'],
    }),
    updatePartnersSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/partner-sections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['partnerSections'],
    }),
    updatePartnersUserSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/partners/update/sections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['partnerSections'],
    }),

    getPartnerSubSections: build.query<IPaginationResponse<ISubCategory[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `cms/partner-subsection-list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['partnerSubSections'],
    }),
    getPartnerSubSectionsInfo: build.query<ISubCategory, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/partner-subsections/${id}/`,
      }),
      providesTags: ['partnerSubSections'],
    }),
    createPartnerSubSection: build.mutation<ISubCategory, ISubCategoryForm>({
      query: (data) => ({
        url: `cms/partner-subsections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['partnerSubSections'],
    }),
    editPartnerSubSection: build.mutation<ISubCategory, IEditBody<ISubCategoryForm>>({
      query: (data) => ({
        url: `cms/partner-subsections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['partnerSubSections'],
    }),
    deletePartnerSubSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/partner-subsections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['partnerSubSections'],
    }),
    updatePartnerSubSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/partner-subsections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['partnerSubSections'],
    }),
    updatePartnerUserSubSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/partners/update/subsections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['partnerSubSections'],
    }),

    getPartnerSecondSubSections: build.query<IPaginationResponse<ISecondSubCategory[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `cms/partner-second-subsection-list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['partnerSecondSubSections'],
    }),
    getPartnerSecondSubSectionsInfo: build.query<IPaginationResponse<ISecondSubCategory[]>, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/partner-second-subsections/${id}/`,
      }),
      providesTags: ['partnerSecondSubSections'],
    }),
    createPartnerSecondSubSection: build.mutation<ISecondSubCategory, ISecondSubCategoryForm>({
      query: (data) => ({
        url: `cms/partner-second-subsections/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['partnerSecondSubSections'],
    }),
    editPartnerSecondSubSection: build.mutation<ISecondSubCategory, IEditBody<ISecondSubCategoryForm>>({
      query: (data) => ({
        url: `cms/partner-second-subsections/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['partnerSecondSubSections'],
    }),
    deletePartnerSecondSubSection: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/partner-second-subsections/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['partnerSecondSubSections'],
    }),
    updatePartnersSecondSubSectionIndex: build.mutation<null, IUpdateCategoryIndex>({
      query: (data) => ({
        url: `cms/partner-second-subsections/update-index/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['partnerSecondSubSections'],
    }),
    updatePartnersUserSecondSubSectionIndex: build.mutation<null, IUpdateUsersIndex>({
      query: (data) => ({
        url: `cms/partners/update/second-subsections-index/${data.id}/`,
        method: 'PUT',
        body: { data: data.data },
      }),
      invalidatesTags: ['partnerSecondSubSections'],
    }),
  }),
});

export const {
  useUpdatePartnerUserSubSectionIndexMutation,
  useUpdatePartnersUserSecondSubSectionIndexMutation,
  useUpdatePartnersSectionIndexMutation,
  useUpdatePartnersSecondSubSectionIndexMutation,
  useUpdatePartnerSubSectionIndexMutation,
  useUpdatePartnersUserSectionIndexMutation,
  useCreatePartnerSecondSubSectionMutation,
  useCreatePartnerSectionMutation,
  useCreatePartnerSubSectionMutation,
  useDeletePartnerSecondSubSectionMutation,
  useDeletePartnerSectionMutation,
  useDeletePartnerSubSectionMutation,
  useEditPartnerSecondSubSectionMutation,
  useEditPartnerSectionMutation,
  useEditPartnerSubSectionMutation,
  useGetPartnerSecondSubSectionsInfoQuery,
  useGetPartnerSecondSubSectionsQuery,
  useGetPartnerSectionsInfoQuery,
  useGetPartnerSectionsQuery,
  useGetPartnerSubSectionsInfoQuery,
  useGetPartnerSubSectionsQuery,
} = partnerSectionsAPI;
