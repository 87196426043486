import { instance } from '7-shared/api';

import { IAdminConfigSet, IUserConfig } from './types';

export const appApi = {
  getConfig(): Promise<IUserConfig> {
    return instance.get(`cms/users/config/`).then((res) => res.data);
  },
  createConfig(data: IAdminConfigSet) {
    return instance.post(`cms/users/set-config/`, data).then((res) => res.data);
  },
};
