import { createApi } from '@reduxjs/toolkit/query/react';
import { IAdvertising, IAdvertisingForm, IEditBody, IPaginationResponse, IQueryParams } from 'interface';
import { customFetchBase } from '../fetchBase';

export const advertisingAPI = createApi({
  reducerPath: 'advertisingAPI',
  tagTypes: ['advertising'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getAdvertising: build.query<IPaginationResponse<IAdvertising[]>, IQueryParams>({
      query: ({ page, size, search, status }) => ({
        url: `/cms/reclames/?page=${page}&page_size=${size}&search=${search ?? ''}&status=${status ?? ''}`,
      }),
      providesTags: ['advertising'],
    }),
    createAdvertising: build.mutation<IAdvertising, IAdvertisingForm>({
      query: (data) => ({
        url: `/cms/reclames/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['advertising'],
    }),
    createAdvertisingGuest: build.mutation<IAdvertising, IAdvertisingForm>({
      query: (data) => ({
        url: `/cms/reclames/guest/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['advertising'],
    }),
    editAdvertising: build.mutation<IAdvertising, IEditBody<IAdvertisingForm>>({
      query: (data) => ({
        url: `/cms/reclames/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['advertising'],
    }),
    deleteAdvertising: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/reclames/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['advertising'],
    }),
  }),
});

export const {
  useCreateAdvertisingMutation,
  useGetAdvertisingQuery,
  useDeleteAdvertisingMutation,
  useEditAdvertisingMutation,
  useCreateAdvertisingGuestMutation,
} = advertisingAPI;
