import { forwardRef } from 'react';
import { IStreets } from '6-entities/plots';
import { IconPencil, IconTrash } from '7-shared/assets';
import { ActionIcon, Flex, Text } from '7-shared/ui';

interface ISelectUserItem {
  value: string;
  label: string;
  onPressCreate?: () => void;
  onPressEdit?: (value: string, label: string) => void;
  onPressDelete?: (value: string) => void;
}

export const mapStreets = (
  streets: IStreets[],
  create: () => void,
  edit: (value: string, label: string) => void,
  remove: (value: string) => void
) => {
  const streetsForSelect = streets.map((i) => ({
    value: i.id.toString(),
    label: i.name,
    onPressEdit: edit,
    onPressDelete: remove,
  }));
  return [{ value: '', label: '+ Добавить новую улицу', onPressCreate: create }, ...streetsForSelect];
};

export const SelectPlotItem = forwardRef<HTMLDivElement, ISelectUserItem>(
  ({ label, onPressDelete, onPressCreate, onPressEdit, value, ...others }: ISelectUserItem, ref) => {
    return (
      <Flex className={'relative'} gap={10} pr={10} align={'center'}>
        <Flex ref={ref} {...others}>
          <Text>{label}</Text>
        </Flex>
        {onPressCreate && (
          <div
            className={'absolute w-full z-[10] top-0 left-0 right-0 bottom-0 bg-transparent cursor-pointer'}
            onClick={() => onPressCreate()}
          />
        )}
        {onPressEdit && (
          <ActionIcon onClick={() => onPressEdit(value, label)}>
            <IconPencil size={18} />
          </ActionIcon>
        )}
        {onPressDelete && (
          <ActionIcon onClick={() => onPressDelete(value)}>
            <IconTrash size={18} />
          </ActionIcon>
        )}
      </Flex>
    );
  }
);
