import React from 'react';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';
import { columns } from '../../model/videosListColumns';
import { selectVideosListTableData } from '6-entities/videos';

export const VideosTable = () => {
  const tableData = useAppSelector(selectVideosListTableData);

  return <Table noHeader={true} columns={columns} rows={tableData.data} tableLoading={tableData.isLoading} />;
};
