import { createAsyncThunk } from '@reduxjs/toolkit';
import { appApi } from '../api/app.api';
import { IRejectRequest } from '7-shared/api/types';
import { IAdminConfigSet } from '../api/types';
import { appNewActions } from '../model/app.slice';
import { mapConfigList } from '../lib/mapConfigList';

export const getConfigThunk = createAsyncThunk<void, void>(
  'app/getConfig',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appNewActions.setIsLoading({ isLoading: true }));
      const result = await appApi.getConfig();
      const data = mapConfigList(result);
      dispatch(appNewActions.setConfig(data));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(appNewActions.setIsLoading({ isLoading: false }));
    }
  }
);

export const createConfigThunk = createAsyncThunk<void, IAdminConfigSet, { state: RootState }>(
  'app/createConfig',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appNewActions.setIsLoading({ isLoading: true }));
      await appApi.createConfig(data);
      dispatch(getConfigThunk());
    } catch (error) {
      return rejectWithValue((error as IRejectRequest<any>)?.response?.data);
    } finally {
      dispatch(appNewActions.setIsLoading({ isLoading: false }));
    }
  }
);
