import { ROLES, STAFF_ROLES } from 'interface';

export const user_roles = [
  {
    role: ROLES.CLIENT,
    name: 'клиент',
    text_color: '#146BBC',
    bg_color: 'rgba(20, 107, 188, 0.07)',
  },
  {
    role: ROLES.OWNER_AND_CLIENT,
    name: 'житель/клиент',
    text_color: '#146BBC',
    bg_color: 'rgba(20, 107, 188, 0.07)',
  },
  {
    role: ROLES.OWNER,
    name: 'житель',
    text_color: '#00A54C',
    bg_color: 'rgba(0, 165, 76, 0.07)',
  },
  {
    role: ROLES.GUEST,
    name: 'гость',
    text_color: 'rgba(224, 108, 25, 1)',
    bg_color: 'rgba(224, 108, 25, 0.07)',
  },
];

export const staff_roles = [
  { role: STAFF_ROLES.DIRECTOR, name: 'Руководитель', color: '' },
  { role: STAFF_ROLES.MANAGER, name: 'Менеджер', color: '' },
  { role: STAFF_ROLES.GUARD, name: 'Охранник', color: '' },
];
