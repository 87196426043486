import React from 'react';
import { IconDots, IconPencil, IconTrash } from '7-shared/assets';
import { ActionIcon, DeleteModal, Menu } from '7-shared/ui';
import { useAppDispatch, useAppSelector, useModal } from '7-shared/hooks';
import { notification } from '7-shared/lib/notification';
import { deleteReportThunk, IReports, reportsActions, selectReportsModalState } from '6-entities/reports';
import { getRealtyThunk, getStreetsListThunk } from '7-shared/api';

interface IProps {
  record: IReports;
}

export const ReportsTableActions = ({ record }: IProps) => {
  const dispatch = useAppDispatch();
  const { isShown, toggle: toogleDeleteModal } = useModal();
  const { isLoading } = useAppSelector(selectReportsModalState);

  const deleteSettlementHandler = () => {
    dispatch(deleteReportThunk(`${record.id}`))
      .unwrap()
      .then(() => toogleDeleteModal())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Удаление отчета запрещено',
        });
      });
  };

  const onEditHandler = async () => {
    await dispatch(getStreetsListThunk(`${record?.settlement?.id}`));
    if (record?.realty) {
      await dispatch(getRealtyThunk(`${record?.realty.street.id}`));
    }
    dispatch(reportsActions.setReportsModalState({ open: true, currentItem: record }));
  };

  return (
    <>
      <DeleteModal
        deleteText={'Вы уверены что хотитет удалить отчет?'}
        onClose={toogleDeleteModal}
        loading={isLoading}
        open={isShown}
        onConfirm={deleteSettlementHandler}
      />
      <Menu shadow="md" width={180}>
        <Menu.Target>
          <ActionIcon>
            <IconDots size={22} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {!record.template && (
            <Menu.Item icon={<IconPencil size={16} />} onClick={onEditHandler}>
              Редактировать
            </Menu.Item>
          )}
          <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={toogleDeleteModal}>
            Удалить
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
