import { ROLES } from '7-shared/accesses';

export enum SERVICE_SUPPORT_STATUS {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  WAITING_RESPONSE = 'waiting_response',
  REDIRECTED = 'redirected',
  CLOSED = 'closed',
  RETURNED_TO_WORK = 'returned_to_work',
}

export interface IServicelSupport {
  id?: number;
  account_id?: string;
  name?: string;
  role?: ROLES;
  phone?: string;
  settlement: string;
  address: string;
  plot: string;
  email?: string;
  comment?: string;
  dateOfCreation?: string;
  dateOfClosed?: string;
  manager?: string;
  score?: number;
  status?: string;
}

export interface IServiceSupportListFilters {
  settlement?: string;
  id?: string;
  account_id?: string;
  name?: string;
  role?: string;
  phone?: string;
  email?: string;
  address_street?: string;
  address_realty?: string;
  plot?: string;
  comment?: string;
  dateOfCreation_start?: string;
  dateOfCreation_end?: string;
  dateOfClosed_start?: string;
  dateOfClosed_end?: string;
  main_time_max?: string;
  main_time_min?: string;
  manager?: string;
  score?: string;
  status?: string;
}
export interface IStreets {
  id: number;
  name: string;
}
export interface IRealty {
  id: number;
  name: string;
}
export interface ISettlement {
  id: number;
  name: string;
}
