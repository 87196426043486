import { type Action, configureStore, type ThunkAction } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';
import { authAPI } from 'store/auth';
import { companyAPI, staffAPI } from 'store/company';
import { usersAPI } from 'store/users';
import { technicalSupportApi, technicalSupportTemplatesApi } from 'store/technicalSupport';
import { realtyAPI, settlementsAPI, streetsAPI } from 'store/settlements';
import { plotsAPI } from 'store/settlements/plots.api';
import { eventAPI, newsAPI, notificationsAPI } from 'store/news';
import { documentsAPI, faqAPI } from 'store/information';
import { reportsAPI, templatesReportsAPI } from 'store/reports';
import { surveyAPI } from 'store/survey';
import { videosAPI } from 'store/videos';
import { mastersAPI, mastersSectionsAPI, publicMastersApi } from 'store/masters';
import { partnersAPI, partnerSectionsAPI, publicPartnersAPI } from 'store/partners';
import { cardContactAPI, contactsAPI, specialContactsAPI } from 'store/contacts';
import { advertisingAPI } from 'store/advertising';
import { statusesAPI } from 'store/news/statuses';
import { videoContentApi } from 'store/videoContent';
import { publicServicesAPI, servicesAPI, servicesSectionsAPI } from 'store/services';
import { serviceSupportApi } from 'store/serviceSupport';
import { accessSystemApi } from 'store/accessSystem';
import { builderSupportApi } from 'store/builderSupport';
import { chatApi } from 'store/chat';
import { fileApi } from 'store/file';
import { personalAdvertisingAPI } from 'store/personalAdvertising';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authAPI.middleware,
      companyAPI.middleware,
      usersAPI.middleware,
      staffAPI.middleware,
      technicalSupportApi.middleware,
      staffAPI.middleware,
      settlementsAPI.middleware,
      streetsAPI.middleware,
      plotsAPI.middleware,
      realtyAPI.middleware,
      newsAPI.middleware,
      realtyAPI.middleware,
      documentsAPI.middleware,
      faqAPI.middleware,
      reportsAPI.middleware,
      surveyAPI.middleware,
      videosAPI.middleware,
      surveyAPI.middleware,
      mastersAPI.middleware,
      mastersSectionsAPI.middleware,
      partnersAPI.middleware,
      partnerSectionsAPI.middleware,
      contactsAPI.middleware,
      cardContactAPI.middleware,
      advertisingAPI.middleware,
      statusesAPI.middleware,
      videoContentApi.middleware,
      templatesReportsAPI.middleware,
      servicesAPI.middleware,
      servicesSectionsAPI.middleware,
      serviceSupportApi.middleware,
      accessSystemApi.middleware,
      builderSupportApi.middleware,
      chatApi.middleware,
      fileApi.middleware,
      technicalSupportTemplatesApi.middleware,
      specialContactsAPI.middleware,
      technicalSupportTemplatesApi.middleware,
      eventAPI.middleware,
      publicPartnersAPI.middleware,
      publicMastersApi.middleware,
      publicServicesAPI.middleware,
      notificationsAPI.middleware,
      personalAdvertisingAPI.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
