import React, { useEffect } from 'react';
import { Button, Flex, Title } from '7-shared/ui';
import { BuilderTemplateForm, BuilderTemplatesPagination, BuilderTemplatetTable } from '5-features/builderSupport';
import { IconPlus } from '7-shared/assets';
import { useAppDispatch, useAppSelector, useQueryParams } from '7-shared/hooks';
import { getTemplatesListThunk, selectTemplateListPagination, templatesActions } from '6-entities/templates';

export const BuilderSupportTemplates = () => {
  const dispatch = useAppDispatch();

  const { onChange, pagination } = useQueryParams();

  const tablePagination = useAppSelector(selectTemplateListPagination);

  const onPressAddHandler = () => {
    dispatch(templatesActions.setTemplateModalState({ open: true }));
  };
  useEffect(() => {
    if (tablePagination?.page) {
      dispatch(getTemplatesListThunk('builder'));
    }
  }, [tablePagination?.page]);

  useEffect(() => {
    if (!pagination?.page) {
      return;
    }
    if (tablePagination?.page !== Number(pagination?.page)) {
      dispatch(templatesActions.setListPagination({ page: Number(pagination?.page) }));
    }
  }, [pagination?.page]);
  return (
    <Flex direction={'column'} gap={10}>
      <Flex justify={'space-between'} align={'center'}>
        <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
          Шаблоны
        </Title>
        <Button rightIcon={<IconPlus size={20} />} className={'w-max'} variant="outline" onClick={onPressAddHandler}>
          Добавить шаблон
        </Button>
      </Flex>
      <BuilderTemplatetTable />
      <BuilderTemplatesPagination onChange={onChange} />
      <BuilderTemplateForm />
    </Flex>
  );
};
