import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITablePagination, ITableState } from '7-shared/types';

import { IBuilderSupport, IBuilderSupportListFilters } from './types';

interface IInitialState {
  table: ITableState<IBuilderSupport>;
  pagination: ITablePagination;
  filters: IBuilderSupportListFilters;
}

const initialState: IInitialState = {
  table: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 0,
    total_count: 0,
    page_size: 10,
  },
  filters: {
    settlement: undefined,
    id: undefined,
    account_id: undefined,
    name: undefined,
    role: undefined,
    phone: undefined,
    email: undefined,
    address_street: undefined,
    address_realty: undefined,
    plot: undefined,
    comment: undefined,
    dateOfCreation_start: undefined,
    dateOfCreation_end: undefined,
    dateOfClosed_start: undefined,
    dateOfClosed_end: undefined,
    main_time_max: undefined,
    main_time_min: undefined,
    manager: undefined,
    score: undefined,
    status: undefined,
  },
};

export const builderSupportSlice = createSlice({
  name: 'builderSupport',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<IBuilderSupport>>>) => {
      state.table = { ...state.table, ...action.payload };
    },

    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setListFilters: (state, action: PayloadAction<Partial<IBuilderSupportListFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setInitialBuilderSupportstate: () => initialState,
  },
});

export const selectBuilderSupportListTableData = (state: RootState) => state.builderSupport.table;
export const selectBuilderSupportListPagination = (state: RootState) => state.builderSupport.pagination;
export const selectBuilderSupportListFilters = (state: RootState) => state.builderSupport.filters;

export const builderSupportActions = builderSupportSlice.actions;
