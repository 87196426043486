import { IReports } from '../model/types';
import { IReport } from '../api/types';

export function mapReportsList(dto: IReport): IReports {
  return {
    id: dto.id,
    title: dto.title,
    description: dto.description,
    preview: dto.preview,
    settlement: dto.settlement
      ? { id: dto.settlement.id, name: dto.settlement.name }
      : { id: dto.realty.street.settlement.id, name: dto.realty.street.settlement.name },
    link: dto.link,
    realty: dto.realty,
    created_by_template: dto.created_by_template,
    template: dto.template,
    type: dto.type,
  };
}
