import React, { ChangeEvent, SetStateAction } from 'react';
import { Button, Flex, MantineTextInput, Text } from '7-shared/ui';

import { IStreetFormData } from '../../../model/streetFormData';

interface IProps {
  formData: IStreetFormData;
  setFormData: React.Dispatch<SetStateAction<IStreetFormData>>;
  onConfirmEditHandler: () => void;
  onConfirmCreateHandler: () => void;
}

export const CustomStreetForm = ({ formData, setFormData, onConfirmEditHandler, onConfirmCreateHandler }: IProps) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setFormData({
        ...formData,
        label: e.target.value,
        error: 'Это поле обязательное',
      });
      return;
    }
    setFormData({
      ...formData,
      label: e.target.value,
      error: e?.target?.value?.length >= 200 ? 'Максимальная длина символов 200' : undefined,
    });
  };

  const onClickSaveHandler = () => {
    if (formData.label.length === 0) {
      setFormData({
        ...formData,
        error: 'Это поле обязательное',
      });
      return;
    }
    formData.mode === 'create' ? onConfirmCreateHandler() : onConfirmEditHandler();
  };

  if (!formData.showInput) {
    return null;
  }

  return (
    <Flex direction={'column'} gap={10}>
      {<Text>{formData.mode === 'create' ? 'Добавление новой улицы' : 'Редактирование улицы'}</Text>}
      <MantineTextInput
        withAsterisk
        value={formData.label}
        onChange={onChangeHandler}
        label={'Наименование'}
        placeholder={'Наименование'}
        error={formData?.error}
      />
      <Button onClick={onClickSaveHandler} fullWidth type={'button'}>
        {formData.mode === 'create' ? 'Создать' : 'Сохранить'}
      </Button>
    </Flex>
  );
};
