export const localStorageUtils = {
  getValue(key: string) {
    return localStorage.getItem(key);
  },
  setValue(key: string, value: string) {
    return localStorage.setItem(key, value);
  },
  removeValue(key: string) {
    return localStorage.removeItem(key);
  },
};
