export const getMissingKeys = (strings: string[], objects: any): string[] => {
  const keys = Object.keys(objects);
  return keys.filter((key) => {
    if (
      (strings.includes('dateOfCreation') && key === 'dateOfCreation_start') ||
      (strings.includes('dateOfCreation') && key === 'dateOfCreation_end')
    ) {
      return false;
    }
    if (
      (strings.includes('dateOfClosed') && key === 'dateOfClosed_start') ||
      (strings.includes('dateOfClosed') && key === 'dateOfClosed_end')
    ) {
      return false;
    }
    if (key === 'main_time_min' || key === 'main_time_max') {
      return false;
    } else return !strings.includes(key);
  });
};

export const getRemoteKeysObject = (strings: string[], objects: any): Record<string, undefined> => {
  const keys = Object.keys(objects);
  const missingKeys = keys.filter((key) => {
    if (
      (strings.includes('dateOfCreation') && key === 'dateOfCreation_start') ||
      (strings.includes('dateOfCreation') && key === 'dateOfCreation_end')
    ) {
      return false;
    }
    if (
      (strings.includes('dateOfClosed') && key === 'dateOfClosed_start') ||
      (strings.includes('dateOfClosed') && key === 'dateOfClosed_end')
    ) {
      return false;
    }
    if (key === 'main_time_min' || key === 'main_time_max') {
      return false;
    } else return !strings.includes(key);
  });
  return missingKeys.reduce((obj: Record<string, undefined>, string: string) => {
    obj[string] = undefined;
    return obj;
  }, {});
};
