import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditBody,
  IPaginationQuery,
  IPaginationResponse,
  IPartner,
  IPartnerForm,
  IPartnerScore,
  IPartnersStatisticQueryParams,
  IPartnerStatistic,
  IQueryParams,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const partnersAPI = createApi({
  reducerPath: 'partnersAPI',
  tagTypes: ['partners', 'pre-partners'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getPartners: build.query<IPaginationResponse<IPartner[]>, IQueryParams>({
      query: ({ page, size, search, status, time_created__gte, time_created__lte }) => ({
        url: `/cms/partners/`,
        params: { page, page_size: size, search, status__in: status, time_created__gte, time_created__lte },
      }),
      providesTags: ['partners'],
    }),
    createPartner: build.mutation<IPartner, IPartnerForm>({
      query: (data) => ({
        url: `cms/partners/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['partners'],
    }),
    editPartner: build.mutation<IPartner, IEditBody<IPartnerForm>>({
      query: (data) => ({
        url: `cms/partners/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['partners'],
    }),
    deletePartner: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/partners/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['partners'],
    }),
    getStatistics: build.query<
      IPaginationResponse<IPartnerStatistic[]>,
      Record<IPartnersStatisticQueryParams, string | number | undefined>
    >({
      query: ({ page, size, time_lte, time_gte }) => ({
        url: `/cms/partners/statistic/?page=${page}&page_size=${size}&time_lte=${time_lte ?? ''}&time_gte=${
          time_gte ?? ''
        }`,
      }),
    }),
    getPartnersScore: build.query<
      IPaginationResponse<IPartnerScore[]>,
      Record<IPaginationQuery, string | number | undefined>
    >({
      query: ({ page, size }) => ({
        url: `/cms/partners/score/?page=${page}&page_size=${size}`,
      }),
    }),
    getPrePartners: build.query<IPaginationResponse<IPartner[]>, IQueryParams>({
      query: ({ page, size, search, status, time_created__gte, time_created__lte }) => ({
        url: `/cms/pre-partners/`,
        params: { page, page_size: size, search, status__in: status, time_created__gte, time_created__lte },
      }),
      providesTags: ['pre-partners'],
    }),
    editPrePartner: build.mutation<IPartner, IEditBody<IPartnerForm>>({
      query: (data) => ({
        url: `cms/pre-partners/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['pre-partners', 'partners'],
    }),
    deletePrePartner: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/pre-partners/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['pre-partners'],
    }),
  }),
});

export const {
  useCreatePartnerMutation,
  useDeletePartnerMutation,
  useEditPartnerMutation,
  useGetPartnersQuery,
  useGetStatisticsQuery,
  useGetPartnersScoreQuery,
  useEditPrePartnerMutation,
  useGetPrePartnersQuery,
  useDeletePrePartnerMutation,
} = partnersAPI;
