import React from 'react';
import { DataTable } from 'mantine-datatable';
import { useStyles } from './styles';
import { Flex } from '7-shared/ui';

interface IProps {
  rows?: any[];
  columns: any[];
  tableLoading: boolean;
  wholeScreen?: boolean;
  noHeader?: boolean;
  rowStyle?: ({ type }: { type: any }) => Record<string, string> | undefined;
}

export const Table = ({ rows, columns, tableLoading, wholeScreen = true, noHeader = false, rowStyle }: IProps) => {
  const { classes } = useStyles({ wholeScreen });

  return (
    <Flex>
      <div style={{ width: wholeScreen ? '100%' : 'min-content' }}>
        <DataTable
          rowStyle={rowStyle}
          fetching={tableLoading}
          noHeader={noHeader}
          classNames={classes}
          horizontalSpacing="md"
          verticalSpacing="md"
          fontSize="md"
          minHeight={'70vh'}
          withBorder
          striped
          highlightOnHover
          columns={columns}
          records={rows}
        />
      </div>
    </Flex>
  );
};
