import React from 'react';
import { IPlots } from '6-entities/plots';
import { Tooltip } from '7-shared/ui';

import { PlotsTableActions } from '../ui/plotsTableActions/plotsTableActions';

export interface PlotsColumnTable {
  accessor: keyof IPlots;
  title: string | JSX.Element;
  render?: (value: IPlots, index: number) => JSX.Element;
  width?: number;
  ellipsis?: boolean;
}

export const columns: PlotsColumnTable[] = [
  {
    accessor: 'landPlotNumber',
    title: 'Номер ЗУ',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value.landPlotNumber}`}>
        {value.landPlotNumber}
      </Tooltip>
    ),
  },
  {
    accessor: 'account',
    title: 'Лицевой счет',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.account}`}>
        {value?.account ?? ''}
      </Tooltip>
    ),
  },
  {
    accessor: 'street',
    title: 'Улица',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.street}`}>
        {value?.street ?? ''}
      </Tooltip>
    ),
  },
  {
    accessor: 'house',
    title: 'Дом',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.house}`}>
        {value?.house ?? ''}
      </Tooltip>
    ),
  },
  {
    accessor: 'frame',
    title: 'Корпус',
    render: (value, index) => (
      <Tooltip className={'max-w-[600px]'} index={index} tooltip={`${value?.frame}`}>
        {value?.frame ?? ''}
      </Tooltip>
    ),
  },
  {
    accessor: 'settings',
    title: '',
    render: (value) => <PlotsTableActions record={value} />,
  },
];
