import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditBody,
  IPaginationResponse,
  IQueryParams,
  ITechnicalSupport,
  ITechnicalSupportInfoForm,
  ITechnicalSupportQueryParams,
  ITechnicalSupportReplace,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const technicalSupportApi = createApi({
  reducerPath: 'technicalSupportApi',
  tagTypes: ['technicalSupportList', 'technicalSupportListItem'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getTechnicalSupportItem: build.query<ITechnicalSupport, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/feedback/${id}`,
      }),
      providesTags: ['technicalSupportListItem'],
    }),
    createTechnicalSupportComment: build.mutation<ITechnicalSupport, IEditBody<ITechnicalSupportInfoForm>>({
      query: (data) => ({
        url: `cms/feedback/response/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['technicalSupportListItem', 'technicalSupportList'],
    }),
    replaceTechnicalSupportItem: build.mutation<ITechnicalSupport, IEditBody<ITechnicalSupportReplace>>({
      query: (data) => ({
        url: `cms/feedback-any/redirect/${data.id}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: ['technicalSupportList'],
    }),
  }),
});

export const {
  useCreateTechnicalSupportCommentMutation,
  useGetTechnicalSupportItemQuery,
  useReplaceTechnicalSupportItemMutation,
} = technicalSupportApi;
