import React, { useState } from 'react';
import { Button, Flex, IFilterSchema, Modal, TableFilters } from '7-shared/ui';
import { useAppDispatch } from '7-shared/hooks';
import { format, parseISO } from 'date-fns';
import {
  builderSupportActions,
  getBuilderSupportReportThunk,
  IBuilderSupportListFilters,
} from '6-entities/builderSupport';

interface IProps {
  onChange: (key: keyof IBuilderSupportListFilters, value: string) => void;
  onClear: (key: keyof IBuilderSupportListFilters) => void;
  params?: IBuilderSupportListFilters;
}

export const BuilderSupportReports = ({ onChange, onClear, params }: IProps) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const onChangeFilter = (key: keyof IBuilderSupportListFilters, value: string) => {
    onChange(key, value);
    dispatch(builderSupportActions.setListPagination({ page: 1 }));
    dispatch(builderSupportActions.setListFilters({ ...params, [key]: value }));
  };

  const onClearFilter = (key: keyof IBuilderSupportListFilters) => {
    onClear(key);
    dispatch(builderSupportActions.setListPagination({ page: 1 }));
    dispatch(builderSupportActions.setListFilters({ ...params, [key]: undefined }));
  };
  const disabledButton = !params?.main_time_min || !params?.main_time_max;
  const title =
    params?.main_time_min && params?.main_time_max
      ? `Отчет с ${format(parseISO(params?.main_time_min), 'dd-MM-yyyy')} по ${format(
          parseISO(params?.main_time_max),
          'dd-MM-yyyy'
        )}`
      : '';

  const downloadExelReportHandler = () => {
    if (params?.main_time_min && params?.main_time_max) {
      dispatch(getBuilderSupportReportThunk());
      setIsOpen(false);
    }
  };

  const onCloseModalHandler = () => {
    setIsOpen(false);
  };
  const onOpenModalHandler = () => {
    setIsOpen(true);
  };

  const filtersSchema: IFilterSchema<IBuilderSupportListFilters>[] = [
    {
      name: 'main_time_min',
      type: 'date',
      placeholder: 'C',
      className: 'min-w-[170px] max-w-[170px]',
      value: params?.main_time_min,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
    {
      name: 'main_time_max',
      type: 'date',
      placeholder: 'По',
      className: 'min-w-[170px] max-w-[170px]',
      value: params?.main_time_max,
      minDate: params?.main_time_min,
      onChange: onChangeFilter,
      onClear: onClearFilter,
    },
  ];
  return (
    <>
      <Flex justify={'flex-end'}>
        <Flex className={'w-[590px]'}>
          <TableFilters schema={filtersSchema} />
          <Button disabled={disabledButton} onClick={onOpenModalHandler} size={'md'} className={'bg-[#228be6] mr-3'}>
            Сформировать отчет
          </Button>
        </Flex>
      </Flex>
      <Modal onClose={onCloseModalHandler} opened={isOpen} title={title}>
        <Button className={'w-[410px]'} onClick={downloadExelReportHandler}>
          Скачать файл Excel
        </Button>
      </Modal>
    </>
  );
};
