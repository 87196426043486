import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IEditBody,
  IPersonalAdvertising,
  IPersonalAdvertisingForm,
  IPaginationResponse,
  IQueryParams,
} from 'interface';
import { customFetchBase } from '../fetchBase';

export const personalAdvertisingAPI = createApi({
  reducerPath: 'personalAdvertisingAPI',
  tagTypes: ['personal_reclames'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getPersonalAdvertising: build.query<IPaginationResponse<IPersonalAdvertising[]>, IQueryParams>({
      query: ({ page, size, search, status }) => ({
        url: `/cms/personal_reclames/?page=${page}&page_size=${size}&search=${search ?? ''}&status=${status ?? ''}`,
      }),
      providesTags: ['personal_reclames'],
    }),
    createPersonalAdvertising: build.mutation<IPersonalAdvertising, IPersonalAdvertisingForm>({
      query: (data) => ({
        url: `/cms/personal_reclames/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['personal_reclames'],
    }),
    editPersonalAdvertising: build.mutation<IPersonalAdvertising, IEditBody<IPersonalAdvertisingForm>>({
      query: (data) => ({
        url: `/cms/personal_reclames/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['personal_reclames'],
    }),
    deletePersonalAdvertising: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/personal_reclames/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['personal_reclames'],
    }),
  }),
});

export const {
  useCreatePersonalAdvertisingMutation,
  useDeletePersonalAdvertisingMutation,
  useEditPersonalAdvertisingMutation,
  useGetPersonalAdvertisingQuery,
} = personalAdvertisingAPI;
