import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IAccessSystem,
  IAccessSystemReportFilters,
  IEditBody,
  IOnTerritoryAccessSystem,
  IPaginationResponse,
  IPerformAccessSystem,
  IQueryParams,
} from 'interface';
import { customFetchBase } from '../fetchBase';
import { localStorageUtils } from 'shared/utils';
import { AUTH_TOKEN } from 'shared/constants';
import { showNotification } from '@mantine/notifications';

export const accessSystemApi = createApi({
  reducerPath: `accessSystemApi`,
  tagTypes: ['accessSystem'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getAccessSecurityList: build.query<IPaginationResponse<IAccessSystem[]>, IQueryParams>({
      query: ({ page, size, id, search, status, travel_date__lte, travel_date__gte }) => ({
        url: `/cms/security-passes/${id}`,
        params: { page, page_size: size, search, status, travel_date__lte, travel_date__gte },
      }),
      providesTags: ['accessSystem'],
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const accessToken = localStorageUtils.getValue(AUTH_TOKEN.access);
        await cacheDataLoaded;
        const ws = new WebSocket(
          `${process.env?.REACT_APP_WEBSOCKETS_SECURITY_PASSES_URL}/${arg.id}/?token=${accessToken}`
        );
        try {
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            updateCachedData((draft) => {
              draft.results.unshift(data.data);
            });
          };

          ws.addEventListener('message', listener);
        } catch {
          showNotification({
            color: 'red',
            title: 'Ошибка',
            message: 'Что то пошло не так',
          });
        }
        await cacheEntryRemoved;
        ws.close();
      },
    }),
    performAccessSecurityItem: build.mutation<null, IEditBody<IPerformAccessSystem>>({
      query: (data) => ({
        url: `/cms/security-passes/perform/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['accessSystem'],
    }),
    onTerritoryAccessSystemItem: build.mutation<null, IEditBody<IOnTerritoryAccessSystem>>({
      query: (data) => ({
        url: `/cms/security-passes/on-territory/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['accessSystem'],
    }),
    createExelAccessSystemReport: build.mutation<null, IAccessSystemReportFilters>({
      query: (data) => ({
        url: '/cms/security-passes/upload/report/',
        method: 'POST',
        body: data,
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        cache: 'no-cache',
      }),
    }),
    getLandPlotsOwners: build.query<any, number>({
      query: (id) => ({
        url: `/cms/land-plot/owners/${id}/`,
      }),
    }),
  }),
});

export const {
  useGetAccessSecurityListQuery,
  usePerformAccessSecurityItemMutation,
  useOnTerritoryAccessSystemItemMutation,
  useCreateExelAccessSystemReportMutation,
  useGetLandPlotsOwnersQuery,
} = accessSystemApi;
