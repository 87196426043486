import { IUserStreet } from '../../user/model/types';

export interface ITemplateReports {
  id: number;
  time_created: string;
  time_updated: string;
  title: string;
  description: string;
  preview: string;
  actions?: string;
}

export interface IReports extends ITimeStamps {
  id: number;
  title: string;
  description: string;
  preview: string;
  type: REPORT_TYPE;
  link: string;
  address: string;
  settlement: ISettlement;
  realty: IUserRealty;
  created_by_template: boolean;
  template: number;
}

export interface ITemplateReportsForm {
  title: string;
  description: string;
  preview: string;
}

export interface ISettlement extends ISettlementForm {
  id: number;
  is_guest: boolean;
}

export interface ISettlementForm {
  name: string;
  address: string;
  description?: string;
}

export interface IUserRealty extends ITimeStamps {
  corpus: string;
  id: number;
  number: string;
  street: IUserStreet;
}

export interface ITimeStamps {
  time_created: string;
  time_updated: string;
}

export enum REPORT_TYPE {
  personal = 'personal',
  common = 'common',
  template = 'template',
}

export interface IReport extends ITimeStamps {
  id: number;
  title: string;
  description: string;
  preview: string;
  type: REPORT_TYPE;
  link: string;
  address: string;
  settlement: ISettlement;
  realty: IUserRealty;
  created_by_template: boolean;
  template: number;
}
