import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppDispatch, useAppSelector } from '7-shared/hooks';
import { Button, Input, Modal } from '7-shared/ui';
import {
  createCameraThunk,
  editCameraThunk,
  ICameraForm,
  pauseStreamThunk,
  selectCamerasModalState,
  selectSettlement,
  selectStreamUrl,
  startStreamThunk,
  stopStreamThunk,
  videosActions,
} from '6-entities/videos';
import { isEmpty } from '7-shared/lib/lodash';
import { notification } from '7-shared/lib/notification';
import { ReactComponent as PlayIcon } from '7-shared/assets/icons/cameraPlay.svg';
import { ReactComponent as PauseIcon } from '7-shared/assets/icons/cameraStop.svg';
import { cameraSchema } from '../../../model/camera.schema';
import ReactPlayer from 'react-player';
import { ConnectCameraModal } from '../connectCameraModal/connectCameraModal';

export const CameraForm = () => {
  window.onunload = () => stopStreamHandler();
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<boolean>(true);
  const { open, currentItem, isLoading } = useAppSelector(selectCamerasModalState);
  const streamUrl = useAppSelector(selectStreamUrl);
  const settlement = useAppSelector(selectSettlement);
  const playerRef = React.useRef<any>();
  const { control, handleSubmit, reset } = useForm<ICameraForm>({
    mode: 'onChange',
    resolver: yupResolver(cameraSchema),
  });
  const onCloseModalHandler = async () => {
    await stopStreamHandler();
    dispatch(videosActions.setCamerasModalState({ open: false, currentItem: undefined }));
    dispatch(videosActions.setStreamUrl(''));
    reset();
  };
  useEffect(() => {
    return () => {
      stopStreamHandler();
    };
  }, []);

  //Заполнение значениеями по умолчанию, при редактировании камеры
  useEffect(() => {
    if (open) {
      reset({
        name: currentItem?.name,
        login: currentItem?.login,
        password: currentItem?.password,
        ip: currentItem?.ip,
        endpoint: currentItem?.endpoint,
        active: currentItem?.active,
        video_id: currentItem?.video_id,
      });
    }
  }, [currentItem, open, reset]);

  const startStreamHandler = async () => {
    if (!active) {
      await dispatch(startStreamThunk(currentItem?.id as number));
      setActive(true);
    }
  };

  const stopStreamHandler = async () => {
    if (!isEmpty(currentItem)) {
      await dispatch(stopStreamThunk(currentItem.id));
    }
  };

  const pauseStreamHandler = async () => {
    if (active) {
      await dispatch(pauseStreamThunk(currentItem?.id as number));
      setActive(false);
    }
  };

  const onSubmit = (data: ICameraForm) => {
    const newData = {
      name: data.name,
      login: data.login,
      password: data.password,
      ip: data.ip,
      endpoint: data.endpoint,
      active: data.active,
      video_id: settlement.video_id,
    };
    if (isEmpty(currentItem)) {
      createCameraHandler(newData);
    } else {
      editCameraHandler(newData);
    }
  };

  const createCameraHandler = (data: ICameraForm) => {
    dispatch(createCameraThunk(data))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error ?? 'Создание камеры запрещено',
        });
      });
  };

  const editCameraHandler = (data: ICameraForm) => {
    dispatch(editCameraThunk({ id: currentItem?.id as number, data }))
      .unwrap()
      .then(() => onCloseModalHandler())
      .catch((error) => {
        notification({
          status: 'error',
          title: 'Ошибка',
          message: error.message ?? 'Редактирование камеры запрещено',
        });
      });
  };

  return (
    <>
      <ConnectCameraModal visible={!isEmpty(currentItem) && !streamUrl} />
      <Modal
        onClose={onCloseModalHandler}
        loading={isLoading}
        opened={!isEmpty(currentItem) ? open && !!streamUrl : open}
        size={'auto'}
        title={isEmpty(currentItem) ? 'Добавление камеры' : 'Редактирование камеры'}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={'h-auto flex flex-col gap-2 w-full items-center justify-center'}
        >
          {!isEmpty(currentItem) && active && (
            <ReactPlayer playerRef={playerRef} url={streamUrl} playing={true} width={400} height="auto" />
          )}
          {!isEmpty(currentItem) && (
            <div className={'flex flex-row items-center justify-center gap-5'}>
              <div className={'flex flex-col gap-1 justify-center items-center'}>
                <PlayIcon
                  onClick={() => startStreamHandler()}
                  className={`cursor-pointer ${active ? 'fill-[#A6A6A6]' : 'fill-[rgba(20, 107, 188, 1)]'}`}
                />
                <span className={''}>Старт</span>
              </div>
              <div className={'flex flex-col gap-1 justify-center items-center'}>
                <PauseIcon
                  onClick={pauseStreamHandler}
                  className={`cursor-pointer cursor-pointer ${
                    !currentItem?.active ? 'fill-[#A6A6A6]' : 'fill-[rgba(20, 107, 188, 1)]'
                  }`}
                />
                <span className={'action-text'}>Пауза</span>
              </div>
            </div>
          )}
          <Input className={'w-full'} name={'name'} control={control} placeholder={'Название'} />
          <Input className={'w-full'} name={'login'} control={control} placeholder={'Логин'} />
          <Input className={'w-full'} name={'password'} control={control} placeholder={'Пароль'} />
          <Input className={'w-full'} name={'ip'} control={control} placeholder={'Ip'} />
          <Input className={'w-full'} name={'endpoint'} control={control} placeholder={'Эндпоинт'} />
          <Button mt={30} size={'md'} type={'submit'} fullWidth className={'mt-[15px] w-full'}>
            {isEmpty(currentItem) ? 'Сохранить камеру' : 'Сохранить изменения'}
          </Button>
        </form>
      </Modal>
    </>
  );
};
