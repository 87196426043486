import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalState, ITablePagination, ITableState } from '7-shared/types';
import { ICamera, IVideo } from '../api/types';
import { ISettlement } from '../model/types';

interface IInitialState {
  streamUrl: string;
  settlement: ISettlement;
  table: ITableState<IVideo>;
  tableCameras: ITableState<ICamera>;
  pagination: ITablePagination;
  modal: IModalState<IVideo>;
  modalCameras: IModalState<ICamera>;
}

const initialState: IInitialState = {
  streamUrl: '',
  settlement: {} as ISettlement,
  table: {
    data: [],
    isLoading: false,
  },
  tableCameras: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 1,
    total_count: 0,
    page_size: 10,
  },
  modal: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
  modalCameras: {
    open: false,
    currentItem: undefined,
    isLoading: false,
  },
};

export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    setListTableData: (state, action: PayloadAction<Partial<ITableState<any>>>) => {
      state.table = { ...state.table, ...action.payload };
    },
    setListTableCamerasData: (state, action: PayloadAction<Partial<ITableState<ICamera>>>) => {
      state.tableCameras = { ...state.tableCameras, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setVideosModalState: (state, action: PayloadAction<Partial<IModalState<IVideo>>>) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setCamerasModalState: (state, action: PayloadAction<Partial<IModalState<ICamera>>>) => {
      state.modalCameras = { ...state.modalCameras, ...action.payload };
    },
    setSettlement: (state, action: PayloadAction<ISettlement>) => {
      state.settlement = action.payload;
    },
    setStreamUrl: (state, action: PayloadAction<string>) => {
      state.streamUrl = action.payload;
    },
    setInitialVideosState: () => initialState,
  },
});

export const selectVideosListTableData = (state: RootState) => state.videos.table;
export const selectVideosListTableCamerasData = (state: RootState) => state.videos.tableCameras;
export const selectVideosListPagination = (state: RootState) => state.videos.pagination;
export const selectIsLoading = (state: RootState) => state.videos.table.isLoading;
export const selectVideosModalState = (state: RootState) => state.videos.modal;
export const selectCamerasModalState = (state: RootState) => state.videos.modalCameras;
export const selectSettlement = (state: RootState) => state.videos.settlement;
export const selectStreamUrl = (state: RootState) => state.videos.streamUrl;

export const videosActions = videosSlice.actions;
