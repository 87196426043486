import React from 'react';
import { BackArrow, Flex, Title } from '7-shared/ui';
import { SettlementsForm } from '5-features/settlements';
import { useParams } from 'react-router-dom';

export const CreateSettlementPage = () => {
  const { id } = useParams();
  return (
    <Flex direction={'column'} className={'w-full h-full'} gap={10}>
      <Flex className={'flex flex-row gap-3 h-max items-center'} align={'center'}>
        <BackArrow />
        <Title order={2} className={'truncate overflow-hidden max-w-[720px]'}>
          {id ? 'Редактирование поселка' : 'Создание коттеджного поселка'}
        </Title>
      </Flex>
      <SettlementsForm />
    </Flex>
  );
};
