import { instance } from '7-shared/api';
import { IPaginationResponse } from '7-shared/types';
import { ITemplates, ITemplatesForm, ITemplatesType } from './types';

export const templatesApi = {
  getTechnicalTemplatesList(
    page: number,
    page_size: number,
    type: ITemplatesType
  ): Promise<IPaginationResponse<ITemplates>> {
    return instance
      .get('cms/feedback-templates/', {
        params: { page, page_size, type },
      })
      .then((res) => res.data);
  },
  createTechnicalTemplateComment(data: ITemplatesForm) {
    return instance.post(`cms/feedback-templates/`, data).then((res) => res.data);
  },
  editTechnicalTemplateComment(id: number, data: ITemplatesForm) {
    return instance.put(`cms/feedback-templates/${id}/`, data).then((res) => res.data);
  },
  deleteTechnicalTemplateComment(id: number) {
    return instance.delete(`cms/feedback-templates/${id}/`).then((res) => res.data);
  },
};
