import { SelectItem } from '@mantine/core';

export interface IQueryParams {
  page?: number;
  size?: number;
  id?: number | string;
  search?: string;
  role?: string;
  type?: string;
  accepted?: string;
  settlements__name?: string;
  settlement_id?: string;
  street_id?: string;
  realty_id?: string;
  status?: string;
  travel_date__gte?: string;
  travel_date__lte?: string;
  expire_date__gte?: string;
  expire_date__lte?: string;
  time_created__gte?: string;
  time_created__lte?: string;
  ordering?: string;
  limit?: string;
  account__user_role?: string;
  with_owners?: boolean;
}

export interface ITimeStamps {
  time_created: string;
  time_updated: string;
}

export interface IPaginationResponse<D> {
  count: number;
  next: string;
  prev: string;
  results: D;
}

export interface IEditBody<B> {
  id: number;
  body: B;
}

export type IPaginationQuery = 'page' | 'size';

export interface IOption {
  id: string | number;
  value: string;
  textColor?: string;
}

export interface IPagination {
  onChange: (fieldName: string, page: string) => void;
  currentPage: number;
  limit: number;
}

export interface IFilterSchema<T> {
  name: T;
  type: 'input' | 'select' | 'date';
  options?: (string | SelectItem)[];
  placeholder: string;
  className?: string;
  value?: string;
  onChange: (fieldName: string, value: string) => void;
  onClear: (fieldName: string) => void;
  minDate?: string;
  maxDate?: string;
}

export interface IFilter {
  name: string;
  type: string;
  options?: IOption[];
  placeholder: string;
  className: string;
}

export enum QUERY_PARAMS {
  PAGE = 'page',
  SIZE = 'size',
}

export enum NOTIFICATION_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum PERMISSIONS {
  USERS = 'crud_users',
  SETTLEMENTS = 'crud_settlements',
  SURVEYS = 'crud_surveys',
  NEWS = 'crud_news',
  INFORMATION = 'crud_informations',
  VIDEO = 'crud_video',
  REPORTS = 'crud_reports',
  TECHNICAL_SUPPORT = 'crud_feedback',
  SERVICE_SUPPORT = 'crud_feedback_communal',
  COMPANIES = 'crud_companies',
  MASTERS = 'crud_masters',
  PARTNERS = 'crud_partners',
  SERVICES = 'crud_servicings',
  CONTACTS = 'crud_contacts',
  ADVERTISING = 'crud_reclames',
  VIDEO_CONTENT = 'crud_videolinks',
  ACCESS_SYSTEM = 'crud_security_passes',
  BUILDER_SUPPORT = 'crud_feedback_builder',
}
