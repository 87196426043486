import { ITechnicalSupport, TECHNICAL_SUPPORT_STATUS } from '../model/types';
import { ITechnicalSupportDto } from '../api/types';
import { formatDate, getFullName, getUserAddress } from '7-shared/utils';
import { isEmpty } from '7-shared/lib/lodash';

export function mapTechnicalSupportList(dto: ITechnicalSupportDto): ITechnicalSupport {
  return {
    id: dto.id,
    account_id: dto?.user_address?.account ?? '',
    name: isEmpty(dto?.guest_data) ? getFullName(dto?.user) : dto?.guest_data?.full_name,
    role: isEmpty(dto?.guest_data) ? dto?.user_address?.user_role : dto?.user?.role,
    phone: isEmpty(dto?.guest_data) ? dto?.user?.phone : dto?.guest_data?.phone,
    email: dto?.email,
    settlement: dto?.user_address?.settlement?.name,
    address:
      getUserAddress(
        undefined,
        dto?.user_address?.address?.street__name,
        dto?.user_address?.address?.number,
        dto?.user_address?.address?.corpus
      ) ?? '',
    plot: dto?.user_address?.land_plot_number,
    comment: dto?.message,
    dateOfCreation: formatDate(dto?.time_created, 'dd/MM/yyyy'),
    dateOfClosed: dto?.time_closed ? formatDate(dto?.time_closed, 'dd/MM/yyyy') : '',
    manager: `${dto?.manager?.last_name ?? ''} ${dto?.manager?.first_name ?? ''}`,
    score: dto?.score as number,
    status: dto.status as TECHNICAL_SUPPORT_STATUS,
  };
}
