import { Textarea as MantineTextarea, type TextareaProps } from '@mantine/core';
import React from 'react';
import { Control, FieldValues, Path, PathValue, useController } from 'react-hook-form';

interface IProps<T extends FieldValues> extends Omit<TextareaProps, 'value' | 'defaultValue'> {
  name: Path<T>;
  control: Control<T, Path<T>>;
  defaultValue?: PathValue<T, Path<T>>;
}

export const Textarea = <T extends FieldValues>({ name, control, defaultValue, ...rest }: IProps<T>) => {
  const {
    field: { value: value = '', onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    name,
    control,
    defaultValue,
  });

  return (
    <MantineTextarea
      value={value ?? ''}
      onChange={(e) => {
        fieldOnChange(e);
      }}
      error={fieldState.error?.message}
      minRows={3}
      maxRows={3}
      {...rest}
      {...field}
    />
  );
};
