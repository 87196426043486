import { showNotification } from '@mantine/notifications';

export const largePayloadErrorHandler = (message: string) => {
  showNotification({
    color: 'red',
    title: 'Слишком большой объем файлов',
    message: message ?? 'Что-то пошло не так',
  });
};

export const noAccessErrorHandler = (message: string) => {
  showNotification({
    color: 'red',
    title: 'У вас недостаточно прав',
    message: message ?? 'Что-то пошло не так',
  });
};
