import { ISettlement } from '../api/types';
import { ISettlementDto } from '7-shared/api';

export function mapSettlementList(dto: ISettlementDto): ISettlement {
  return {
    id: dto.id,
    name: dto.name,
    address: dto.address,
    description: dto.description ? dto.description : '',
    is_guest: dto.is_guest,
  };
}
