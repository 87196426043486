import { defaultTo } from '../lib/lodash';

export const defaultValue = (value?: any, defaultValue?: any) => {
  return defaultTo(value, defaultValue);
};
export const getUserAddressSelect = (data: any) => {
  const settlement = defaultValue(data?.street?.settlement, {});
  const street = defaultValue(data?.street, {});
  const realty_id = defaultValue(`${data?.id}`, undefined);
  return {
    settlement: {
      value: settlement?.id,
      label: settlement?.name ?? '',
    },
    street: { value: street?.id, label: street?.name },
    realty: {
      value: realty_id,
      label: `${data?.number ? data?.number : ''} ${data?.corpus ? `к.${data?.corpus.toUpperCase()}` : ''}`,
    },
    address: {
      value: realty_id,
      label: `${street?.name ? street?.name : ' '} ${data?.number ? data?.number : ''} ${
        data?.corpus ? `к.${data?.corpus.toUpperCase()}` : ''
      }`,
    },
  };
};
export const imgToBase64 = function (imageURL: any) {
  return fetch(imageURL)
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();

        reader.onloadend = function () {
          return resolve({ file: reader.result, name: imageURL });
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};
