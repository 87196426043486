import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, INewsStatus, INewsStatusForm, IPaginationResponse, IQueryParams } from 'interface';
import { customFetchBase } from '../../fetchBase';

export const statusesAPI = createApi({
  reducerPath: 'statusesAPI',
  tagTypes: ['statuses', 'currentStatus'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getStatuses: build.query<IPaginationResponse<INewsStatus[]>, IQueryParams>({
      query: ({ page, size }) => ({
        url: `/cms/news-statuses/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['statuses'],
    }),
    createStatus: build.mutation<INewsStatus, INewsStatusForm>({
      query: (data) => ({
        url: `/cms/news-statuses/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['statuses'],
    }),
    editStatus: build.mutation<INewsStatus, IEditBody<INewsStatusForm>>({
      query: (data) => ({
        url: `/cms/news-statuses/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['statuses'],
    }),
    deleteStatus: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/news-statuses/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['statuses'],
    }),
  }),
});

export const { useGetStatusesQuery, useCreateStatusMutation, useDeleteStatusMutation, useEditStatusMutation } =
  statusesAPI;
