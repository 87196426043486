import { createAsyncThunk } from '@reduxjs/toolkit';
import { newsApi } from '../api/news.api';
import { newsActions } from '../model/news.slice';
import { mapLiks } from '../lib/mapFiltersLikes';

export const getCurrentNewsThunk = createAsyncThunk<void, number, { state: RootState }>(
  'news/getCurrentNews',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(newsActions.setListTableLikesData({ isLoading: true }));
      const result = await newsApi.getCurrentNews(id);
      const list = result.likes.map((i) => mapLiks(i));
      dispatch(newsActions.setListTableLikesData({ data: list }));
      dispatch(newsActions.setListPagination({ total_count: result.likes_count }));
    } catch (error) {
      return rejectWithValue(error);
    } finally {
      dispatch(newsActions.setListTableLikesData({ isLoading: false }));
    }
  }
);
