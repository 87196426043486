import React from 'react';
import { Table } from '7-shared/ui';
import { useAppSelector } from '7-shared/hooks';
import { columns } from '../../../model/camerasListColumns';
import { selectVideosListTableCamerasData } from '6-entities/videos';

export const CamerasTable = () => {
  const tableData = useAppSelector(selectVideosListTableCamerasData);

  return <Table noHeader={true} columns={columns} rows={tableData.data} tableLoading={tableData.isLoading} />;
};
