import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, ICardContact, ICardContactForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const cardContactAPI = createApi({
  reducerPath: 'card_contactAPI',
  tagTypes: ['card_contacts'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getCardContact: build.query<IPaginationResponse<ICardContact[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `/cms/card-contact-list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['card_contacts'],
    }),
    createCardContact: build.mutation<ICardContact, ICardContactForm>({
      query: (data) => ({
        url: `/cms/card-contacts/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['card_contacts'],
    }),
    editCardContact: build.mutation<ICardContact, IEditBody<ICardContactForm>>({
      query: (data) => ({
        url: `/cms/card-contacts/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['card_contacts'],
    }),
    deleteCardContact: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `/cms/card-contacts/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['card_contacts'],
    }),
  }),
});

export const {
  useCreateCardContactMutation,
  useDeleteCardContactMutation,
  useEditCardContactMutation,
  useGetCardContactQuery,
} = cardContactAPI;
