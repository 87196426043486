import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITablePagination, ITableState } from '7-shared/types';
import { ILikes } from '../model/types';

interface IInitialState {
  tableLikes: ITableState<ILikes>;
  pagination: ITablePagination;
}

const initialState: IInitialState = {
  tableLikes: {
    data: [],
    isLoading: false,
  },
  pagination: {
    page: 0,
    total_count: 0,
    page_size: 10,
  },
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setListTableLikesData: (state, action: PayloadAction<Partial<ITableState<ILikes>>>) => {
      state.tableLikes = { ...state.tableLikes, ...action.payload };
    },
    setListPagination: (state, action: PayloadAction<Partial<ITablePagination>>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setInitialNewsstate: () => initialState,
  },
});

export const selectNewsListTableData = (state: RootState) => state.news.tableLikes;
export const selectNewsListPagination = (state: RootState) => state.news.pagination;

export const newsActions = newsSlice.actions;
