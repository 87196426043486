import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from '7-shared/ui';
import { tabs } from '../model/tabs';

export const BuilderSupportLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTabs] = useState<string>(tabs.support.value);

  useEffect(() => {
    if (location.pathname === tabs.templates.link) {
      setCurrentTabs(tabs.templates.value);
    }
    if (location.pathname === tabs.support.link) {
      setCurrentTabs(tabs.support.value);
    }
  }, [location]);

  return (
    <>
      <Tabs value={currentTab} variant={'pills'}>
        <Tabs.List className={'mb-4'}>
          <Tabs.Tab
            onClick={() => navigate(tabs.support.link)}
            px={25}
            py={15}
            value={tabs.support.value}
            icon={tabs.support.icon}
          >
            {tabs.support.label}
          </Tabs.Tab>
          <Tabs.Tab
            onClick={() => navigate(tabs.templates.link)}
            px={15}
            py={10}
            value={tabs.templates.value}
            icon={tabs.templates.icon}
          >
            {tabs.templates.label}
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Outlet />
    </>
  );
};
