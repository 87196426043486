import { createApi } from '@reduxjs/toolkit/query/react';
import { IEditBody, IPaginationResponse, IQueryParams, IRealty, IRealtyForm } from 'interface';
import { customFetchBase } from '../fetchBase';

export const realtyAPI = createApi({
  reducerPath: 'realtyAPI',
  tagTypes: ['realty'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getRealty: build.query<IPaginationResponse<IRealty[]>, IQueryParams>({
      query: ({ page, size, id }) => ({
        url: `/cms/realty_list/${id}/?page=${page}&page_size=${size}`,
      }),
      providesTags: ['realty'],
    }),
    createRealty: build.mutation<IRealty, IRealtyForm>({
      query: (data) => ({
        url: `cms/realty/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['realty'],
    }),
    editRealty: build.mutation<IRealty, IEditBody<IRealtyForm>>({
      query: (data) => ({
        url: `cms/realty/${data.id}/`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['realty'],
    }),
    deleteRealty: build.mutation<null, IQueryParams>({
      query: ({ id }) => ({
        url: `cms/realty/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['realty'],
    }),
  }),
});

export const { useCreateRealtyMutation, useDeleteRealtyMutation, useEditRealtyMutation, useGetRealtyQuery } = realtyAPI;
