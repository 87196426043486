import React from 'react';
import { MantinePagination } from '7-shared/ui/mantine';

interface IProps {
  activePage: number;
  setPage: (page: number) => void;
  total: number;
  paginationPosition?: boolean;
}

export const Pagination = ({ activePage, setPage, total, paginationPosition = false }: IProps) => {
  const stylePagination = paginationPosition
    ? { margin: 'auto' }
    : { margin: '0 auto', display: 'flex', justifyContent: 'center' };
  return (
    <MantinePagination
      style={stylePagination}
      className=" p-[10px]"
      value={activePage}
      onChange={setPage}
      total={total}
    />
  );
};
