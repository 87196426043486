import React, { useId, useRef } from 'react';
import { type ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';

import { useGetTextWidth } from '7-shared/hooks';

interface IProps extends ITooltip {
  index: number;
  tooltip: string;
  children: React.ReactNode | string;
  className: string;
}

export const Tooltip = ({ children, index, tooltip, className, ...rest }: IProps) => {
  const id = useId();
  const ref = useRef<HTMLDivElement | null>(null);
  const width = useGetTextWidth(tooltip);
  const containerWidth = ref?.current?.offsetWidth ?? 100;

  return (
    <>
      <div
        ref={ref}
        className={'overflow-hidden text-ellipsis whitespace-nowrap'}
        data-tooltip-hidden={containerWidth > width}
        data-tooltip-id={id}
        data-tooltip-content={tooltip}
      >
        {children}
      </div>
      <ReactTooltip
        place={index > 5 ? 'top' : 'bottom'}
        className={`z-[1] whitespace-pre-wrap ${className}`}
        clickable
        id={id}
        {...rest}
      />
    </>
  );
};
