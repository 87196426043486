import { Button as MantineButton, type ButtonProps } from '@mantine/core';
import React from 'react';

interface IProps extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button = ({ children, className, variant = 'filled', ...rest }: IProps) => {
  return (
    <MantineButton
      variant={variant}
      className={`${variant === 'filled' && 'bg-[#228be6]'} min-h-[45px] ${className}`}
      {...rest}
    >
      {children}
    </MantineButton>
  );
};
