import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportsActions } from '../model/reports.slice';
import { templatesApi } from '../api/templates.api';
import { ITemplateReportsForm } from '../api/types';
import { mapTempleatessList } from '../lib/mapTemplatesList';
import { ICommonErrorReject } from '7-shared/api/types';

export const getTemplatesReportsThunk = createAsyncThunk<void, void, { state: RootState }>(
  'reports/getTemplatesReports',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setListTableData({ isLoading: true }));
      const pagination = getState().reports.pagination;
      const result = await templatesApi.getTemplatesReports(pagination.page, pagination.page_size);
      const list = result.results.map((i) => mapTempleatessList(i));
      dispatch(reportsActions.setListTableTemplatesData({ data: list }));
      dispatch(reportsActions.setListPagination({ total_count: result?.count }));
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(reportsActions.setListTableData({ isLoading: false }));
    }
  }
);
export const editTemplateReportsThunk = createAsyncThunk<
  void,
  { id: string; data: ITemplateReportsForm },
  { state: RootState }
>('reports/editTemplateReports', async ({ id, data }, { dispatch, getState, rejectWithValue }) => {
  try {
    dispatch(reportsActions.setListTableData({ isLoading: true }));
    await templatesApi.editTemplateReports(id, data);
    dispatch(getTemplatesReportsThunk());
  } catch (error) {
    return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
  } finally {
    dispatch(reportsActions.setListTableData({ isLoading: false }));
  }
});
export const createTemplateReportsThunk = createAsyncThunk<void, ITemplateReportsForm, { state: RootState }>(
  'reports/createTemplateReports',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setListTableData({ isLoading: true }));
      await templatesApi.createTemplateReports(data);
      dispatch(getTemplatesReportsThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(reportsActions.setListTableData({ isLoading: false }));
    }
  }
);
export const deleteTemplateReportsThunk = createAsyncThunk<void, string, { state: RootState }>(
  'reports/deleteTemplateReports',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(reportsActions.setListTableData({ isLoading: true }));
      await templatesApi.deleteTemplateReports(id);
      dispatch(getTemplatesReportsThunk());
    } catch (error) {
      return rejectWithValue((error as ICommonErrorReject)?.response?.data?.detail);
    } finally {
      dispatch(reportsActions.setListTableData({ isLoading: false }));
    }
  }
);
