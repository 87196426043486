import { IUser } from './users.interface';
import { IPaginationQuery } from './app.interface';
import { IGuestUserData } from './technicalSupport';
import { IUserAddress } from '../6-entities/serviceSupport/api/types';

export interface IServiceSupport {
  status: SERVICE_SUPPORT_STATUS;
  comments: IServiceSupportComment[];
  email: string;
  id: number;
  message: string;
  time_created: string;
  time_updated: string;
  user: IUser;
  guest_data: IGuestUserData;
  media: IServiceSupportMedia[];
  user_address: IUserAddress;
  chat_id: number;
  score: number;
}

export enum SERVICE_SUPPORT_STATUS {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  WAITING_RESPONSE = 'waiting_response',
  REDIRECTED = 'redirected',
  CLOSED = 'closed',
  RETURNED_TO_WORK = 'returned_to_work',
}

export interface IServiceSupportMedia {
  file: string;
}
export interface IServiceSupportComment {
  comment: string;
  current_status: SERVICE_SUPPORT_STATUS;
  previous_status: SERVICE_SUPPORT_STATUS;
  time_created: string;
}

export interface IServiceSupportInfoForm {
  comment?: string;
  status: SERVICE_SUPPORT_STATUS;
  detail?: string;
}

export interface IServiceSupportReplace {
  current_type: 'communal';
  target_type: 'support' | 'builder';
}
export interface IServiceSupportReplaceForm {
  status: 'support' | 'builder';
  detail?: string;
}

export type IServiceSupportFilters = Readonly<'search' | 'status' | 'settlements__name'>;
export type IServiceSupportQueryParams = IServiceSupportFilters | IPaginationQuery;
