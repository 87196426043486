import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons';

export const BackArrow = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return <IconArrowLeft className={'cursor-pointer min-w-[25px]'} onClick={goBack} size={25} />;
};
